<script lang="ts">
	import { ErrorReportIcon } from "svelte-comps/luxedo"
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { onDestroy } from "svelte"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"

	let progress: number = 0

	let errorMessage: string = undefined
	let errorCode: string = undefined
	let errorDescription: string = undefined

	let unsubscribeFns = []

	function openTroubleshooter() {
		CalibrationOverlayController.openTroubleshooter()
	}

	function cancel() {
		CalibrationOverlayController.close()
	}

	unsubscribeFns.push(
		CalibrationOverlayController.subscribe((ctx) => {

			progress = ctx.progress
			errorDescription = ctx.description
			errorMessage = ctx.message
			errorCode = ctx.errorCode
		})
	)

	onDestroy(() => {
		unsubscribeFns.forEach((fn) => fn())
	})
</script>

<div class="calibration-top">
	<ErrorReportIcon />
	<ProgressBar width="calc(100% - 8rem)" percentage={progress} />
</div>
<div class="calibration-bottom">
	<h3>⚠ {errorMessage ? errorMessage : "An error occurred while calibrating"}</h3>
	<p id="error-description">
		{errorDescription
			? errorDescription
			: "An error occured while calibrating. Given the complexity of calibration, there are many reasons this could have happened. Click 'Troubleshoot' below to investigate."}
		{#if errorCode}
			[Error code: {errorCode}]
		{/if}
	</p>
</div>
<div class="button-container">
	<button class="link-button" on:click={cancel}> Cancel </button>
	{#if errorCode}
		<button on:click={CalibrationOverlayController.retry}>Retry</button>
	{:else}
		<button on:click={openTroubleshooter}>Troubleshoot</button>
	{/if}
</div>

<style>
	.calibration-top :global(svg) {
		height: 8rem;
	}

	h3 {
		color: var(--color-error);
	}
</style>
