<script lang="ts">
	import { PlusIcon } from "svelte-comps/icons"
	import QuickSelector from "./QuickSelector.svelte"
	import { tooltip } from "svelte-comps/tooltip"

	type Options = {
		[index: string]: () => void
	}

	export let options: Options
	export let isCreating = false
	export let remWidth = 10
	export let info = undefined

	let isHovering = false

	function onHover() {
		isHovering = true
	}

	function onBlur() {
		isHovering = false
	}
</script>

<button
	class="small icon upload-btn {isHovering ? 'hover' : ''} {isCreating ? 'active' : ''}"
	on:mouseover={onHover}
	on:mouseleave={onBlur}
	on:click={(e) => {
		isCreating = !isCreating
		e.currentTarget.blur()
	}}
	use:tooltip={{content: info, pointing: "top"}}
>
	<PlusIcon strokeColor={"var(--color-main)"} />
	<QuickSelector isHidden={!isCreating} {options} {remWidth} />
</button>

<style>
	.upload-btn :global(svg) {
		transition: transform 250ms;
	}

	.upload-btn:hover > :global(svg .svg-stroke),
	.upload-btn:focus-visible > :global(svg .svg-stroke) {
		stroke: var(--color-main);
	}

	.upload-btn:not(.active):hover > :global(svg .svg-stroke),
	.upload-btn:not(.active):focus-visible > :global(svg .svg-stroke) {
		stroke: var(--color-text);
	}

	.upload-btn.active > :global(svg) {
		transform: rotate(45deg);
	}

	.upload-btn :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.upload-btn {
		align-self: center;
		margin-left: 1rem;
	}
</style>
