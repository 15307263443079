import { fabric } from "fabric"
import { AnimationPreset, PresetKeyframes, PresetOptions } from "../../animation/presets"
import { CanvasAsset } from "../../asset"
import { Path, PathMessageType, PathSubscriber } from "../../asset"
import { Keyframe, LocalTimestamp } from "../../animation"
import { Track } from "../../tracks"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../modules/serialize"
import { EditorClass } from "../.."

export class PositionPath extends AnimationPreset<CanvasAsset> implements PathSubscriber, Serializable {
	name = "PositionPath"
	properties = ["left" as const, "top" as const]
	type = "base" as const
	path: Path
	pathId: string

	declare track: Track<CanvasAsset>

	constructor(editor: EditorClass, track: Track<CanvasAsset>, path: Path, options: PresetOptions) {
		super(editor, track, options)
		this.path = path
		this.pathId = path.id

		this.path.useRelativeOrigin(track.asset)
		this.generateKeyframes()

		this.path.register()
		this.path.subscribe(this)
	}

	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	) {
		switch (messageType) {
			case "point:add":
			case "point:edit":
			case "point:delete":
				this.generateKeyframes()
				this.editor.canvas.requestRenderAll()
				return
			case "unsubscribed":
			case "deleted":
				this.pathId = undefined
				this.track.removeAnimationPreset(this)
				return
		}
	}

	get points(): fabric.Point[] {
		return this.path.points
	}

	updateTime(start: LocalTimestamp, end: LocalTimestamp) {
		this.startTime = start
		this.endTime = end

		this.generateKeyframes()
	}

	protected calcNormalizedTimestamps() {
		const lengths = []
		for (let i = 1; i < this.points.length; i++) {
			const prev = this.points[i - 1]
			const next = this.points[i]

			lengths.push(next.distanceFrom(prev))
		}

		const total = lengths.reduce((a, b) => a + b)
		const relative = lengths.map((len) => len / total)

		let ts = 0
		let cumulative = [0]

		for (const len of relative) {
			ts += len
			cumulative.push(ts)
		}

		const timeScale = this.endTime - this.startTime

		cumulative = cumulative.map((ts) => {
			return ts * timeScale + this.startTime
		})

		return cumulative
	}

	generateKeyframes() {
		const leftKfs: Keyframe[] = []
		const topKfs: Keyframe[] = []
		const timestamps = this.calcNormalizedTimestamps()

		for (let i = 0; i < this.points.length; i++) {
			const point = this.points[i]

			leftKfs.push({ timestamp: timestamps[i] as LocalTimestamp, value: point.x })
			topKfs.push({ timestamp: timestamps[i] as LocalTimestamp, value: point.y })
		}

		const left = new PresetKeyframes(this, leftKfs)
		const top = new PresetKeyframes(this, topKfs)

		this.keyframes = {
			left,
			top,
		}
	}

	/**
	 * Loads the JSON data for the position path, assuming the PATH object is passed to data as data.path
	 * @param data The json data to import
	 * @param data.path The Path instance
	 * @returns
	 */
	static async loadJSON(
		editor: EditorClass,
		data: Partial<PositionPath> & { startTime: number; endTime: number; pathData: Partial<Path> }
	): Promise<PositionPath> {
		const pathData = data.pathData
		pathData["track"] = data.track

		const path = await Path.loadJSON(editor, pathData, pathData.id)

		const instance = new this(editor, data.track, path, data)
		return instance
	}

	serializeKeyframes() {
		let serialized = {}
		for (const [name, keyframes] of Object.entries(this.keyframes)) {
			serialized[name] = (keyframes as PresetKeyframes<any>).serialize()
		}
		return serialized
	}

	serialize(forExport?: boolean): Partial<PositionPath> {
		let json = {}

		if (forExport) {
			json = convertInstanceToObject(this, {
				forExport,
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
				propertiesToExclude: ["path", "track"], // When exporting, the paths will be saved separately
			})
		} else {
			json = convertInstanceToObject(this, {
				forExport,
				propertiesToDeepCopy: ["path"],
				propertyGetters: {
					serializeKeyframes: ["keyframes"],
				},
				propertiesToExclude: ["track"],
			})
		}

		return json
	}
}

registerSerializableConstructor(PositionPath)
