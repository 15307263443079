<script lang="ts">
	import { DataHandlerCalibration, DataHandlerMask, Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openMaskEditorOverlay } from "../../../reusable/overlays/mask-editor"
	import { MaskSidebarController } from "../../../reusable/overlays/mask-editor/sidebar/MaskEditorSidebarController"

	let device: Device
	let hasPreviousMasks: boolean = false

	SelectedDeviceStore.subscribe((dev) => {
		hasPreviousMasks = checkForPreviousMasks(dev)
		device = dev
	})

	function checkForPreviousMasks(dev: Device): boolean {
		if (!dev) return

		const allMasks = DataHandlerMask.filterByDevice(dev)
		return !!allMasks.length
	}

	function importMasks() {
		openMaskEditorOverlay(DataHandlerCalibration.filterByDevice(device, true)[0])
		setTimeout(() => {
			MaskSidebarController.goTo("import")
		})
	}
</script>

<div class="next-steps-heading">
	<h1>Masking</h1>
</div>
<p>
	Now that your device has been calibrated successfully, it is recommended to configure your masks.
</p>
<div class="info">
	<p>Before configuring any masks, it may be helpful to understand their purpose:</p>
	<p>
		Masks allow you to control where projected content appears within your projection space. They
		provide an accurate alignment of projected content within designated areas, enhancing the
		overall visual impact of the projection mapping display.
	</p>
	<p>
		The process of configuring masks involves drawing shapes around each section of your projection
		space, specifying where content will be visible or hidden. Once saved, these masks will
		automatically be imported whenever creating a new show.
	</p>

	Masking is recommended to simplify show creation.
</div>

<div class="button-container">
	{#if hasPreviousMasks}
		<button class="outline-button alt" on:click|stopPropagation={() => importMasks()}
			>Import Old Masks</button
		>
	{/if}

	<button
		class="outline-button"
		on:click|stopPropagation={() =>
			openMaskEditorOverlay(DataHandlerCalibration.filterByDevice(device)[0])}
		>Create New Masks</button
	>
</div>

<style>
	.button-container {
		flex-direction: row;
		justify-content: flex-start;
	}

	.button-container button {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
</style>
