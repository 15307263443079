<script lang="ts">
	import Guides from "luxedo-data/src/data/Guides"

	// This step is for a new device - has not been set up and is offline
</script>

<h1>Getting Started</h1>
<p>Congratulations on your new Luxedo! Let's get you set up.</p>
<p>
	In order to communicate with your Luxedo device, it must be powered on and connected to Wi-Fi.
	Follow <a href={Guides.Device["Connecting to Wi-Fi"]}>this guide</a> to get your device connected.
</p>
<p class="help">
	Having trouble? Follow <a href={Guides["Device"]["Troubleshooting Connection To Wi-Fi"]}
		>this guide</a
	>
	for help troubleshooting. Or check out our
	<a href="https://www.luxedo.com/pages/help">help center</a> for further assistance.
</p>

<style>
</style>
