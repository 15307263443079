<script lang="ts">
	import { ProgressBar } from "../../../progress-bar"

	export let progress
</script>

<div>
	<p>
		Saving this file will replace your old version, including where it appears in any existing
		scenes when you next render them. The old version will no longer be accessible. Save anyways?
	</p>
	<ProgressBar percentage={progress} width={"100%"} />
</div>

<style>
	div {
		max-width: 40vw;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 10% 20%;
	}

	p {
		color: var(--color-text);
	}
</style>
