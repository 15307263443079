import { EditorClass } from "../.."
import { fabric } from "fabric"

/**
 * @file controllers/Viewport.ts
 * @description Controller for the viewport for an editor canvas, such as pan and zoom levels
 */
export interface ViewportController {
	reset(): void
	zoom(delta: number, focusPoint?: fabric.Point): void
	pan(dx: number, dy: number): void
	panToPoint(point: fabric.Point): void
	lock(): void
	unlock(): void

	_zoom: number
	locked: boolean
	editor: EditorClass
}

/**
 * A class for handling an editor's viewport
 */
export class ViewportController implements ViewportController {
	//#region    ===========================		   Construction & init  	 		==============================

	private resizeObserver: ResizeObserver

	maxZoom: number
	minZoom: number

	constructor(editor: EditorClass, options?: ViewportOptions) {
		this.editor = editor
		this.locked = options?.locked || false
		this.minZoom = options?.minZoom || 0.2
		this.maxZoom = options?.maxZoom || 5

		this.updateCanvasSize()
		this.reset()

		this.resizeObserver = new ResizeObserver(this.updateCanvasSize.bind(this))
		this.resizeObserver.observe(this.editor.element)

		this.initEvents()
	}

	private updateCanvasSize() {
		const containerElement = this.editor.element
		this.editor.canvas.setDimensions({
			width: containerElement.clientWidth,
			height: containerElement.clientHeight,
		})
	}
	private initEvents() {
		/* initialize scroll-wheel zoom */
		this.editor.canvas.on("mouse:wheel", (e) => {
			const wheelEvent = e.e as WheelEvent
			const delta = -wheelEvent.deltaY / 1000
			this.zoom(delta, new fabric.Point(e.absolutePointer.x, e.absolutePointer.y))
		})

		let panStartX: number
		let panStartY: number

		let centerX: number
		let centerY: number

		this.editor.canvas.on("mouse:down", (e) => {
			if (e.button != 3) return

			centerX = this.centerX
			centerY = this.centerY

			panStartX = e.pointer.x / this.scale
			panStartY = e.pointer.y / this.scale
		})

		this.editor.canvas.on("mouse:up", (e) => {
			if (e.button != 3) return
			panStartX = undefined
			panStartY = undefined
			this.panning = false
			this.editor.canvas.skipTargetFind = false
		})

		/* Panning with right click on mousedown */
		this.editor.canvas.on("mouse:move", (e) => {
			if (!panStartX) return

			const diffX = e.pointer.x / this.scale - panStartX
			const diffY = e.pointer.y / this.scale - panStartY

			/** Set a threshold of moving the mouse 4 pixels to begin the panning */
			if (!this.panning) {
				if (Math.abs(diffX) * this.scale < 4 && Math.abs(diffY) * this.scale < 4) return
				this.panning = true
				this.editor.canvas.skipTargetFind = true
			}

			const point = new fabric.Point(centerX - diffX, centerY - diffY)
			this.panToPoint(point)
		})
	}

	panning: boolean

	//#endregion =====================================================================================================

	//#region    ===========================				  Properties 		 		==============================

	get width(): number {
		return this.editor.element.clientWidth
	}

	get height(): number {
		return this.editor.element.clientHeight
	}

	get viewportCenter(): fabric.Point {
		return new fabric.Point(this.width / 2, this.height / 2)
	}

	get scalePerfectFit() {
		return Math.min(this.height / this.editor.sceneHeight, this.width / this.editor.sceneWidth)
	}

	//#endregion =====================================================================================================

	//#region    ===========================			Transform Properties			==============================

	private _scale = 1
	get scale(): number {
		return this._scale
	}
	set scale(val: number) {
		val = Math.max(this.minZoom, Math.min(val, this.maxZoom))
		this._scale = val
		this.requestUpdateTransform()
	}

	private _centerX = 0
	private _centerY = 0

	get centerX(): number {
		return this._centerX
	}
	set centerX(val: number) {
		this._centerX = Math.max(0, Math.min(val, this.editor.sceneWidth))
		this.requestUpdateTransform()
	}
	get centerY(): number {
		return this._centerY
	}
	set centerY(val: number) {
		this._centerY = Math.max(0, Math.min(val, this.editor.sceneHeight))
		this.requestUpdateTransform()
	}

	private pendingTransform: number

	private requestUpdateTransform() {
		if (this.pendingTransform) return

		this.pendingTransform = setTimeout(() => {
			this.apply()
			this.pendingTransform = undefined
		})
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  	API		 		 		==============================

	private apply() {
		// First we transform the canvas so that its center is at 0, 0
		const prepMatrix = composeMatrix({
			translateX: -this.centerX,
			translateY: -this.centerY,
		})

		// Then we scale everything to what we want it to be
		const scaleMatrix = composeMatrix({
			scaleX: this.scale,
			scaleY: this.scale,
		})

		// Multiply those for the intermediary matrix
		const cornerAndScale = fabric.util.multiplyTransformMatrices(scaleMatrix, prepMatrix)

		// Finally we do the inverse translation on the canvas to put the point we actually want into the center
		const recenterMatrix = composeMatrix({
			translateX: this.width / 2,
			translateY: this.height / 2,
		})

		const finalMatrix = fabric.util.multiplyTransformMatrices(recenterMatrix, cornerAndScale)

		this.editor.canvas.setViewportTransform(finalMatrix)
	}

	public reset() {
		if (this.locked) return
		this.scale = this.scalePerfectFit * 0.975
		this.panToPoint(this.editor.sceneCenter)
	}

	public setZoom(zoomValue: number) {
		if (this.locked) return
		this.scale = zoomValue
	}

	public zoom(delta: number, focusPoint?: fabric.Point) {
		if (this.locked) return
		const zoom = this.scale + delta
		const ratio = zoom / this.scale

		this.scale = zoom

		if (this.scale != zoom) return // This means we're scrolling into our bounds

		if (focusPoint) {
			let diffX = focusPoint.x - this.centerX
			let diffY = focusPoint.y - this.centerY

			this.pan(diffX * (1 - ratio), diffY * (1 - ratio))
		}
	}

	public pan(dx: number, dy: number) {
		if (this.locked) return
		this.centerX -= dx
		this.centerY -= dy
	}

	public panToPoint(absolutePoint: fabric.Point) {
		if (this.locked) return
		this.centerX = absolutePoint.x
		this.centerY = absolutePoint.y
	}

	public lock() {
		this.locked = true
	}

	public unlock() {
		this.locked = false
	}
	//#endregion =====================================================================================================
}

export interface ViewportOptions {
	locked?: boolean
	minZoom?: number
	maxZoom?: number
}

type TransformMatrix = [
	scaleX: number,
	skewX: number,
	skewY: number,
	scaleY: number,
	translateX: number,
	translateY: number
]

export function composeMatrix(options: {
	translateX?: number
	translateY?: number
	angle?: number
	scaleX?: number
	scaleY?: number
	flipX?: boolean
	flipY?: boolean
	skewX?: number
	skewY?: number
}): TransformMatrix {
	return fabric.util.composeMatrix({
		scaleX: options.scaleX || 1,
		scaleY: options.scaleY || 1,
		translateX: options.translateX || 0,
		translateY: options.translateY || 0,
		angle: options.angle || 0,
		flipX: options.flipX || false,
		flipY: options.flipY || false,
		skewX: options.skewX || 0,
		skewY: options.skewY || 0,
	}) as TransformMatrix
}
