<script lang="ts">
	import { DataHandlerDevice, Tag, type Device, DataHandlerTag } from "luxedo-data"
	import { UserStore } from "../../../../stores/UserStore"
	import ProjectTags from "../../tags/ProjectTags.svelte"

	export let name = "Enter scene title..."
	export let description = "Add description."
	export let selectedDeviceId: number
	export let selectedTags: Array<number>

	export let triggerCreate: () => void

	let tags: Array<Tag> = DataHandlerTag.getMany()
	DataHandlerTag.addListener((newIds) => {
		tags = [...DataHandlerTag.getMany()]
	})

	let availableDevices: Array<Device>
	UserStore.subscribe(() => {
		availableDevices = DataHandlerDevice.getMany()
	})

	function onSceneNameChange(
		e: KeyboardEvent & {
			currentTarget: EventTarget & HTMLHeadingElement
		}
	) {
		if (e.key === "Enter") {
			e.preventDefault()
			return triggerCreate()
		}
		const target = e.currentTarget
		setTimeout(() => {
			name = target.innerText
		})
	}

	function onDescriptionChange(
		e: Event & {
			currentTarget: EventTarget & HTMLParagraphElement
		}
	) {
		const target = e.currentTarget
		setTimeout(() => {
			description = target.innerText
		})
	}
</script>

<div class="scene-inputs">
	<h1 id="new-scene-title-input" on:keydown={onSceneNameChange} contenteditable={true}>
		Enter scene title...
	</h1>
	<div class="scene-inputs-bottom-row">
		<div class="left">
			<p on:keydown={onDescriptionChange} contenteditable={true}>Add description.</p>
			<div class="tag-container">
				<ProjectTags bind:selectedTags={selectedTags} isLibraryContext={false} />
			</div>
		</div>
		<div class="select-container">
			<label for="scene-projector-input">Select Projector:</label>
			<select id="scene-projector-input" bind:value={selectedDeviceId}>
				{#each availableDevices as device}
					<option value={device.id}>{device.name}</option>
				{/each}
			</select>
		</div>
	</div>
</div>

<style>
	h1 {
		font-size: var(--text-h2);
		margin: 0;
	}

	p {
		color: var(--color-text);
	}

	.scene-inputs {
		height: fit-content;
		padding: 6rem 2rem 2rem 2rem;

		width: 100%;
		background-color: var(--color-bg-dark);
	}

	.tag-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.scene-inputs-bottom-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.left {
		width: 70%;
	}

	.select-container {
		flex-direction: column;
		align-items: flex-start;
		align-self: center;
	}

	.select-container label {
		padding-left: 0.25rem;
		color: var(--color-text);
	}

	.select-container select {
		padding: 0;
		color: var(--color-text-light);
	}

	.select-container option {
		color: var(--color-text-light);
	}
</style>
