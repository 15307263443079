<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import NumberRange from "../../../../reusable/input/NumberRange.svelte"
	import type { DeviceGroup } from "luxedo-data"
	import { onMount } from "svelte"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	let gamma = 2.2
	let isActive
	let hasMounted = false

	let group: DeviceGroup
	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
		isActive = ctx.blendingOptions.isBlendActive
	})

	function toggleBlending(setActive: boolean) {
		if (setActive) {
			GroupEditorController.initializeBlending(gamma)
		} else {
			GroupEditorController.clearBlending()
		}
	}

	function updateBlending() {
		if (!(isActive && hasMounted)) return

		GroupEditorController.initializeBlending(gamma)
	}

	onMount(() => {
		hasMounted = false

		const { blendingOptions, group } = GroupEditorController.getCurrentContext()
		if (blendingOptions.isBlendActive) {
			gamma = blendingOptions.gamma
			isActive = true
		} else {
			gamma = group.gamma
			isActive = false
		}

		gamma = Math.max(0, gamma)

		hasMounted = true
	})

	$: gamma && updateBlending()
</script>

<div class="input-container">
	<span class="label">Blending</span>
	<ToggleSwitch bind:isActive onUpdate={toggleBlending} />
</div>
<NumberRange step={0.1} label="Gamma" min={1.6} max={2.8} bind:value={gamma} />

<style>
</style>
