import {
	CanvasAsset,
	Mask,
	type EditorAsset,
	ClippingMask,
	TrackMask,
	MaskBase,
	AudioAsset,
	VideoAsset,
} from "../asset"
import {
	AnimatedProperty,
	AnimationError,
	Keyframe,
	LocalTimestamp,
	TimestampKeyframeMap,
	AnimationPreset,
	PresetType,
} from "../animation"
import {
	convertInstanceToObject,
	getSerializedConstructor,
	registerSerializableConstructor,
} from "../modules/serialize"
import { EditorClass } from ".."
import { type RootTrackGroup, type TrackBaseOptions, TrackGroup, TrackLike } from "."

export interface TrackOptions extends TrackBaseOptions {
	start?: number
	end?: number
}

/**
 * Basic organizational structure of the editor
 * The editor timeline / tracklist is made up of groups and of tracks
 * Tracks contain a single asset in them
 * Groups contain other groups or tracks
 */
export class Track<T extends EditorAsset = any> extends TrackLike<T> {
	//#region    ===========================			   Initialization				==============================

	/** The asset underlying this track */
	asset: T
	usesKeyframes: boolean

	constructor(editor: EditorClass, options: TrackOptions) {
		super(editor, options)

		this.setTimespan({
			start: options?.start || 0,
			end: options?.end || 5,
		})
	}

	/**
	 * @constructor
	 * Create a new track from the basis of a pre-existing asset
	 * This is the preferred way of constructing a track!
	 **/
	static FromAsset<T extends EditorAsset>(editor: EditorClass, asset: T, options: TrackOptions): Track<T> {
		const track = new Track(editor, options)
		track.asset = asset
		track.asset.bindTrack(track)
		track.contentType = track.asset instanceof AudioAsset ? "AUDIO" : "VIDEO"
		return track
	}

	initialize(root: RootTrackGroup<T>): void {
		super.initialize(root)
		this.contentType = this.asset instanceof AudioAsset ? "AUDIO" : "VIDEO"
	}

	/**
	 * Called when outright deleted (not just detached)
	 * Calls the asset's deletion function, if present
	 * @returns
	 */
	onDelete(): void {
		this.asset.onDelete()
	}

	//#endregion =====================================================================================================

	//#region    ===========================				   Interface				==============================

	setTimespan(timespan: Partial<TimeSpan>) {
		timespan.start = timespan.start ?? this.startTime
		timespan.end = timespan.end ?? this.endTime

		if (timespan.start >= timespan.end) throw RangeError("start cannot be after end")
		this.startTime = timespan.start
		this.endTime = timespan.end

		this.getLineage().forEach((parent) => {
			parent.emitEvent("track:edittimespan", {
				track: this,
			})
		})

		this.emitEvent("track:edittimespan", {
			track: this,
		})
	}

	getAssets() {
		return [this.asset]
	}

	//#endregion =====================================================================================================

	//#region    ===========================				  Properties	 	 		==============================

	protected startTime: number = 0
	protected endTime: number = 10

	/** The start time (in seconds) of the track's asset in the scene */
	get start(): number {
		return this.startTime
	}
	set start(val: number) {
		this.setTimespan({ start: val })
	}

	get end(): number {
		return this.endTime
	}
	set end(val: number) {
		this.setTimespan({ end: val })
	}

	get duration(): number {
		return this.endTime - this.startTime
	}

	/** The end time (in seconds) of the track's asset in the scene */

	//#endregion =====================================================================================================

	//#region    ===========================				  Animation 				==============================

	animations: {
		[prop in T["AnimatableProperty"]]?: AnimatedProperty<any>
	}

	animationPresets: {
		[presetName: string]: AnimationPreset<T>
	} = {}

	/**
	 * @param animatedProp
	 * @returns
	 */
	isAnimated(): boolean {
		return !!this.animations
	}

	/**
	 * Converts all active animation presets to
	 */
	useKeyframes() {
		if (this.usesKeyframes) return

		if (!this.isAnimated()) this.animate()

		for (const [prop, animation] of Object.entries(this.animations)) {
			const propertyKeyframes = (animation as AnimatedProperty<typeof this.asset>).flattenKeyframes()
			for (const keyframe of propertyKeyframes) {
				let offsetValue = keyframe.value - this.animations[prop].initialEditValue
				;(this.animations[prop] as AnimatedProperty<typeof this.asset>).set(keyframe.timestamp, offsetValue)
			}
		}

		for (const preset of Object.values(this.animationPresets)) {
			this.removeAnimationPreset(preset)
		}

		this.usesKeyframes = true
		this.emitEvent("animation:update", { track: this })
	}

	/**
	 * Add a new animation to this track's keyframes
	 * @param name The name this animated property will be keyed on in this.animations
	 * @param animatedProperty The property of the target that will be made animated
	 **/
	animate(): void {
		if (this.animations) return
		this.animations = {}

		for (const property of (this.asset as CanvasAsset).AnimatableProperties) {
			this.animations[property] = AnimatedProperty.Create(this.editor, this, property)
			this.animations[property].insert(0, 0)
		}
	}

	/** Apply a preset animation to this track */
	addAnimationPreset(preset: AnimationPreset<T>) {
		if (this.usesKeyframes)
			throw new AnimationError("This track uses keyframes and cannot use animation presets.", {
				cause: "usesKeyframes",
				track: this,
			})

		if (!this.animationPresets) this.animationPresets = {}

		if (this.animationPresets[preset.name]) {
			throw TypeError(`Track ${this.name} already has preset ${preset.constructor.name}`)
		}

		this.animationPresets[preset.name] = preset

		for (const prop of preset.properties) {
			if (!this.isAnimated()) this.animate()

			this.animations[prop].applyPreset(preset)
		}

		this.emitEvent("animation:update", {
			track: this,
		})
	}

	removeAnimationPreset(preset: AnimationPreset<T>) {
		if (!this.animationPresets) return
		if (!(preset.name in this.animationPresets)) return

		for (const prop of this.animationPresets[preset.name].properties) {
			this.animations[prop].removePreset(preset)
		}

		delete this.animationPresets[preset.name]

		this.emitEvent("animation:update", {
			track: this,
		})
	}

	removeKeyframe(time: LocalTimestamp) {
		this.keyframes[time]

		for (const [prop, anim] of Object.entries(this.animations)) {
			const frames = (anim as AnimatedProperty<T>).keyframes
			let i = 0
			for (let i = 0; i < frames.length; i++) {
				const frame = frames[i]
				if (frame.timestamp === time) {
					this.animations[prop].keyframes.splice(i, 1)
				}
			}
		}

		this.emitEvent("animation:update", { track: this })
	}

	get muted() {
		return this._muted
	}

	set muted(newVal: boolean) {
		super.muted = newVal
		;(async (asset) => {
			if (asset instanceof AudioAsset || asset instanceof VideoAsset) {
				if (asset.loadingPromise) await asset.loadingPromise
				asset.content?.mute(newVal)
			}
		})(this.asset)
	}

	get keyframes(): TimestampKeyframeMap {
		// ! this seems to be called over and over again in certain circumstances (when converting presets to keyframes)

		const map = {}

		if (!this.animations) return {}

		for (const [prop, animatedProp] of Object.entries(this.animations)) {
			const anim = animatedProp as AnimatedProperty<T>
			const animFrames = anim.keyframes
			// Loop through each keyframe and add to the timestamp map
			for (const frame of animFrames) {
				if (!(frame.timestamp in map)) {
					// Create the index at the timestamp if it does not already exist
					map[frame.timestamp] = {
						[prop]: frame,
					}
				} else {
					// Add the property to the object keyed at the timestamp
					map[frame.timestamp][prop] = frame
				}
			}
		}

		return map
	}

	//#endregion =====================================================================================================

	//#region    ===========================		Keyframes and Rendering logic		==============================

	isVisible() {
		const currentTime = this.editor.timeline.currentTime
		return currentTime >= this.startTime && currentTime < this.endTime
	}

	//#endregion =====================================================================================================
	//#region    ===========================				Serialization	    	 ==============================

	/** Creates a new track from a serialized instance
	 * @param data [REQUIRED] the serialized JSON data representing the instance
	 */
	static async loadJSON(
		editor: EditorClass,
		data: Partial<Track & { startTime: number; endTime: number }>,
		id?: string
	): Promise<Track> {
		const applyPresets = async (clone: Track, presets: Partial<AnimationPreset<any>>[]) => {
			for (const preset of presets) {
				preset.track = clone
				const constructor = getSerializedConstructor(preset)
				const instance = await constructor.loadJSON(editor, preset)

				// @ts-ignore
				clone.addAnimationPreset(instance)
				editor.canvas.requestRenderAll()
			}
		}

		const applyAnimations = (clone: Track, properties: Partial<AnimatedProperty<any>>[]) => {
			clone.animate()
			for (const prop of properties) {
				clone.animations[prop.property] = AnimatedProperty.Create(editor, clone, prop.property)
				clone.animations[prop.property].keyframes = prop.keyframes
			}
		}

		const clone = new Track(editor, {
			name: data.name,
			end: data.endTime,
			start: data.startTime,
		})

		if (id) clone.id = id
		clone.tags = data.tags

		// If the asset has been generated, it will be passed in as part of the data
		if (data.asset) {
			clone.asset = data.asset
			clone.asset.bindTrack(clone)
		}

		if (data.animations) {
			applyAnimations(clone, Object.values(data.animations))
		}

		if (data.animationPresets) {
			await applyPresets(clone, Object.values(data.animationPresets))
		}

		// Mask import handled in `Importer`

		// if (data.clippingMask) {
		// const base = MaskBase.fromClippingMask(data.clippingMask)

		// data.clippingMask.bindTrack(clone)

		// const constructor = getSerializedConstructor(data.clippingMask) // Cannot reference the ClippingMask class directly due to odd load order issues
		// const mask = await constructor.loadJSON(editor, data.clippingMask)

		// clone.setMask(mask)
		// }

		// if (data.trackMask) {
		// 	data.trackMask.data.track = clone

		// @ts-ignore - track mask serialization does not return a partial
		// 	const trackMask = await TrackMask.loadJSON(editor, data.trackMask)
		// 	clone.trackMask = trackMask
		// }

		clone.hidden = data.hidden ?? false
		clone.locked = data.locked ?? false
		clone.muted = data.muted ?? false

		return clone
	}

	/**
	 * Converts this track instance into a serialized object
	 * @param forExport set to true if exporting project data, false if just copy/paste
	 * @returns
	 */
	serialize(forExport?: boolean): Partial<this> {
		let json = {}

		if (forExport) {
			json = convertInstanceToObject(this, {
				propertiesToExclude: ["asset", "animations", "animationPresets", "trackMask"],
				forExport,
			})

			json["assetId"] = this.asset.id
		} else {
			json = convertInstanceToObject(this, {
				propertiesToExclude: ["animations", "animationPresets", "trackMask"],
				propertiesToDeepCopy: ["asset"],
				forExport,
			})
		}

		if (this.animations) {
			const animData = {}
			for (const [key, animation] of Object.entries(this.animations)) {
				animData[key] = (animation as AnimatedProperty<typeof this.asset>).serialize(forExport)
			}
			json["animations"] = animData
		}

		if (this.animationPresets) {
			const presetData = {}
			for (const [key, animation] of Object.entries(this.animationPresets)) {
				presetData[key] = (animation as AnimationPreset<typeof this.asset>).serialize(forExport)
			}
			json["animationPresets"] = presetData
		}

		if (this.maskData) {
			json["maskData"] = this.maskData
		}

		json["parentId"] = this.parent.id

		return json
	}

	//#endregion    ===================================================================================
}

// #region =========================  TYPES  ===========================

export type TimeSpan = {
	start: number
	end: number
}

type AllKeyframeData<T extends EditorAsset> = {
	[prop in T["AnimatableProperty"]]?: {
		initialValue: number
		keyframes: KeyframesByTimestamp
	}
}

interface AllPresetData<T extends EditorAsset> {
	[presetName: string]: {
		instance: AnimationPreset<T>
		type: PresetType
		animatedProperties: T["AnimatableProperty"][]
		keyframes: KeyframesByProperty<T>
	}
}

type KeyframesByProperty<T extends EditorAsset> = {
	[prop in T["AnimatableProperty"]]?: KeyframesByTimestamp
}

type KeyframesByTimestamp = {
	[ts in ZeroToOne]?: Keyframe
}

type ZeroToOne = `0` | `1` | (`0.${number}` & `${number}`)

//#endregion =====================================================

registerSerializableConstructor(Track)
