<script lang="ts">
	import { DeviceGroup, type Device, type Lightshow, type Scene } from "luxedo-data"
	import { onMount } from "svelte"
	import { PlayIcon, StopIcon } from "svelte-comps/icons"
	import { Toast } from "svelte-comps/toaster"
	import LoadingSpinner from "../../loaders/LoadingSpinner.svelte"

	export let scene: Scene | Lightshow
	export let device: Device

	let isPlaying = false
	let isWaiting = false
	let isOffline = false

	async function stopPlayback() {
		try {
			isWaiting = true
			await device.clearPreview()
			Toast.success("Playback stopped.")
			isPlaying = false
		} catch (e) {
			console.error("Error clearing playback... ", e)
			Toast.error("Error canceling playback.")
		} finally {
			isWaiting = false
		}
	}

	async function playOnDevice() {
		try {
			isWaiting = true
			Toast.success("Your show will start playing momentarily.")
			await device.previewShow(scene, 1)
			isPlaying = true
		} catch (e) {
			console.error("Error playing show on device... ", e)
			if (e && e.statusCode === 701)
				Toast.error(
					"Device appears to be disconnected, ensure is connected to Wi-Fi and try again."
				)
			else Toast.error("Unable to play show at this time. Please refresh and try again.")
		} finally {
			isWaiting = false
		}
	}

	function handleClick() {
		if (isOffline) {
			Toast.error("Device appears to be disconnected, ensure is connected to Wi-Fi and try again.")
			return
		}

		if (isWaiting) return

		if (isPlaying) {
			stopPlayback()
		} else {
			playOnDevice()
		}
	}

	function checkStatus(dev: Device) {
		if (dev.id != device.id) return

		if (dev instanceof DeviceGroup) {
			isOffline = false
			isPlaying =
				dev.getChildDevices().filter((d) => d.getEidos().display_mode === "PREVIEW").length > 0

			console.warn(
				`isPlaying? Children statuses = ${dev
					.getChildDevices()
					.filter((d) => d.isOnline)
					.map((d) => d.getEidos().display_mode)}`
			)
			return
		}

		if (!dev.isOnline) {
			isOffline = true
		} else {
			isOffline = false
		}

		if (
			(dev.eidos.display_mode === "STATIC" || dev.eidos.display_mode === "PREVIEW") &&
			dev.eidos.proj_id === scene.id
		) {
			isPlaying = true
		} else {
			isPlaying = false
		}
	}

	function registerListener(dev: Device) {
		if (!(dev instanceof DeviceGroup)) {
			dev.addUpdateListener(checkStatus)
			return
		} else {
			for (const child of dev.getChildDevices()) {
				child.addUpdateListener((d) => checkStatus(dev))
			}
		}
	}

	registerListener(device)
</script>

{#if isWaiting}
	<LoadingSpinner color="var(--color-main)" height="3.5rem" />
{:else}
	<button
		id="play-lightshow-btn"
		class="icon"
		title={isPlaying ? "Stop Playback" : "Play on Projector"}
		on:click={handleClick}
	>
		{#if isPlaying}
			<StopIcon />
		{:else}
			<PlayIcon />
		{/if}
	</button>
{/if}

<style>
	#play-lightshow-btn {
		outline: 1px solid var(--color-main);
		padding: 1rem;
		opacity: 0;
		user-select: none;
		pointer-events: none;
		transition:
			outline-offset 250ms ease-out,
			outline-color 250ms;
	}

	#play-lightshow-btn:hover,
	#play-lightshow-btn:focus-visible {
		outline-offset: 0.5rem;
	}

	#play-lightshow-btn :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
