<script lang="ts">
	import { DataHandlerDevice, Snapshot, type DeviceGroupSlot } from "luxedo-data"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	// #region Group & Slot Props

	export let slot: DeviceGroupSlot
	let slotElem: HTMLDivElement

	let selectedSlot: DeviceGroupSlot
	let device = DataHandlerDevice.get(slot.device_id)
	let snapshot: Snapshot

	let tempPosX, tempPosY

	const onKeydown = (e: KeyboardEvent) => {
		isDragging = true

		switch (e.key) {
			case "ArrowLeft":
				tempPosX = (tempPosX ?? slot.pos_x) - 1
				break
			case "ArrowRight":
				tempPosX = (tempPosX ?? slot.pos_x) + 1
				break
			case "ArrowDown":
				tempPosY = (tempPosY ?? slot.pos_y) + 1
				break
			case "ArrowUp":
				tempPosY = (tempPosY ?? slot.pos_y) - 1
				break
		}
	}

	const onKeyup = () => {
		isDragging = false

		if (tempPosX !== undefined || tempPosY !== undefined) {
			GroupEditorController.updateSlot({
				pos_x: tempPosX ?? slot.pos_x,
				pos_y: tempPosY ?? slot.pos_y,
			})
		}

		tempPosX = undefined
		tempPosY = undefined

		setTimeout(() => {
			GroupEditorController.View.refreshZoom()
		})
	}

	GroupEditorController.subscribe((ctx) => {
		selectedSlot = ctx.selectedSlot
		if (selectedSlot?.id === slot.id) initKeyListeners()
		else clearKeyListeners()
	})

	// #endregion  Group & Slot Props
	// #region View Options

	let gridImages: { [index: number]: string }
	let showSnapshots: boolean
	let scale: number

	GroupEditorController.View.subscribe((ctx) => {
		showSnapshots = ctx.showSnapshots
		gridImages = ctx.gridImages
		scale = ctx.zoomLevel
	})

	// #endregion View Options
	// #region Slot Update Operations

	/**
	 * Updates the device in memory according to the assigned slot's device id
	 */
	function updateDevice() {
		device = DataHandlerDevice.get(slot.device_id)

		updateSnapshot()
	}

	/**
	 * Updates the active snapshot according to the device in memory
	 */
	async function updateSnapshot() {
		if (device) snapshot = await device.getSnapshot()
		else snapshot = undefined
	}

	/**
	 * Updates the selected slot upon click
	 */
	function handleSlotClick() {
		GroupEditorController.selectSlot(slot)
	}

	$: slot && updateDevice()

	// #endregion Slot Update Operations
	// #region Drag Operations

	let isDragging = false

	/**
	 * Handles mousedown to initiate drag event
	 */
	function handleSlotMousedown(
		e: MouseEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.button !== 0) return

		GroupEditorController.selectSlot(slot)

		const startPos = {
			x: e.clientX,
			y: e.clientY,
		}

		const updatePosition = () => {
			if (tempPosX !== undefined) {
				GroupEditorController.updateSlot({
					pos_x: Math.floor(tempPosX),
					pos_y: Math.floor(tempPosY),
				})

				setTimeout(() => {
					const { right, bottom } = GroupEditorController.View.getContainerBounds()

					if (
						slotElem.getBoundingClientRect().right < right ||
						slotElem.getBoundingClientRect().bottom < bottom
					)
						GroupEditorController.View.refreshZoom()
				})

				tempPosX = undefined
				tempPosY = undefined
			}

			isDragging = false
			document.removeEventListener("mousemove", handler)
			document.removeEventListener("mouseup", updatePosition)
		}

		const handler = (e: MouseEvent) => {
			const baseX = slot.pos_x
			const baseY = slot.pos_y

			const movementX = (e.clientX - startPos.x) / scale
			const movementY = (e.clientY - startPos.y) / scale

			tempPosX = baseX + movementX
			tempPosY = baseY + movementY

			isDragging = true
		}

		document.addEventListener("mousemove", handler)
		document.addEventListener("mouseup", updatePosition)
	}

	function clearKeyListeners() {
		document.removeEventListener("keydown", onKeydown)
		document.removeEventListener("keyup", onKeyup)
	}

	function initKeyListeners() {
		document.addEventListener("keydown", onKeydown)
		document.addEventListener("keyup", onKeyup)
	}

	// #endregion Drag Operations
</script>

<div
	class="group-slot {selectedSlot?.id === slot.id ? 'selected' : ''} {isDragging ? 'drag' : ''} "
	on:click={handleSlotClick}
	on:mousedown={handleSlotMousedown}
	bind:this={slotElem}
	style="background-color: {device?.color}44; --color-border: {device?.color}; width: {slot.width *
		slot.scale_x}px; height: {slot.height * slot.scale_y}px; left: {isDragging
		? tempPosX
		: slot.pos_x}px; top: {isDragging ? tempPosY : slot.pos_y}px;"
>
	{#if snapshot && showSnapshots}
		<img src={snapshot.src} alt="" />
	{:else if gridImages !== undefined && slot.id in gridImages}
		<img src="data:image/jpeg;base64,{gridImages[slot?.id]}" alt="" />
	{/if}
	<span id="device-name">{device?.name ?? "Unassigned"}</span>
</div>

<style>
	.group-slot {
		user-select: none;
		position: absolute;
		border: 5px solid var(--color-border);
		transition:
			left 500ms,
			top 500ms,
			height 500ms,
			width 500ms;
	}

	#device-name {
		font-size: var(--text-h1);
		position: absolute;
		bottom: 0;
		right: 1rem;
	}

	.group-slot.selected {
		outline: 5px solid var(--color-border);
		z-index: 100;
	}

	.group-slot.drag {
		transition: none;
	}

	img {
		height: 100%;
		width: 100%;
		pointer-events: none;
	}
</style>
