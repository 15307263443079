import { get, writable } from "svelte/store"
import { GroupEditorController } from "./DeviceGroupEditorController"

type MappedImages = {
	[index: number]: string
}

type CTX = {
	zoomLevel: number
	showSnapshots: boolean
	lockAspectRatio: boolean
	shrinkInputs: boolean
	gridImages: MappedImages
	panPosition: [number, number]
}

const store = writable<CTX>({
	zoomLevel: 1,
	shrinkInputs: false,
	lockAspectRatio: true,
	showSnapshots: true,
	gridImages: undefined,
	panPosition: [0, 0],
})

export namespace GroupEditorViewContext {
	export let projectorAlignmentContainer: HTMLDivElement

	export function setViewportElement(elem: HTMLDivElement) {
		if (!elem) return
		projectorAlignmentContainer = elem
		refreshZoom()
	}

	export function subscribe(subscriber: (ctx: CTX) => void) {
		return store.subscribe(subscriber)
	}

	export function getCurrentContext() {
		return get(store)
	}

	// #region Zoom Handling

	const DEFAULT_ZOOM_ADJUST = 0.01
	const MIN_ZOOM_LEVEL = 0.01
	const MAX_ZOOM_LEVEL = 3

	export function zoomIn() {
		const newZoomLevel = Math.min(MAX_ZOOM_LEVEL, get(store).zoomLevel + DEFAULT_ZOOM_ADJUST)
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function zoomOut() {
		const newZoomLevel = Math.max(MIN_ZOOM_LEVEL, get(store).zoomLevel - DEFAULT_ZOOM_ADJUST)
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function setZoom(zoom: number) {
		const newZoomLevel = Math.max(MIN_ZOOM_LEVEL, Math.min(MAX_ZOOM_LEVEL, zoom))
		store.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
	}

	export function refreshZoom() {
		const group = GroupEditorController.getCurrentContext().group

		const groupWidth = group.resX
		const groupHeight = group.resY

		const paddingWidth =
			Number(
				window
					.getComputedStyle(projectorAlignmentContainer, null)
					.getPropertyValue("padding-left")
					.replace("px", "")
			) * 2
		const paddingHeight =
			Number(
				window
					.getComputedStyle(projectorAlignmentContainer, null)
					.getPropertyValue("padding-top")
					.replace("px", "")
			) * 2
		const viewportWidth = projectorAlignmentContainer.getBoundingClientRect().width - paddingWidth
		const viewportHeight =
			projectorAlignmentContainer.getBoundingClientRect().height - paddingHeight

		const zoomX = viewportWidth / groupWidth
		const zoomY = viewportHeight / groupHeight

		const bestZoom = Math.floor(Math.min(zoomX, zoomY) * 100) / 100

		setZoom(bestZoom)
	}

	// #endregion Zoom Handling

	export function toggleAspectRatioLock(doLock?: boolean) {
		if (doLock !== undefined) {
			store.update((ctx) => ({ ...ctx, lockAspectRatio: doLock }))
		} else {
			store.update((ctx) => ({ ...ctx, lockAspectRatio: !ctx.lockAspectRatio }))
		}
	}

	export function toggleSnapshotVisibility(doShow?: boolean) {
		if (doShow !== undefined) {
			store.update((ctx) => ({ ...ctx, showSnapshots: doShow }))
		} else {
			store.update((ctx) => ({ ...ctx, showSnapshots: !ctx.showSnapshots }))
		}
	}

	export function toggleInputVisibility(doShow?: boolean) {
		if (doShow !== undefined) {
			store.update((ctx) => ({ ...ctx, shrinkInputs: !doShow }))
		} else {
			store.update((ctx) => ({ ...ctx, shrinkInputs: !ctx.shrinkInputs }))
		}

		setTimeout(() => {
			refreshZoom()
		}, 500)
	}

	export function setGridImages(images: MappedImages) {
		store.update((ctx) => ({
			...ctx,
			gridImages: images,
		}))
	}

	export function clearGridImages() {
		store.update((ctx) => ({ ...ctx, gridImages: undefined }))
	}
}
