import { fabric } from "fabric"
import { EditorClass } from ".."
import { Asset, CanvasAsset } from "../asset"
import { SelectionEvent, type SelectionEventInit } from "../events"
import { EventedInstance } from "../events/decorators"
import Mode from "../modules/mode"
import { Track, TrackLike } from "../tracks"

export interface SelectionController extends EventedInstance<SelectionEvent> {}

/**
 * @file controllers/Selection.ts
 * @author Austin Day 2023
 * @description A controller class for an editor which handles its selections
 */
@EventedInstance(SelectionEvent)
export class SelectionController {
	declare editor: EditorClass

	constructor(editor: EditorClass) {
		this.editor = editor
	}

	//#region    ===========================		  		  Properties				==============================

	get length() {
		return this.get().length
	}

	protected selection: Set<Selectable> = new Set([])

	//#endregion =====================================================================================================

	//#region    ===========================		  		  Interface 				==============================

	includes(asset: Selectable) {
		return this.get().includes(asset)
	}

	/**
	 * Returns the selected objects in the editor
	 * @returns - Array of selected asset instances
	 */
	get(): Selectable[] {
		return Array.from(this.selection)
	}

	getCanvas(): fabric.Object[] {
		return this.get().filter((item) => item instanceof fabric.Object) as fabric.Object[]
	}

	canSelect(target: any): boolean {
		if (!this.isSelectableType(target)) return false
		if (this.locked) return false
		if ("track" in target && target.track instanceof Track && target.track.locked) return false
		return true
	}

	isSelectableType(target: any): target is Selectable {
		return target instanceof Asset || target instanceof fabric.Object
	}

	/**
	 * Set the current selection and emit an event
	 * @param target
	 */
	set(target: Selectable | Selectable[], eventData?: Partial<SelectionEventInit>): void {
		if (this.locked) return

		if (target instanceof Array) {
			return this.setMultiple(target, eventData)
		}

		if (!this.canSelect(target)) return

		const e = this.set_(target, eventData?.origin)

		if (e) this.emitEvent(e)
	}

	/**
	 * Force set the selection silently
	 * returns an event, without actually emitting it
	 * @protected
	 */
	set_(target: Selectable, origin?: SelectionEvent["origin"]): SelectionEvent {
		const selection = this.get()

		if (selection.length == 1 && selection[0] === target) return undefined
		this.selection.clear()
		this.add_(target)

		// const newSelection = this.get()

		return new SelectionEvent("selection:created", {
			origin: origin ?? "automatic",
			selected: [target],
			previousSelection: selection,
		})
	}

	protected setMultiple(target: Selectable[], eventData?: Partial<SelectionEventInit>): void {
		const previousSelection = this.get()

		this.selection.clear()

		target.forEach((item) => {
			if (!this.canSelect(item)) return
			this.selection.add(item as Selectable)
		})

		this.editor.canvas.refreshSelection()

		this.emitEvent("selection:created", {
			origin: eventData?.origin ?? "automatic",
			selected: this.get(),
			previousSelection,
		})
	}

	/**
	 * Add an asset to the current selection
	 * @param asset
	 */
	add(asset: Selectable, eventData?: Partial<SelectionEventInit>): void {
		if (!this.canSelect(asset)) return

		const e = this.add_(asset, eventData)

		if (e) this.emitEvent(e)
	}

	/**
	 * Force add the selection silently
	 * returns an event, without actually emitting it
	 * @protected
	 */
	add_(asset: Selectable, eventData?: Partial<SelectionEventInit>): SelectionEvent {
		const previousSelection = this.get()

		this.selection.add(asset)

		if (asset instanceof fabric.Object) this.editor.canvas.refreshSelection(eventData?.canvasEvent)

		const event = new SelectionEvent("selection:changed", {
			origin: eventData ? eventData.origin : "automatic",
			selected: this.get(),
			previousSelection,
		})

		return event
	}

	/**
	 * Clear the selection
	 */
	clear(eventData?: Partial<SelectionEventInit>): void {
		if (this.locked) return

		const previousSelection = this.get()

		let success = true
		for (const sel of this.selection) {
			if (!this.canSelect(sel)) {
				success = false
				continue
			}
			this.selection.delete(sel)
		}

		const eventName = success ? "selection:cleared" : "selection:changed"
		this.emitEvent(eventName, {
			origin: eventData?.origin ?? "automatic",
			selected: this.get(),
			canvasEvent: eventData?.canvasEvent,
			previousSelection,
		})
		this.editor.canvas.refreshSelection(eventData?.canvasEvent)
	}

	/**
	 * Remove an asset from the current selection
	 * @param asset
	 */
	remove(asset: Selectable, eventData?: Partial<SelectionEventInit>): void {
		if (!this.canSelect(asset)) return

		const e = this.remove_(asset, eventData)

		if (e) this.emitEvent(e)
	}

	/**
	 * Force remove the a selected item silently
	 * returns an event, without actually emitting it
	 * @protected
	 */
	remove_(asset: Selectable, eventData?: Partial<SelectionEventInit>): SelectionEvent {
		const previousSelection = this.get()

		this.selection.delete(asset)
		if (asset instanceof fabric.Object) this.editor.canvas.refreshSelection(eventData?.canvasEvent)

		const eventName = this.selection.size > 0 ? "selection:changed" : "selection:cleared"
		const event = new SelectionEvent(eventName, {
			origin: eventData ? eventData.origin : "automatic",
			selected: this.get(),
			previousSelection,
		})

		return event
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  		  Interface 				==============================

	lockedBy?: Mode<any> = null

	/**
	 * a mode can call this to lock in control of the canvas
	 */
	lock(caller: Mode<any>) {
		this.lockedBy = caller

		const previousSelection = this.get()

		this.emitEvent("locked", {
			origin: "mode",
			selected: this.get(),
			previousSelection,
		})
	}

	/**
	 * Unlock the selection of the editor
	 * Should only be used by modes
	 */
	unlock() {
		this.lockedBy = null

		const previousSelection = this.get()

		this.emitEvent("unlocked", {
			origin: "mode",
			selected: this.get(),
			previousSelection,
		})
	}

	get locked(): boolean {
		return !!this.lockedBy
	}

	//#endregion =====================================================================================================
}

export type Selectable = Asset | CanvasAsset | fabric.Object
