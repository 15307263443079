<script lang="ts">
	import { onDestroy, onMount } from "svelte"
	import QuickSelectPointer from "./QuickSelectPointer.svelte"

	type Options = {
		[index: string]: () => void
	}

	export let options: Options
	export let isHidden: boolean = true
	export let remWidth: number = 10

	function stopPropagationOnHover(e: MouseEvent) {
		e.stopImmediatePropagation()
	}
</script>

{#if !isHidden}
	<div class="quick-select-bg" on:mouseover={stopPropagationOnHover} />
{/if}
<!-- <div class="quick-selector-absolute-helper"> -->
<div class="quick-selector-container">
	<div class="quick-selector {isHidden ? 'hidden' : ''}" style="width: {remWidth}rem; ">
		<QuickSelectPointer />

		{#each Object.entries(options) as [title, trigger]}
			<button on:mouseover={stopPropagationOnHover} on:click={trigger}>{title}</button>
		{/each}
	</div>
</div>

<!-- </div> -->

<style>
	.quick-selector-container {
		position: absolute;
		height: 0;
		width: 0;
	}

	.quick-select-bg {
		z-index: 5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	.quick-selector {
		z-index: 6;
		background-color: var(--color-bg-opaque);
		border: 1px solid var(--color-border);
		position: absolute;

		bottom: -2.5rem;
		left: -1rem;
		transform: translateY(100%);

		border-radius: 0.5rem;
		transition: opacity 250ms;
	}

	.quick-selector.hidden {
		user-select: none;
		pointer-events: none;
		opacity: 0;
	}

	.quick-selector button {
		width: 100%;
		padding: 0.5rem;
		background-color: transparent;
		box-shadow: unset;
		border-bottom: 1px solid var(--color-border);
		border-radius: unset;
		/* margin: 0 1rem; */
		text-align: left;
		transform: none;
	}

	.quick-selector button:hover,
	.quick-selector button:focus-visible {
		color: var(--color-main);
	}

	.quick-selector button:last-of-type {
		border: none;
	}

	.quick-selector > :global(svg) {
		width: 1.5rem;
		height: 1.5rem;

		position: absolute;
		top: -1.35rem;
		left: 0;
		right: 0;
		margin-left: 1rem;
		margin-right: auto;

		/* left: -1.8rem; */

		/* top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto; */
		transform: rotate(90deg);
	}
</style>
