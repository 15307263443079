<script lang="ts">
	import { DataHandlerDevice, Device, DeviceGroup, type DeviceGroupSlot } from "luxedo-data"
	import NumberRange from "../../../../reusable/input/NumberRange.svelte"
	import { ToggleSwitch } from "svelte-comps/inputs"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	export let slot: DeviceGroupSlot
	export let group: DeviceGroup

	let devices: Array<Device>
	let selectedDeviceId: number

	let isAspectRatioLocked: boolean = true // set from context

	GroupEditorController.View.subscribe((ctx) => (isAspectRatioLocked = ctx.lockAspectRatio))

	function onSlotUpdate() {
		selectedDeviceId = slot.device_id
		devices = filterDevices()
	}

	function filterDevices() {
		const allDevices = DataHandlerDevice.getMany()
		return allDevices.filter((device) => {
			if (
				group.slots.map((slot) => slot.device_id).includes(device.id) &&
				slot.device_id !== device.id
			)
				return false
			if (device.typeId === "dev_amalgam") return false
			if (device.getParent() && device.getParent().id !== group.id) return false
			return true
		})
	}

	function selectDevice(
		e: Event & {
			currentTarget: HTMLSelectElement
		}
	) {
		try {
			const device = DataHandlerDevice.get(Number(e.currentTarget.value))
			if (device) {
				GroupEditorController.updateSlot({
					device_id: Number(e.currentTarget.value),
					width: device.resX,
					height: device.resY,
				})
			} else {
				GroupEditorController.updateSlot({
					device_id: Number(e.currentTarget.value),
					width: 1920,
					height: 1080,
				})
			}
		} catch (e) {
			console.error("ERROR selecting device", e)
		}
	}

	function deselectDevice() {
		GroupEditorController.updateGroup((ctx) => {
			ctx.selectedSlot = undefined
			return ctx
		})
	}

	function removeSlot() {
		GroupEditorController.deleteSlot()
	}

	function onUpdatePosition(axis: "x" | "y") {
		let updateKey = `pos_${axis}`
		return (
			e: Event & {
				currentTarget: HTMLInputElement
			}
		) => {
			const target = e.currentTarget
			setTimeout(() => {
				// Wrapped in setTimeout to ensure target value is updated
				const value = Math.floor(Number(target.value))
				GroupEditorController.updateSlot({
					[updateKey]: value,
				})
			})
		}
	}

	function updateScaleX(e: CustomEvent) {
		const newScale = e.detail
		if (isAspectRatioLocked) {
			GroupEditorController.updateSlot({
				scale_x: newScale,
				scale_y: newScale,
			})
		} else {
			GroupEditorController.updateSlot({
				scale_x: newScale,
			})
		}
	}

	function updateScaleY(e: CustomEvent) {
		const newScale = e.detail
		GroupEditorController.updateSlot({
			scale_y: newScale,
		})
	}

	function onAspectRatioLock(newLock: boolean) {
		GroupEditorController.View.toggleAspectRatioLock(newLock)
	}

	function updatePriority(
		e: KeyboardEvent & {
			currentTarget: HTMLInputElement
		}
	) {
		GroupEditorController.updateSlot({
			priority: Number(e.currentTarget.value),
		})
	}

	$: slot && onSlotUpdate()
</script>

<div class="slot-options">
	<h3>Projector Slot Options</h3>
	<div class="slot-option-inputs">
		<div class="top">
			<div class="select-container">
				<label for="assigned-projector-input">Assigned Projector</label>
				<select
					name=""
					id="assigned-projector-input"
					bind:value={selectedDeviceId}
					on:change={selectDevice}
				>
					{#if devices}
						<option value={-1}>Unassigned</option>
						{#each devices as device (device.id)}
							<option value={device.id}>{device.name}</option>
						{/each}
					{/if}
				</select>
			</div>
			<div class="input-container">
				<span class="label">Lock Aspect Ratio</span>
				<ToggleSwitch isActive={isAspectRatioLocked} onUpdate={onAspectRatioLock} />
			</div>
		</div>
		<div class="bottom">
			<div id="position-inputs" class="input-container">
				<span class="label"> Position </span>
				<div class="flex-column">
					<div class="input-container">
						<label for="position">X: </label>
						<input
							id="position-input"
							type="number"
							step="1"
							value={slot.pos_x}
							on:keydown={onUpdatePosition("x")}
						/>
					</div>
					<div class="input-container">
						<label for="position-y-input">Y: </label>
						<input
							id="position-y-input"
							type="number"
							step="1"
							value={slot.pos_y}
							on:keydown={onUpdatePosition("y")}
						/>
					</div>
				</div>
			</div>
			<div class="scale-inputs">
				<NumberRange
					label="Vertical Scale"
					max={3}
					min={0.5}
					value={slot.scale_x}
					step={0.1}
					on:change={updateScaleX}
				/>
				<NumberRange
					label="Horizontal Scale"
					value={slot.scale_y}
					step={0.1}
					min={0.5}
					max={3}
					disabled={isAspectRatioLocked}
					on:change={updateScaleY}
				/>
			</div>
		</div>
		<div class="flex-row bottom-row">
			<div class="input-container">
				<label for="priority">Priority</label>
				<input id="priority-input" type="number" value={slot.priority} on:change={updatePriority} />
			</div>

			<div class="button-container">
				<button class="link-button small" on:click={deselectDevice}>Deselect</button>
				<button id="remove-slot-button" class="outline-button warn small" on:click={removeSlot}
					>Remove Projector</button
				>
			</div>
		</div>
	</div>
</div>

<style>
	.slot-options {
		flex-grow: 1;
		padding: 0 2rem;
	}

	h3 {
		font-size: var(--h1);
		margin: 0;
		line-height: 1.75em;
	}

	.slot-option-inputs {
		display: flex;
		flex-direction: column;
		/* width: fit-content; */
		background-color: var(--color-bg-dark);
		margin-top: 0.5rem;
		border-radius: var(--br);
		padding: 0.5rem 1rem;
	}

	.top,
	.bottom {
		display: flex;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
	}

	.scale-inputs {
		flex-grow: 1;
		margin-left: 1rem;
	}

	.scale-inputs :global(label) {
		width: 7em;
	}

	.label {
		margin-right: 0.5rem;
	}

	#position-inputs {
		align-items: flex-start;
	}

	#position-inputs .label {
		width: 3.5rem;
	}

	#position-inputs label {
		color: var(--color-text);
		width: 1rem;
	}

	#position-inputs input {
		background-color: var(--color-bg);
		padding: 0.2em 0.5em;
		line-height: 1rem;
		margin-left: 0.5rem;
		width: 3rem;
		font-size: var(--h3);
	}

	input[type="number"] {
		text-align: center;
	}

	.button-container {
		margin: 0;
		align-items: center;
		justify-content: flex-end;
	}

	.bottom-row label {
		width: 5.5rem;
	}

	.bottom-row input {
		width: 3rem;
		background-color: var(--color-bg);
		line-height: 1rem;
		font-size: var(--h3);
		padding: 0.2em 0.5em;
	}
</style>
