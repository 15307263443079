<script lang="ts">
	import type { DeviceGroup } from "luxedo-data"
	import { GroupEditorController } from "./DeviceGroupEditorController"
	import GroupEditorInputs from "./inputs/GroupEditorInputs.svelte"
	import GroupEditorAdjuster from "./alignment/GroupEditorAdjuster.svelte"
	import { closeOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { GroupEditorViewContext } from "./GroupEditorViewContext"
	import { DragArrowIcon } from "svelte-comps/icons"
	import LoadingSpinner from "../../../reusable/loaders/LoadingSpinner.svelte"

	export let group: DeviceGroup

	let isSaving = false
	let isHidingInput

	GroupEditorViewContext.subscribe((ctx) => (isHidingInput = ctx.shrinkInputs))

	async function close() {
		await GroupEditorController.reload() // This is here until a component is created to prompt the user
		await GroupEditorController.close()
		closeOverlay()
	}

	async function save() {
		isSaving = true
		try {
			await GroupEditorController.save()
			Toast.success("Projector group configuration updated successfully!")
		} catch (e) {
			console.error("ERROR updating projector group.")
			Toast.error("Projector group unable to update, please refresh and try again.")
		}
		isSaving = false
	}
</script>

<div id="projector-group-overlay" on:contextmenu|preventDefault>
	<div id="group-editor-top" class={isHidingInput ? "expand" : ""}>
		<div id="editor-heading">
			<h1>
				Align Projectors
				<span class="device-name">{group.name}</span>
			</h1>
		</div>
		<GroupEditorAdjuster />
		<button
			id="shrink-input-button"
			class={isHidingInput ? "inverse" : ""}
			title={isHidingInput ? "Show Options" : "Hide Options"}
			on:click={() => GroupEditorViewContext.toggleInputVisibility()}
		>
			<DragArrowIcon />
		</button>
	</div>
	<GroupEditorInputs />
	<div class="button-container">
		<button class="link-button" on:click={close}>Cancel</button>
		{#if isSaving}
			<LoadingSpinner color="var(--color-main)" height="2rem" />
		{:else}
			<button on:click={save}>Save</button>
		{/if}
	</div>
</div>

<style>
	#projector-group-overlay {
		width: 90vw;
		height: 90vh;
		display: flex;
		flex-direction: column;
	}

	#shrink-input-button {
		padding: 0;
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2rem;
		box-shadow: none;

		background-color: var(--color-bg-opaque);

		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;

		height: 1rem;
	}

	#shrink-input-button :global(svg) {
		transform: rotate(-90deg);
	}

	#shrink-input-button.inverse :global(svg) {
		transform: rotate(90deg);
	}

	#group-editor-top {
		position: relative;
		padding: 2rem;

		width: 100%;
		flex-grow: 1;
		background-color: var(--color-bg-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: padding 500ms;
	}

	#group-editor-top.expand {
		padding-bottom: 4rem;
	}

	#editor-heading {
		align-self: flex-start;
	}

	h1 {
		font-size: var(--h1);
	}

	.device-name {
		color: var(--color-text);
	}

	.button-container {
		position: absolute;
		bottom: 1rem;
		margin: 0;
		right: 2rem;
		width: fit-content;
	}
</style>
