import { DateTime } from "luxon"
import { Device } from "../.."
import {
	TimetableEvent,
	TimetableEventRepeat,
	TimetableEventSingle,
	type FullCalendarEvent,
	type TimetableRawData,
	type EventOccurrence,
} from "./TimetableEvent"
import { EventImpl } from "@fullcalendar/core/internal"

/** Handles pulling data  */

export class Timetable {
	declare device: Device
	declare events: Array<TimetableEvent>

	declare lastUpdated: Date
	declare version: string

	constructor(device: Device) {
		this.device = device
		this.events = []
	}

	/**
	 * Pulls and imports the timetable data from the server
	 * @param push If true, send the device timetable update with the internal data
	 */
	async refresh() {
		const rawData: TimetableRawData = await this.pull()
		this.events = []
		this.importData(rawData)
	}

	findEvent(projectId: number, startTime: DateTime): TimetableEvent | undefined {
		return this.events.find((event) => {
			const eventTiming = event.getTimingValues()
			const start = DateTime.fromISO(eventTiming.start)
			const end = DateTime.fromISO(eventTiming.end)
			return startTime >= start && startTime < end && event.show.id === projectId
		})
	}

	/**
	 * Finds a timetable event with the provided id
	 */
	getEvent(id: string): TimetableEvent
	getEvent(fcEvent: FullCalendarEvent): TimetableEvent
	getEvent(eventData: string | FullCalendarEvent): TimetableEvent {
		let id
		if (eventData instanceof EventImpl) id = eventData.extendedProps.eventId
		else id = eventData

		return this.events.find((event) => event.id === id)!
	}

	getEventsInOrder(
		limit: number = 10,
		options?: { from?: DateTime; to?: DateTime }
	): Array<EventOccurrence> {
		const allEvents = this.events
		const { from, to } = options ?? {}

		const eventOccurrences: { [index: string]: EventOccurrence[] } = {}

		for (const event of allEvents) {
			eventOccurrences[event.id] = event.getOccurrences()
		}

		const allOccurrences: Array<EventOccurrence> = []

		let i = 0
		for (const occurrences of Object.values(eventOccurrences)) {
			if (i >= limit) break
			for (const occurrence of occurrences) {
				if (i >= limit) break
				if (from && occurrence.start < from) continue
				if (to && occurrence.start > to) continue

				allOccurrences.push(occurrence)
				i++
			}
		}

		allOccurrences.sort((a, b) => {
			if (a.start < b.start) return -1
			if (a.start > b.start) return 1
			return 0
		})

		return allOccurrences
	}

	/** Requests from the server the user's timetable */
	private async pull() {
		return (await this.device.platoCall("timetable_get_frontend", [])) as TimetableRawData
	}

	async addEvent(event: TimetableEvent) {
		const existingEvent = this.getEvent(event.id)
		if (existingEvent) {
			this.events.splice(
				this.events.findIndex((e) => e.id === event.id),
				1,
				event
			)
		} else {
			this.events.push(event)
		}
		await this.push()
	}

	async deleteEvent(event: TimetableEvent) {
		this.events.splice(this.events.findIndex((e) => e.id === event.id))
		await this.push()
	}

	/** Sends an update to the device with the timetable data within this instance */
	async push() {
		const timetableJson = this.exportData()

		await this.device.platoCall("timetable_set", [timetableJson])
		await this.refresh()
	}

	/** Imports the raw data pulled from this.pull, creating event instances to populate this.events */
	private importData(data: TimetableRawData) {
		if (!data) return

		this.lastUpdated = new Date(data.metadata.last_modified)
		this.version = data.version

		for (const eventData of data.events) {
			try {
				if (eventData.repeat) {
					this.events.push(new TimetableEventRepeat(eventData, this.device) as TimetableEvent)
				} else {
					this.events.push(new TimetableEventSingle(eventData, this.device) as TimetableEvent)
				}
			} catch (e) {
				console.error("Caught broken event", eventData, e)
			}
		}
	}

	/** Exports the data and events from this module to create a json block which can be sent to the server to update the timetable. */
	private exportData() {
		const json = {
			metadata: {
				last_modified: DateTime.local().toUTC().toISO(),
			},
			events: this.events.map((event) => event.toJson()),
			version: this.version,
			precache_projects: [],
		}

		return json
	}
}
