import {
	DataHandlerDevice,
	DeviceRPi,
	type Device,
	type DeviceGroup,
	type DeviceGroupSlot,
	type DeviceGroupSlotOverlapOverride,
} from "luxedo-data"
import { writable, get } from "svelte/store"
import { GroupCanvasController } from "./canvas/GroupCanvasController"
import { GroupAlignController } from "./steps/align/GroupAlignController"
import { GroupOverlapController } from "./steps/blend/GroupOverlapController"
import { LuxedoRPC } from "luxedo-rpc"
import { Toast } from "svelte-comps/toaster"
import { closeOverlay } from "svelte-comps/overlay"

export namespace GroupEditorController {
	type ContextType = {
		step: number
		isNavigationLocked?: boolean
		group: DeviceGroup
		devices: Array<DeviceRPi>
		isAutoSaveActive: boolean
	}

	const CONTEXT_DEFAULT: ContextType = {
		step: 0,
		isNavigationLocked: false,
		isAutoSaveActive: false,
		group: undefined,
		devices: [],
	}

	export let overlayID
	const store = writable<ContextType>(CONTEXT_DEFAULT)
	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	function getGroupDevices(group: DeviceGroup) {
		return group.slots.map((slot) => DataHandlerDevice.get(slot.device_id) as DeviceRPi)
	}

	export function activate(group: DeviceGroup) {
		if (group.slots.length) {
			store.set({ devices: getGroupDevices(group), step: 3, group, isAutoSaveActive: false })
			Slots.setSlots(group.slots)
			Blend.toggleBlend(group.blending)
		} else store.set({ ...CONTEXT_DEFAULT, group })
	}

	export function reset() {
		const group = get(store).group
		if (group?.dirty) DataHandlerDevice.pull([group.id])

		GroupAlignController.reset()
		GroupCanvasController.reset()
		GroupOverlapController.reset()
		Slots.reset()
		store.set(CONTEXT_DEFAULT)
	}

	export function setNavigationLocked(locked: boolean) {
		store.update((ctx) => ({ ...ctx, isNavigationLocked: locked }))
	}

	export function setStep(step: number) {
		if (step > STEP_LIST.length - 1 || step < 0) return
		store.update((ctx) => {
			if (ctx.isNavigationLocked) return ctx
			return {
				...ctx,
				step,
			}
		})
	}

	export function next() {
		store.update((ctx) => {
			if (ctx.isNavigationLocked) return ctx
			return { ...ctx, step: ctx.step + 1 }
		})
	}

	export function back() {
		store.update((ctx) => {
			if (ctx.isNavigationLocked) return ctx
			return { ...ctx, step: ctx.step - 1 }
		})
	}

	export function toggleAutoSave(doAutoSave?: boolean) {
		if (doAutoSave === undefined) store.update((ctx) => ({ ...ctx, isAutoSaveActive: !ctx.isAutoSaveActive }))
		else store.update((ctx) => ({ ...ctx, isAutoSaveActive: doAutoSave }))

		save()
	}

	export function getActiveGroup() {
		return get(store).group
	}

	function restrictGroupBounds(group: DeviceGroup) {
		const MAX_WIDTH = 8192
		const MAX_HEIGHT = 4320
		function clampGroupSize() {
			const maxX = group.resX
			const maxY = group.resY

			if (maxX <= MAX_WIDTH && maxY <= MAX_HEIGHT) return

			const scaleFactor = Math.max(maxX / MAX_WIDTH, maxY / MAX_HEIGHT)

			for (const slot of group.slots) {
				slot.pos_x /= scaleFactor
				slot.scale_x /= scaleFactor
				slot.pos_y /= scaleFactor
				slot.scale_y /= scaleFactor
			}
		}

		function normalizeGroupScales() {
			let minScale = 999999
			for (const slot of group.slots) {
				if (slot.deleted) continue
				if (slot.scale_x < minScale) minScale = slot.scale_x
				if (slot.scale_y < minScale) minScale = slot.scale_y
			}

			if (minScale == 1) return

			for (const slot of group.slots) {
				if (slot.deleted) continue
				slot.scale_x /= minScale
				slot.scale_y /= minScale
			}
		}

		function clampGroupPositions() {
			let minX = 999999
			let minY = 999999

			for (const slot of group.slots) {
				if (slot.deleted) continue
				if (slot.pos_x < minX) minX = slot.pos_x
				if (slot.pos_y < minY) minY = slot.pos_y
			}

			if (minX == 0 && minY == 0) return

			for (const slot of group.slots) {
				if (slot.deleted) continue
				slot.pos_x -= minX
				slot.pos_y -= minY
			}
		}

		clampGroupPositions()
		normalizeGroupScales()
		clampGroupSize()
	}

	/**
	 * Updates the device group with the updated content
	 * @param updateFn
	 */
	function updateGroupSlots(slots: Array<DeviceGroupSlot>, ignoreSave?: boolean) {
		store.update((ctx) => {
			let group = ctx.group
			group.slots = [...slots]

			restrictGroupBounds(group)
			GroupCanvasController.updateGroupSize(group.resX, group.resY)
			GroupOverlapController.updateOverlapInstances(group.slots)

			return ctx
		})

		if (ignoreSave) return
		else queuePreviewUpdate()
	}

	/**
	 * Set the selected devices and create new slots for each device, clearing the current slots
	 * @param devices
	 */
	export function setSelectedDevices(devices: Array<DeviceRPi>) {
		const previousSlots = Slots.getSlots().map((slot) => ({ ...slot }))

		store.update((ctx) => ({ ...ctx, devices }))
		Slots.setSlots([]) // Clear any slots
		let nextPosX = 0
		let nextPriority = 10
		for (const device of devices) {
			const existingSlot = previousSlots.find((slot) => slot.device_id === device.id)
			let slotData
			if (existingSlot) {
				slotData = Slots.addSlot(device, { ...existingSlot })
			} else {
				slotData = Slots.addSlot(device, { pos_x: nextPosX, priority: nextPriority-- }) // Create new slots for each device
			}
			const pos_x = slotData.pos_x
			const width = slotData.width
			nextPosX = pos_x + width
		}
		const slots = Slots.getSlots()
		updateGroupSlots(slots, true)

		setTimeout(async () => {
			await save(false, true)
		})
	}

	let previewLockout: number
	function queuePreviewUpdate() {
		if (!previewLockout) {
			applyPreview()
			previewLockout = setTimeout(() => (previewLockout = null), 1500)
		} else {
			clearTimeout(previewLockout)
			previewLockout = setTimeout(() => {
				previewLockout = null
				queuePreviewUpdate()
			}, 1500)
		}
	}

	async function applyPreview() {
		const ctx = get(store)
		const gridCtx = Grid.getContext()
		const images = await LuxedoRPC.api.device.device_group_arrangement_preview(
			ctx.group.id,
			{
				blending: Blend.getContext().isBlending ?? false,
				complex: gridCtx.gridType === "rainbow",
				grid_size: Grid.getContext().gridSize ?? 200,
			},
			ctx.group.slots
		)
		Grid.updateGridImages(images)
		if (ctx.isAutoSaveActive) await save()
	}

	async function stopPreview() {
		clearTimeout(previewLockout)
		const ctx = get(store)
		await LuxedoRPC.api.device.device_group_stop_preview(ctx.group.id)
	}

	export async function save(closeOnComplete?: boolean, doUpdatePreview?: boolean) {
		const ctx = get(store)
		if (!ctx.group) return

		try {
			await LuxedoRPC.api.device.device_group_arrangement_update(ctx.group.id, ctx.group.slots)
			await DataHandlerDevice.save(ctx.group)
			Toast.success("Group settings saved successfully!")
			if (closeOnComplete) closeOverlay(overlayID, true)
			if (doUpdatePreview) queuePreviewUpdate()
		} catch (e) {
			console.warn("Error saving group settings", e)
			Toast.error("Unable to save group settings... Please refresh and try again.")
		}
	}

	export const STEP_LIST: Array<{ title: string; description: string }> = [
		{
			title: "Select Devices",
			description: "Select which devices will be a part of this group",
		},
		{
			title: "Physically Align Projectors",
			description: "Align each projector in your projection space as closely as possible",
		},
		{
			title: "Calibrate Each Device",
			description: "Calibrate each projector to create a snapshot to better adjust alignment",
		},
		{
			title: "Align Snapshots",
			description: "Adjust the new snapshots' position to align with the physical placement",
		},
		{
			title: "Blend",
			description: "Set the gamma of each overlapping section for a smooth transition between each device",
		},
	]

	/** Controls adjusting the position and configuration of the slot instances within the GroupEditorCanvas */
	export namespace Slots {
		type ContextType = {
			slots: Array<DeviceGroupSlot>
			selectedSlot: number
			positionLockedSlots: { [index: number]: boolean }
			scaleLockedSlots: { [index: number]: boolean }
		}

		const CONTEXT_DEFAULT: ContextType = {
			slots: [],
			selectedSlot: undefined,
			positionLockedSlots: {},
			scaleLockedSlots: {},
		}

		const EMPTY_SLOT_DATA: DeviceGroupSlot = {
			id: -1,
			device_id: -1,
			height: 1080,
			width: 1920,
			group_id: null,
			pos_x: -1920,
			pos_y: 0,
			priority: 0,
			scale_x: 1.0,
			scale_y: 1.0,
		}

		let newSlotIndex = -1
		const slotStore = writable<ContextType>(CONTEXT_DEFAULT)

		export function subscribe(cb: (ctx: ContextType) => void) {
			return slotStore.subscribe(cb)
		}

		export function reset() {
			slotStore.set(CONTEXT_DEFAULT)
		}

		export function setSlotPositionLocked(slotID: number, doLock: boolean) {
			slotStore.update((ctx) => ({ ...ctx, positionLockedSlots: { ...ctx.positionLockedSlots, [slotID]: doLock } }))
		}

		export function getSlotPositionLocked(slotID: number) {
			return get(slotStore).positionLockedSlots[slotID] ?? false
		}

		export function setSlotScaleLocked(slotID: number, doLock: boolean) {
			slotStore.update((ctx) => ({ ...ctx, scaleLockedSlots: { ...ctx.scaleLockedSlots, [slotID]: doLock } }))
		}

		export function getSlotScaleLocked(slotID: number) {
			return get(slotStore).scaleLockedSlots[slotID] ?? false
		}

		/**
		 * Create a new slot with the provided device
		 * @param device
		 */
		export function addSlot(device: DeviceRPi, config: Partial<DeviceGroupSlot> = {}) {
			let newSlot: DeviceGroupSlot
			slotStore.update((ctx) => {
				let nextPriority

				if (ctx.slots && ctx.slots.length)
					nextPriority = ctx.slots.reduce((prev, curr) => (curr.priority > prev.priority ? curr : prev)).priority + 1
				else nextPriority = 1

				newSlot = {
					...EMPTY_SLOT_DATA,
					...config,
					id: config.id ?? newSlotIndex--,
					device_id: device.id,
					width: device.resX,
					height: device.resY,
					priority: nextPriority,
				}

				return {
					...ctx,
					slots: [...ctx.slots, newSlot],
				}
			})
			return newSlot
		}

		/**
		 * Sets the store's slot data
		 */
		export function setSlots(slots: Array<DeviceGroupSlot>) {
			slotStore.update((ctx) => ({ ...ctx, slots }))
			updateGroupSlots(slots)
		}

		/**
		 * Gets one slot from the store
		 */
		export function getSlot(slotID: number) {
			const slots = getSlots()
			return slots.find((slot) => slot.id === slotID)
		}

		/**
		 * Gets the slots from the store
		 */
		export function getSlots() {
			return get(slotStore).slots
		}

		/**
		 * Sets the provided slotID as the selected slot
		 * @param slotID
		 */
		export function selectSlot(slotID: number) {
			slotStore.update((ctx) => ({ ...ctx, selectedSlot: slotID }))
		}

		/**
		 * Clears the currently selected slot
		 */
		export function deselectSlot() {
			slotStore.update((ctx) => ({ ...ctx, selectedSlot: undefined }))
		}

		/**
		 * Updates the currently selected slot with the provided slot updates
		 * @param updates Slot updates
		 */
		export function updateSlot(updates: Partial<DeviceGroupSlot>) {
			let { slots, selectedSlot: selectedSlotID } = get(slotStore)
			const selectedSlot = slots.find((slot) => slot.id === selectedSlotID)
			if (!selectedSlot) return

			for (const [key, value] of Object.entries(updates)) {
				selectedSlot[key] = value
			}

			slots = slots.map((slot) => (slot.id === selectedSlotID ? selectedSlot : slot))
			slotStore.update((ctx) => ({ ...ctx, slots }))
			updateGroupSlots(slots)
		}

		/**
		 * Updates the properties of the specified slot overlap
		 * @param mainSlotID The ID of the slot who's values are being changed
		 * @param secondSlotID The ID of the slot overlapping with the slot above (mainSlotID)
		 * @param overlapConfig The new properties of the slot overlap
		 * @param overlapConfig.gamma The new gamma value of the slot overlap
		 * @param overlapConfig.relativeBrightness The new relative brightness value of the slot overlap
		 */
		export function updateSlotOverlap(
			mainSlotID: number,
			secondSlotID: number,
			overlapConfig: {
				gamma?: number
				relativeBrightness?: number
			}
		) {
			const { slots } = get(slotStore)

			const mainSlot = slots.find((slot) => slot.id === mainSlotID)
			if (!mainSlot) throw new Error(`Unable to apply overlap update (no slot not defined with id ${mainSlotID})`)

			const overlapFilter = (overlap: DeviceGroupSlotOverlapOverride) =>
				overlap.main_slot === mainSlotID && overlap.other_slot === secondSlotID

			// Get existing overlap if it exists
			let doesOverlapExist = true
			let overlapData = mainSlot.overlap_override.find(overlapFilter)
			if (!overlapData) {
				doesOverlapExist = false
				overlapData = {
					gamma: 2.2,
					main_slot: mainSlotID,
					other_slot: secondSlotID,
					rel_brightness: undefined,
				}
			}

			// Update the new (or existing overlap)
			overlapData.gamma = overlapConfig.gamma ?? overlapData.gamma
			overlapData.rel_brightness = overlapConfig.relativeBrightness ?? overlapData.rel_brightness

			if (doesOverlapExist) {
				// Update the existing overlap with the updated data
				const existingOverlapIndex = mainSlot.overlap_override.findIndex(overlapFilter)
				mainSlot.overlap_override.splice(existingOverlapIndex, 1)
				mainSlot.overlap_override.push(overlapData)
			} else {
				// Create new overlap with provided data
				mainSlot.overlap_override.push(overlapData)
			}

			const updatedSlots = slots.map((slot) => (slot.id === mainSlot.id ? mainSlot : slot))
			setSlots(updatedSlots)
		}

		/**
		 * Update the priority of the specified slot
		 * @param slotID the ID of the slot whose priority is being updated
		 * @param prioritySlotID the ID of the slot ^this slot is going above or below
		 * @param position "above" or "below" - if above, the priority will be set above the priorityslot
		 */
		export function updateSlotPriority(slotID: number, prioritySlotID: number, position: "above" | "below") {
			const { slots } = get(slotStore)

			// if above, shift the priority slot (and all below) down - set priority to priority slot value
			// if below, shift all below the priority slot down - set priority to priority slot value + 1

			const prioritizedSlots = slots.sort((a, b) => a.priority - b.priority) // the slots ordered by priority
			const prioritySlot_Value = slots.find((slot) => slot.id === prioritySlotID).priority // the value of the slot we are changing against
			const newPrioritySlot = slots.find((slot) => slot.id === Number(slotID)) // the slot we are changing

			let newPriority // the new priority value
			let newPrioritySlots // the slots with updated priority values
			if (position === "below") {
				newPriority = prioritySlot_Value
				if (newPrioritySlot.priority <= newPriority) return
			} else {
				newPriority = prioritySlot_Value + 1
				if (newPrioritySlot.priority >= newPriority) return
			}

			newPrioritySlot.priority = newPriority
			newPrioritySlots = prioritizedSlots.map((slot) => {
				// update the slot we specified to the new priority
				if (slot.id === Number(slotID)) return newPrioritySlot
				// update the other slots' priority, moving down if necessary
				if (slot.priority >= newPriority) slot.priority++
				// otherwise don't alter the slot
				return slot
			})

			slotStore.update((ctx) => ({ ...ctx, slots: newPrioritySlots }))
			updateGroupSlots(newPrioritySlots)
		}
	}

	/** Controls the projection of alignment grids  */
	export namespace Grid {
		type MappedImages = { [index: number]: string }
		type ContextType = {
			gridType?: "simple" | "rainbow"
			gridSize: number
			images: { [index: number]: string } // Mapped to slot_id
		}

		const gridStore = writable<ContextType>({
			images: {},
			gridSize: 200,
		})

		export function subscribe(cb: (ctx: ContextType) => void) {
			return gridStore.subscribe(cb)
		}

		export function getContext() {
			return get(gridStore)
		}

		export function setGridSize(newSize: number) {
			gridStore.update((ctx) => ({ ...ctx, gridSize: newSize }))
			queuePreviewUpdate()
		}

		export function updateGridImages(images: MappedImages) {
			gridStore.update((ctx) => ({ ...ctx, images }))
		}

		/**
		 * Activates the device group grid, saves the device group settings as preview.
		 * @param mode The grid type to show
		 */
		export async function activate(mode: "rainbow" | "simple", enableBlending?: boolean) {
			gridStore.update((ctx) => ({ ...ctx, gridType: mode }))
			queuePreviewUpdate()
		}

		/**
		 * Deactivates the device group grid, canceling any callbacks to reactivate
		 */
		export async function deactivate() {
			const { group } = get(store)

			gridStore.update((ctx) => ({
				...ctx,
				images: {},
			}))

			await stopPreview()
		}
	}

	export namespace Blend {
		export type ContextType = {
			isBlending: boolean
		}

		const blendStore = writable<ContextType>({
			isBlending: false,
		})

		export function toggleBlend(doBlend?: boolean) {
			if (doBlend !== undefined) blendStore.update((ctx) => ({ ...ctx, isBlending: doBlend }))
			else
				blendStore.update((ctx) => {
					doBlend = !ctx.isBlending
					return { ...ctx, isBlending: !ctx.isBlending }
				})

			const group = getActiveGroup()
			group.blending = doBlend

			DataHandlerDevice.save(group)

			queuePreviewUpdate()
		}

		export function getContext() {
			return get(blendStore)
		}

		export function subscribe(cb: (ctx: ContextType) => void) {
			return blendStore.subscribe(cb)
		}
	}

	export async function disableBlending() {
		const group = GroupEditorController.getActiveGroup()
		group.blending = false
		await GroupEditorController.save()
	}

	export async function enableBlending() {
		const group = GroupEditorController.getActiveGroup()
		group.blending = true
		await GroupEditorController.save()
	}
}
