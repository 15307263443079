<script lang="ts">
	import { DataHandlerDevice, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { onMount } from "svelte"

	export let devices: Array<Device>

	let activeDevice: Device
	let selectedDevice: number

	SelectedDeviceStore.subscribe((dev) => {
		activeDevice = dev
		if (activeDevice && activeDevice.id !== selectedDevice) {
			selectedDevice = activeDevice.id
		}
	})

	function updateSelectedDevice() {
		SelectedDeviceStore.set(DataHandlerDevice.get(selectedDevice))
	}

	$: selectedDevice && updateSelectedDevice()
</script>

<select
	id="device-selection"
	style="background-color: {activeDevice?.color}44;"
	bind:value={selectedDevice}
>
	{#each devices.reverse() as device}
		<option value={device.id}>{device.name}</option>
	{/each}
</select>

<style>
	select {
		padding: 0.5rem;
		margin-left: 1rem;
		line-height: 1rem;
		background-color: transparent;
	}

	option {
		background-color: var(--color-bg-dark);
	}
</style>
