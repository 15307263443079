import { fabric } from "fabric"
import { getEditor } from "../Editor"

export class PointControl extends fabric.Control {
	index: number
	point: fabric.Point
	onEdit: (pt: fabric.Point, event?: MouseEvent) => void
	deletable?: boolean

	constructor(
		point: fabric.Point,
		callback: PointControl["onEdit"],
		options?: Partial<fabric.Control>
	) {
		super(options)
		this.point = point
		this.onEdit = callback

		this.actionHandler = this.actionHandler.bind(this)
	}

	positionHandler(dim, finalmatrix, fabricObject, currentControl) {
		return fabric.util.transformPoint(this.point, getEditor().canvas.viewportTransform)
	}

	actionHandler(eventData: MouseEvent, transform: fabric.Transform, x: number, y: number) {
		const previousValue = this.point.clone()

		this.point.setXY(x, y)

		try {
			this.onEdit(this.point, eventData)
			return true
		} catch (e) {
			console.warn(`Edit blocked (${e.message})`)
			this.point.setFromPoint(previousValue)
			return false
		}
	}

	get canvas() {
		return getEditor().canvas
	}
	set canvas(v) {
		return
	}

	actionName = "pointEdit"
}
