<script lang="ts">
	import { ArrowUp } from "svelte-comps/icons"

	type IssueIndex = `${number}-${number}`

	let selectedIssueIndex: IssueIndex
	let selectedIssueId: string

	let isSolutionOpen: boolean = false

	// Issues which map to the solutions below. Each issue can have multiple statements associated.
	const commonIssues: {
		[index: string]: Array<string>
	} = {
		glare: [
			"There is a bright reflection on the inside of the glass.",
			"I can see a reflection of the camera in the photos.",
		],
		env_daylight: ["The projection space is completely lit up."],
		env_bright: ["The light patterns are hard to see over other lights in the image."],
		lowexp: ["The image is very dim and it is hard to see the light bars."],
		nosig: [
			"The projector did not project anything during calibration.",
			"The projector projected a blank blue screen during calibration.",
			"The projector showed a 'No Signal' dialogue box.",
		],
		moved: ["The projector or camera is moving between shots."],
		proj_too_bright: ["The white bars are extremely bright and bleed out into surrounding pixels."],
		unfocus: ["The bars in the image appear blurry."],
		cam_unfocus: ["The bars are crisp and clear in person, but appear blurry in the photos."],
		toosmall: ["The projector only fills a very small part of the image."],
		// lowexp_colors: ["The bars of light appear to be distorted and colorful instead of white."],
	}

	// Solutions which are mapped to the issues above. Each solution can have a multiline explanation.
	const commonIssueSolutions: {
		[index: string]: Array<string>
	} = {
		nosig: [
			"If you do not see any projection patterns in your images, this probably means your projector is not properly connected.",
			"Make sure your projector is powered on and light is coming out of it, that the HDMI cord for your projector is properly plugged in, and that the projector has its input source set to the correct HDMI port.",
		],
		moved: [
			"Shaking, adjusting, or otherwise moving your camera or proejctor during calibration will always cause it to fail.",
			"Always make sure your device is steady before calibration, and is kept perfectly still throughout the process.",
		],
		unfocus: [
			"A blurry projection is the result of your projector being out of focus. ",
			"To focus your device, activate the spotlight when configuring your Calibration, then use the focus slider which should be located on your projector itself to adjust until the image is clear and crisp.",
		],
		cam_unfocus: [
			"An unfocused camera may be due to the camera being too far from the target, or being aimed at empty space.",
			"To address this, try adjusting the position of your Luxedo system so it is closer and more directly aimed at your target. For LuxCast and LuxLink users, consider using a different camera if this persists.",
		],
		toosmall: [
			"A projection target for calibration being too small can cause a calibration to fail or mess up. If possible, try moving your setup or camera closer to your target.",
			"If you cannot move any closer, consider lowering the Level of Detail in Advanced Options before calibration",
		],
		lowexp_colors: [
			"Bright colors in the calibration image may indicate that your exposure settings are too low.",
			"To fix this, increase your 'Exposure Level' in Advanced Options when setting up a calibration.",
		],
		proj_too_bright: [
			"It is possible for a projector to be TOO bright, causing bloom in the image and disrupting calibration. ",
			"To combat this, first try reducing the 'Exposure' option when configuring your calibration. If you are in an extremely dark environment, it may also help to try increasing the 'Dimming' option when configuring your calibration, or reducing the brightness of the projector itself.",
		],
		env_bright: [
			"Environments that are too bright to clearly see the calibration pattern are much more likely to fail calibration. Options to improve calibration in a bright environment include:",
			" - Waiting until it is darker / turning off all lights",
			" - Experimenting with lower exposure values when configuring calibration",
			" - Adjusting projector brightness settings or using a brighter projector",
			" - Bribing local authorities to turn off nearby street lights ",
		],
		env_daylight: ["Calibrations will not work during the day. Please wait until it is dark."],
		glare: [
			"If your device is experiencing glare, there are a few steps which will fix this:",
			" - Attempt a calibration at dusk; this tends to provide the best result.",
			" - Completely remove the lid of your enclosure while calibrating.",
			" - Follow <a href='https://portal.myluxedo.com/static/luxedo/documents/Camera-Shroud-Instructions.pdf'>this guide</a> to cover the internal camera - occasionally, the reflection causes the camera to be seen in the calibration images, resulting in a failed or distorted calibration.",
		],
		lowexp: [
			"If the image appears too dark, the exposure settings of the calibration are likely too low.",
			"To fix this, we recommend calibrating again with a higher Exposure Level (Advanced Options), and changing the Exposure Mode to 'Exact Value'. If it continues to appear too dim, simply try again with higher exposure values (Exposure mode should still be on 'Exact Value') until it works.",
		],
	}

	function selectIssue(id: string, indexId: IssueIndex) {
		selectedIssueId = id
		selectedIssueIndex = indexId

		if (!isSolutionOpen) setTimeout(() => (isSolutionOpen = true), 10)
	}

	function clearSelectedIssue() {
		selectedIssueIndex = undefined
		isSolutionOpen = false
	}

	function isIssueSelected(issueIndex: IssueIndex, i: number, o: number) {
		if (!issueIndex) return false
		const parts = issueIndex.split("-")
		return i === Number(parts[0]) && o === Number(parts[1])
	}
</script>

<div id="common-calibration-issues">
	<p>
		After viewing the images from the calibration, check if you are experiencing any of the issues
		below. Click the issue to view the solution.
	</p>
	<ul class="cal-issues">
		{#each Object.entries(commonIssues) as [id, issues], i}
			{#each issues as issue, o}
				<li
					class={isIssueSelected(selectedIssueIndex, i, o) ? "selected" : ""}
					role="listitem"
					tabindex="0"
					on:keydown={(e) => {
						if (e.key === "Enter") selectIssue(id, `${i}-${o}`)
					}}
					on:click={() => selectIssue(id, `${i}-${o}`)}
				>
					{issue}
				</li>
			{/each}
		{/each}
	</ul>
	{#if selectedIssueId}
		<div class="solution-container {isSolutionOpen ? 'open' : ''}">
			<div class="flex-row">
				<h3>Solution</h3>
				<button id="close-solution-button" class="icon" on:click={clearSelectedIssue}>
					<ArrowUp />
				</button>
			</div>
			<p>
				{#each commonIssueSolutions[selectedIssueId] as solutionString}
					<span>
						{@html solutionString}
					</span>
				{/each}
			</p>
		</div>
	{/if}
</div>

<style>
	#common-calibration-issues {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		height: calc(100% - 2rem);
		padding-bottom: 1rem;
	}

	.cal-issues {
		flex-shrink: 1;
		overflow-y: scroll;
	}

	h3 {
		color: var(--color-main);
		margin: 0;
	}

	li {
		cursor: pointer;
		color: var(--color-text);
		transition: color 150ms;
	}

	li:hover {
		color: var(--color-main);
	}

	li.selected {
		color: var(--color-text-light);
	}

	.solution-container {
		transition: height 500ms;
		height: 0rem;
	}

	.solution-container span {
		display: inline-block;
	}

	.solution-container.open {
		height: 100%;
	}

	.flex-row {
		align-items: center;
		margin: 2rem 0 0.5rem 0;
	}

	#close-solution-button :global(svg) {
		rotate: 180deg;
		height: 2rem;
		width: 2rem;
	}

	#close-solution-button :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#close-solution-button:hover :global(.svg-stroke),
	#close-solution-button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}

	.solution-container :global(a) {
		color: var(--color-text-light);
		border-color: var(--color-main);
	}
</style>
