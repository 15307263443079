import { Device, DeviceGroup, DeviceRPi, type DeviceGroupSlot, DataHandlerDevice } from "luxedo-data"
import { openOverlay } from "svelte-comps/overlay"
import ProjectorGroupEditor from "./ProjectorGroupEditor.svelte"
import { get, writable, type Updater } from "svelte/store"
import { DataSaveError } from "../../../../types/ErrorVariants"
import { LuxedoRPC } from "luxedo-rpc"

type DeviceGroupSlots = Array<DeviceGroupSlot>
type MappedImages = { [index: number]: string }
type BlendingOptions = { isBlendActive: false } | { isBlendActive: true; gamma: number }
type AlignmentOptions =
	| { isGridActive: false }
	| { isGridActive: true; gridMode: "simple" | "stitched"; gridSize: number }

type EditorCTX = {
	editing: boolean
	group: DeviceGroup
	slots: DeviceGroupSlots
	selectedSlot: DeviceGroupSlot
	width: number
	height: number
	alignmentOptions: AlignmentOptions
	blendingOptions: BlendingOptions
}

type ViewCTX = {
	zoomLevel: number
	showSnapshots: boolean
	lockAspectRatio: boolean
	shrinkInputs: boolean
	gridImages: MappedImages
	panPosition: [number, number]
}

export const MAX_WIDTH = 8192
export const MAX_HEIGHT = 4320
export const GRID_DURATION = 300

const EMPTY_SLOT_DATA: DeviceGroupSlot = {
	id: -1,
	device_id: -1,
	height: 1080,
	width: 1920,
	group_id: null,
	pos_x: -1920,
	pos_y: 0,
	priority: 0,
	scale_x: 1.0,
	scale_y: 1.0,
}

/** The controller for the Device Group Editor - handles the view and state of the Device Group Editor. */
export namespace GroupEditorController {
	let newSlotIndex = -1
	const store = writable<EditorCTX>({
		editing: false,
		group: null,
		slots: [],
		selectedSlot: null,
		width: 1920,
		height: 1080,
		alignmentOptions: {
			isGridActive: false,
		},
		blendingOptions: {
			isBlendActive: false,
		},
	})

	export function subscribe(cb: (ctx: EditorCTX) => void) {
		return store.subscribe(cb)
	}

	export function getCurrentContext() {
		return get(store)
	}

	/** Opens the Projector Group Editor overlay */
	export function open(device: DeviceGroup) {
		if (!(device instanceof DeviceGroup)) throw new Error("Provided device was not a device group.")
		activate(device)
		openOverlay(ProjectorGroupEditor, {
			classHeading: "no-underline ",
			classOverlay: "no-pad large",
			props: {
				group: device,
			},
		})
	}

	/**
	 * Close the editor context and clear it
	 * This WILL error if you try to close it with a dirty lightshow
	 * You must explicitly call reload() or save() first to prevent this
	 * 	- catching this error is a good way to trigger an "unsaved work" prompt
	 * @throws { DataSaveError }
	 */
	export function close() {
		const group = get(store).group

		if (group.dirty) {
			throw new DataSaveError("Unsaved device slot configuration")
		}

		store.set({
			editing: false,
			group: null,
			slots: [],
			selectedSlot: null,
			width: 0,
			height: 0,
			alignmentOptions: {
				isGridActive: false,
			},
			blendingOptions: {
				isBlendActive: false,
			},
		})
	}

	/**
	 * reload the slot configuration of the current group from the server
	 */
	export async function reload() {
		const group = get(store).group

		if (!group.dirty) return

		await DataHandlerDevice.pull()
		store.update((ctx) => {
			ctx.group = DataHandlerDevice.get(group.id) as DeviceGroup
			ctx.slots = ctx.group.slots
			ctx.selectedSlot = null
			return ctx
		})
	}

	/** Save the arrangement of the device group */
	export async function save() {
		const ctx = get(store)
		ctx.group.slots = ctx.slots

		if (!ctx.editing || !ctx.group || !ctx.group.dirty) return

		await LuxedoRPC.api.device.device_group_arrangement_update(ctx.group.id, ctx.slots)
		await DataHandlerDevice.save(ctx.group)
		await reload()
	}

	/** Updates the state with the provided group's data */
	function activate(group: DeviceGroup) {
		store.update((ctx) => {
			ctx.editing = true
			ctx.group = group
			ctx.slots = group.slots
			ctx.blendingOptions = {
				// gamma: group.gamma < 0 ? 0 : group.gamma,
				// isBlendActive: group.gamma > 0,
				gamma: 0,
				isBlendActive: true,
			}
			return ctx
		})

		if (group.slots.length === 0) {
			setTimeout(createSlot)
		}
	}

	/** Updates the group with the updated context, clamping all slot positions and scales */
	export function updateGroup(updater?: Updater<EditorCTX>) {
		store.update((ctx) => {
			if (updater) ctx = updater(ctx)

			ctx.group.slots = ctx.slots

			View.clampGroupPositions(ctx.group)
			View.normalizeGroupScales(ctx.group)
			View.clampGroupSize(ctx.group)

			ctx.width = ctx.group.resX
			ctx.height = ctx.group.resY

			return ctx
		})

		updateGridPreview()
	}

	// #region SLOT MANAGEMENT

	/** Sets the selected slot to the provided slot */
	export function selectSlot(slot: DeviceGroupSlot) {
		if (slot.scale_x !== slot.scale_y) View.toggleAspectRatioLock(false)
		store.update((ctx) => {
			ctx.selectedSlot = slot
			return ctx
		})
	}

	/** Applies the provided changes to the currently selected slot */
	export function updateSlot(updates: Partial<DeviceGroupSlot>) {
		updateGroup((ctx) => {
			for (const [key, value] of Object.entries(updates)) {
				if (!ctx.selectedSlot || !(key in ctx.selectedSlot)) continue
				ctx.selectedSlot[key] = value
			}

			// force an update
			ctx.selectedSlot = { ...ctx.selectedSlot }
			ctx.slots = [...ctx.slots.map((slot) => (slot.id === ctx.selectedSlot.id ? ctx.selectedSlot : slot))]

			return ctx
		})
	}

	/** Adds a new slot to the device group */
	export function createSlot(): void
	export function createSlot(slotData: Partial<DeviceGroupSlot>): void
	export function createSlot(device: Device): void
	export function createSlot(slotData?: Partial<DeviceGroupSlot> | Device) {
		const newSlot: DeviceGroupSlot = { ...getEmptySlotData() }

		if (slotData instanceof Device) {
			if (!canAddDeviceToGroup(slotData)) {
				throw TypeError(`Cannot add device ${slotData.name} (${slotData.constructor.name}) to the group`)
			}
			newSlot.device_id = slotData.id
			newSlot.width = slotData.resX
			newSlot.height = slotData.resY
			newSlot.pos_x = -slotData.resX
			newSlot.pos_y = 0
		} else if (slotData) {
			for (const [k, v] of Object.entries(slotData)) {
				newSlot[k] = v
			}
		}

		const slots = get(store).slots
		slots.push(newSlot)

		View.refreshZoom()

		updateGroup((ctx) => ({ ...ctx, selectedSlot: newSlot }))
	}

	/** Delete the selected slot from the group */
	export function deleteSlot() {
		updateGroup((ctx) => {
			ctx.selectedSlot.deleted = true
			return ctx
		})
	}

	/** Returns the data for a new empty slot with an updated id */
	function getEmptySlotData() {
		const slotData = { ...EMPTY_SLOT_DATA }
		slotData["id"] = newSlotIndex--
		return slotData
	}

	/** Checks if the device is already a part of another group */
	function canAddDeviceToGroup(device: Device) {
		if (!(device instanceof DeviceRPi)) return false

		const group = get(store).group
		if (device.getParent() && device.getParent() !== group) return false

		const slotWithThisDevice = group.slots.find((slot) => slot.device_id === device.id && !slot.deleted)
		if (slotWithThisDevice) return false

		return true
	}

	// #endregion SLOT MANAGEMENT
	// #region GRID/BLENDING MANAGEMENT

	let gridTimeoutId

	export async function updateGridPreview() {
		const { alignmentOptions } = get(store)
		if (!alignmentOptions.isGridActive) return

		if (gridTimeoutId) clearTimeout(gridTimeoutId)
		gridTimeoutId = setTimeout(async () => {
			await showGrid(alignmentOptions.gridMode, alignmentOptions.gridSize, true)
		}, 1500)
	}

	export async function showGrid(
		gridMode: "simple" | "stitched",
		gridSize: number,
		forceUpdate?: boolean
	): Promise<{ [index: number]: string } | void> {
		const { group, alignmentOptions } = get(store)

		if (
			alignmentOptions.isGridActive &&
			alignmentOptions.gridMode == gridMode &&
			alignmentOptions.gridSize == gridSize &&
			!forceUpdate
		)
			return

		if (group.dirty) await save()

		let gridImages = undefined

		if (gridMode === "simple") {
			await LuxedoRPC.api.device.device_group_project_simple_grid(group.id, GRID_DURATION, gridSize)
		} else {
			gridImages = await LuxedoRPC.api.device.device_group_project_rainbow_grid(group.id, GRID_DURATION, gridSize)
		}

		store.update((ctx) => ({
			...ctx,
			alignmentOptions: {
				isGridActive: true,
				gridMode,
				gridSize,
			},
		}))

		// Set grid unactive after duration has finished
		gridTimeoutId = setTimeout(() => {
			store.update((ctx) => ({
				...ctx,
				alignmentOptions: {
					isGridActive: false,
				},
			}))

			gridTimeoutId = undefined
			View.clearGridImages()
		}, GRID_DURATION * 1000)

		if (gridImages) {
			View.setGridImages(gridImages)
			return gridImages
		}
	}

	export async function hideGrid() {
		const { group } = get(store)

		if (gridTimeoutId) clearTimeout(gridTimeoutId)
		View.clearGridImages()
		await LuxedoRPC.api.device.device_group_project_simple_grid(group.id, 0, 0)

		store.update((ctx) => ({
			...ctx,
			alignmentOptions: {
				isGridActive: false,
			},
		}))
	}

	export async function initializeBlending(gamma: number) {
		const { group } = get(store)
		// group.gamma = gamma
		store.update((ctx) => ({
			...ctx,
			blendingOptions: {
				isBlendActive: true,
				gamma: gamma,
			},
		}))

		await save()
		updateGridPreview()
	}

	export async function clearBlending() {
		const { group } = get(store)
		// group.gamma = 0
		store.update((ctx) => ({
			...ctx,
			blendingOptions: {
				isBlendActive: false,
			},
		}))

		updateGridPreview()
	}

	// #endregion GRID/BLENDING MANAGEMENT

	/** Handles ONLY view related methods (zoom in, ect) */
	export namespace View {
		let alignmentContainer: HTMLDivElement
		const viewStore = writable<ViewCTX>({
			zoomLevel: 1,
			shrinkInputs: false,
			lockAspectRatio: true,
			showSnapshots: true,
			gridImages: undefined,
			panPosition: [0, 0],
		})

		export function subscribe(cb: (ctx: ViewCTX) => void) {
			return viewStore.subscribe(cb)
		}

		export function setViewportElement(element: HTMLDivElement) {
			if (!element) return

			alignmentContainer = element
			setTimeout(refreshZoom)
		}

		export function getContainerBounds() {
			return alignmentContainer.getBoundingClientRect()
		}

		// #region ZOOM

		const DEFAULT_ZOOM_ADJUST = 0.01
		const MIN_ZOOM_LEVEL = 0.01
		const MAX_ZOOM_LEVEL = 3

		/** Resets the viewport zoom, centering the slot objects */
		export function refreshZoom() {
			const group = get(store).group

			const groupWidth = group.resX
			const groupHeight = group.resY

			const paddingWidth =
				Number(window.getComputedStyle(alignmentContainer, null).getPropertyValue("padding-left").replace("px", "")) * 2
			const paddingHeight =
				Number(window.getComputedStyle(alignmentContainer, null).getPropertyValue("padding-top").replace("px", "")) * 2
			const viewportWidth = alignmentContainer.getBoundingClientRect().width - paddingWidth
			const viewportHeight = alignmentContainer.getBoundingClientRect().height - paddingHeight

			const zoomX = viewportWidth / groupWidth
			const zoomY = viewportHeight / groupHeight

			const bestZoom = Math.floor(Math.min(zoomX, zoomY) * 100) / 100

			setZoom(bestZoom)
		}

		/** Zooms the viewport in */
		export function zoomIn() {
			const newZoomLevel = Math.min(MAX_ZOOM_LEVEL, get(viewStore).zoomLevel + DEFAULT_ZOOM_ADJUST)
			viewStore.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
		}

		/** Zooms the viewport out */
		export function zoomOut() {
			const newZoomLevel = Math.max(MIN_ZOOM_LEVEL, get(viewStore).zoomLevel - DEFAULT_ZOOM_ADJUST)
			viewStore.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
		}

		/** Sets the zoom level of the viewport */
		function setZoom(zoom: number) {
			const newZoomLevel = Math.max(MIN_ZOOM_LEVEL, Math.min(MAX_ZOOM_LEVEL, zoom))
			viewStore.update((ctx) => ({ ...ctx, zoomLevel: newZoomLevel }))
		}

		// #endregion ZOOM
		// #region VIEW OPTIONS (aspect ratio lock, snapshot visibility)

		export function toggleAspectRatioLock(doLock?: boolean) {
			if (doLock !== undefined) {
				viewStore.update((ctx) => ({ ...ctx, lockAspectRatio: doLock }))
			} else {
				viewStore.update((ctx) => ({ ...ctx, lockAspectRatio: !ctx.lockAspectRatio }))
			}
		}

		export function toggleSnapshotVisibility(doShow?: boolean) {
			if (doShow !== undefined) {
				viewStore.update((ctx) => ({ ...ctx, showSnapshots: doShow }))
			} else {
				viewStore.update((ctx) => ({ ...ctx, showSnapshots: !ctx.showSnapshots }))
			}
		}

		export function toggleInputVisibility(doShow?: boolean) {
			if (doShow !== undefined) {
				viewStore.update((ctx) => ({ ...ctx, shrinkInputs: !doShow }))
			} else {
				viewStore.update((ctx) => ({ ...ctx, shrinkInputs: !ctx.shrinkInputs }))
			}

			setTimeout(() => {
				refreshZoom()
			}, 500)
		}

		// #endregion VIEW OPTIONS
		// #region GRID IMAGES

		export function setGridImages(images: MappedImages) {
			viewStore.update((ctx) => ({
				...ctx,
				gridImages: images,
			}))
		}

		export function clearGridImages() {
			viewStore.update((ctx) => ({ ...ctx, gridImages: undefined }))
		}
		// #endregion GRID IMAGES
		// #region VIEWPORT RESIZING / CLAMPING

		/** Adjust everything so the top left is at (0, 0) */
		export function clampGroupPositions(group: DeviceGroup) {
			let minX = 999999
			let minY = 999999

			for (const slot of group.slots) {
				if (slot.deleted) continue
				if (slot.pos_x < minX) minX = slot.pos_x
				if (slot.pos_y < minY) minY = slot.pos_y
			}

			if (minX == 0 && minY == 0) return

			for (const slot of group.slots) {
				if (slot.deleted) continue
				slot.pos_x -= minX
				slot.pos_y -= minY
			}
		}

		/** Slot sizes should be normalized so that the smallest slot has a scale of 1 - this minimizes render sizes without sacrificing quality */
		export function normalizeGroupScales(group: DeviceGroup) {
			let minScale = 999999
			for (const slot of group.slots) {
				if (slot.deleted) continue
				if (slot.scale_x < minScale) minScale = slot.scale_x
				if (slot.scale_y < minScale) minScale = slot.scale_y
			}

			if (minScale == 1) return

			for (const slot of group.slots) {
				if (slot.deleted) continue
				slot.scale_x /= minScale
				slot.scale_y /= minScale
			}
		}

		/** Adjust everything so nothing is exceeding the scale limit */
		export function clampGroupSize(group: DeviceGroup) {
			const maxX = group.resX
			const maxY = group.resY

			if (maxX <= MAX_WIDTH && maxY <= MAX_HEIGHT) return

			const scaleFactor = Math.max(maxX / MAX_WIDTH, maxY / MAX_HEIGHT)

			for (const slot of group.slots) {
				slot.pos_x /= scaleFactor
				slot.scale_x /= scaleFactor
				slot.pos_y /= scaleFactor
				slot.scale_y /= scaleFactor
			}
		}

		// #endregion VIEWPORT RESIZING / CLAMPING
	}
}
