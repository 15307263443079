import { fabric } from "fabric"
import { CanvasAsset } from "../asset"

declare module "fabric" {
	namespace fabric {
		interface IObjectOptions {
			customCorner?: fabric.Control["customCorner"]
		}

		namespace controlsUtils {
			function renderSquareControl(ctx, left, top, styleOverride, fabricObject): void
			function renderCircleControl(ctx, left, top, styleOverride, fabricObject): void
		}

		interface Control {
			render(ctx, left, top, styleOverride: fabric.Object, fabricObject: fabric.Object): any
			customCorner(
				ctx,
				xsize: number,
				ysize: number,
				styleOverride: CanvasAsset,
				fabricObject: CanvasAsset
			): any
			styleOverride?: Partial<fabric.Object>
			clickableSize?: number
		}
	}
}

const baseCalcCornerCoords = fabric.Control.prototype.calcCornerCoords

export class CustomControlDefinition extends fabric.Control {
	render(ctx, left, top, styleOverride: fabric.Object, fabricObject: fabric.Object) {
		styleOverride = styleOverride || (this.styleOverride as fabric.Object) || ({} as fabric.Object)

		const customCornerRenderFn =
			styleOverride?.customCorner || this.customCorner || fabricObject?.customCorner
		if (customCornerRenderFn) {
			renderCustomControl(this, ctx, left, top, styleOverride, fabricObject, customCornerRenderFn)
			return
		}

		if (this.styleOverride) {
			fabricObject = {
				cornerColor: fabricObject.cornerColor,
				cornerDashArray: fabricObject.cornerDashArray,
				cornerStyle: fabricObject.cornerStyle,
				cornerStrokeColor: fabricObject.cornerStrokeColor,
				cornerSize: fabricObject.cornerSize,
				transparentCorners: fabricObject.transparentCorners,
				...this.styleOverride,
			} as fabric.Object
		}

		const style = fabricObject.cornerStyle

		if (style == "circle") {
			fabric.controlsUtils.renderCircleControl.call(
				this,
				ctx,
				left,
				top,
				styleOverride,
				fabricObject
			)
		} else {
			fabric.controlsUtils.renderSquareControl.call(
				this,
				ctx,
				left,
				top,
				styleOverride,
				fabricObject
			)
		}
	}

	calcCornerCoords(
		objectAngle: number,
		objectCornerSize: number,
		centerX: number,
		centerY: number,
		isTouch: boolean
	): void {
		if (this.clickableSize) objectCornerSize = this.clickableSize

		return baseCalcCornerCoords.call(this, objectAngle, objectCornerSize, centerX, centerY, isTouch)
	}
}

function renderCustomControl(
	_this,
	ctx,
	left,
	top,
	styleOverride,
	fabricObject,
	renderFunction: fabric.IObjectOptions["customCorner"]
) {
	styleOverride = styleOverride || {}
	var xSize = _this.sizeX || styleOverride.cornerSize || fabricObject.cornerSize,
		ySize = _this.sizeY || styleOverride.cornerSize || fabricObject.cornerSize,
		transparentCorners =
			typeof styleOverride.transparentCorners !== "undefined"
				? styleOverride.transparentCorners
				: fabricObject.transparentCorner

	ctx.save()
	ctx.fillStyle = styleOverride.cornerColor || fabricObject.cornerColor
	ctx.strokeStyle = styleOverride.cornerStrokeColor || fabricObject.cornerStrokeColor
	// this is still wrong
	ctx.lineWidth = 1
	ctx.translate(left, top)
	ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
	// this does not work, and fixed with ( && ) does not make sense.
	// to have real transparent corners we need the controls on upperCanvas
	// transparentCorners || ctx.clearRect(-xSizeBy2, -ySizeBy2, xSize, ySize);
	if (!transparentCorners) {
		renderFunction(ctx, xSize, ySize, styleOverride, fabricObject)
	}
	ctx.restore()
}

/* Add all the functionality to fabric.Control */
const Properties = Object.getOwnPropertyDescriptors(CustomControlDefinition.prototype)
delete Properties["constructor"]
Object.defineProperties(window.fabric.Control.prototype, Properties)

export const CustomControl = fabric.Control

export function applyPointExtension() {
	const Properties = Object.getOwnPropertyDescriptors(CustomControlDefinition.prototype)
	delete Properties["constructor"]
	Object.defineProperties(window.fabric.Control.prototype, Properties)
}
