<script lang="ts">
	import YouTubeVideoTutorial from "../../../../YouTubeVideoTutorial.svelte"
	import { GroupEditorController } from "../../GroupEditorController"
	import { GroupAlignController } from "./GroupAlignController"
</script>

<YouTubeVideoTutorial
	title="Align snapshots"
	description={`Adjust the position of the new snapshots relative to each other so that they align with the physical projection space.
	<br />
	<!--Watch this video before getting started: -->`}
/>
<!-- videoURL="https://www.youtube.com/embed/AjUkEHkYx0I" -->

<div id="step-buttons">
	<button id="back-step-button" class="link-button" on:click={() => GroupEditorController.back()}>
		Back
	</button>
	<button id="next-step-button" class="outline-button" on:click={() => GroupAlignController.next()}>
		Next
	</button>
</div>

<style>
</style>
