import type { BundleOf } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import type { Media, MediaRawData } from "../../entries/Media"
import { DHMedia } from "../DataHandlerMedia"

class DHMediaAdmin extends DHMedia {
	protected async requestCreateEntry(entryData: Partial<MediaRawData>): Promise<number> {
		const res = await LuxedoRPC.api.admin.media_create_admin(
			entryData.name!,
			entryData.parent_id!,
			entryData.storage_mimetype!,
			this.EntryClass.hashRawData(entryData)
		)
		const entryID = res.new_file_id
		return entryID
	}

	async requestDeleteEntry(entry: Media): Promise<void> {
		return await LuxedoRPC.api.admin.media_delete_admin(entry.id!)
	}
}

export const DataHandlerMediaAdmin = new DHMediaAdmin()
