import { RRule, datetime } from "rrule"
import { Device } from "../../entries/Device"
import { type EventInput } from "@fullcalendar/core"
import { DateTime, Duration } from "luxon"
import type { Lightshow } from "../../entries/Lightshow"
import type { Scene } from "../../entries/Scene"
import { DataHandlerScene } from "../../datahandlers/DataHandlerScene"
import { DataHandlerLightshow } from "../../datahandlers/DataHandlerLightshow"
import { EventImpl, getUniqueDomId } from "@fullcalendar/core/internal"

// #region Types

export type FrequencyType = "minutes" | "hours" | "days" | "weeks" | "months"

export type RepeatEventTiming = {
	rrule_string: string
	duration_iso: string
}

export type SingleEventTiming = {
	t_start: string
	t_end: string
}

interface ScheduledEventData_REPEAT {
	id: string
	name: string
	project_id: number
	repeat: 1
	timing: RepeatEventTiming
	inherited?: number
}

interface ScheduledEventData_SINGLE {
	id: string
	name: string
	project_id: number
	repeat: 0
	timing: SingleEventTiming
	inherited?: number
}

export type ScheduledEventData = ScheduledEventData_REPEAT | ScheduledEventData_SINGLE
export type FullCalendarEvent = EventImpl & {
	extendedProps: {
		eventId: string
		projectId: number
		deviceId: number
		inherited: boolean
	}
}

export type EventOccurrence = {
	start: DateTime
	end: DateTime
	event: TimetableEventSingle | TimetableEventRepeat
}

export interface TimetableRawData {
	metadata: {
		last_modified: string
	}
	version: string
	events: Array<ScheduledEventData>
}

// #endregion Types

export abstract class TimetableEvent {
	declare id: string
	declare name: string
	declare repeat: boolean
	declare show: Lightshow | Scene
	declare device: Device
	declare inherited?: number

	constructor(eventData: ScheduledEventData, device: Device) {
		this.id = eventData.id
		this.name = eventData.name
		this.inherited = eventData.inherited
		this.device = device
		this.show = DataHandlerScene.get(eventData.project_id) ?? DataHandlerLightshow.get(eventData.project_id)
		this.repeat = !!eventData.repeat
		this.importTiming(eventData.timing)
	}

	toJson() {
		return {
			id: this.id,
			name: this.name,
			project_id: this.show.id,
		}
	}

	toFullCalendarEvent(temp?: boolean): EventInput {
		const fcEvent: EventInput = {
			id: getUniqueDomId(),
			color: this.device && this.device.color ? this.device.color : "var(--color-main)",
			title: this.name,
			extendedProps: {
				eventId: this.id,
				projectId: this.show.id,
				deviceId: this.device.id,
				inherited: this.inherited ?? false,
			},
		}

		if (temp) fcEvent.extendedProps!["temp"] = true

		return fcEvent
	}

	/** Returns an object with keys as the input names (start, end, duration, ect) and values as the respective timing value */
	abstract getTimingValues(): {
		start: string
		end: string
		duration?: number
		frequencyAmount?: number
		frequencyType?: FrequencyType
		doRepeat?: boolean
	}

	abstract getOccurrences(): Array<EventOccurrence>

	abstract importTiming(timing: SingleEventTiming | RepeatEventTiming)
}

export class TimetableEventRepeat extends TimetableEvent {
	declare repeat: true
	declare timingData: RepeatEventTiming
	declare duration: Duration
	declare repeatRule: RRule

	importTiming(timing: RepeatEventTiming) {
		this.timingData = timing
		this.repeatRule = RRule.fromString(timing.rrule_string)
		this.duration = Duration.fromISO(timing.duration_iso)
	}

	toJson(): ScheduledEventData_REPEAT {
		const base = super.toJson() as ScheduledEventData_REPEAT
		base.repeat = 1
		base.timing = {
			duration_iso: this.duration.toISO()!,
			rrule_string: this.repeatRule.toString(),
		}
		return base
	}

	toFullCalendarEvent(temp?: boolean): EventInput {
		const fcEvent = super.toFullCalendarEvent(temp)
		fcEvent.rrule = this.repeatRule.toString()
		fcEvent.duration = this.duration
		fcEvent.extendedProps!.eventId = this.id
		return fcEvent
	}

	getOccurrences() {
		const occurrences = this.repeatRule.all()
		let occurrenceData: Array<{ start: DateTime; end: DateTime; event: typeof this.constructor }> = []

		for (const occurrence of occurrences) {
			const start = DateTime.fromJSDate(occurrence)
			const end = start.plus(this.duration)
			occurrenceData.push({
				start,
				end,
				event: this,
			})
		}

		return occurrenceData
	}

	getTimingValues() {
		const frequencyType = FrequencyOptionsReverse[this.repeatRule.options.freq]
		const frequencyAmount = this.repeatRule.options.interval
		const start = DateTime.fromJSDate(this.repeatRule.options.dtstart)
		const end = DateTime.fromJSDate(this.repeatRule.options.until!)
		const duration = this.duration.toMillis()

		return {
			start: start.toISO()!,
			end: end.toISO()!,
			frequencyType,
			frequencyAmount,
			duration,
			doRepeat: true,
		}
	}

	static createTiming(
		start: string,
		end: string,
		duration: number,
		frequencyAmount: number,
		frequencyType: FrequencyType,
		timezone?: string
	) {
		const frequency = FrequencyOptions[frequencyType]

		let startDate: DateTime
		let endDate: DateTime

		if (timezone) {
			startDate = DateTime.fromISO(start).setZone(timezone, { keepLocalTime: true }).setZone("utc")
			endDate = DateTime.fromISO(end).setZone(timezone, { keepLocalTime: true }).setZone("utc")
		} else {
			startDate = DateTime.fromISO(start).setZone("utc")
			endDate = DateTime.fromISO(end).setZone("utc")
		}

		if (startDate > endDate) throw new TimetableError("Start date must be before end date.")

		const rrule = new RRule({
			freq: frequency,
			interval: frequencyAmount,
			dtstart: datetime(
				startDate.year,
				startDate.month,
				startDate.day,
				startDate.hour,
				startDate.minute,
				startDate.second
			),
			until: datetime(endDate.year, endDate.month, endDate.day, endDate.hour, endDate.minute, endDate.second),
		})

		return {
			rrule_string: rrule.toString(),
			duration_iso: Duration.fromMillis(duration).toISO(),
		}
	}
}

export class TimetableEventSingle extends TimetableEvent {
	declare repeat: false
	declare timingData: SingleEventTiming
	declare start: DateTime
	declare end: DateTime

	importTiming(timing: SingleEventTiming) {
		this.timingData = timing
		this.start = DateTime.fromISO(timing.t_start)
		this.end = DateTime.fromISO(timing.t_end)
	}

	toJson(): ScheduledEventData_SINGLE {
		const data = super.toJson() as ScheduledEventData_SINGLE
		data.repeat = 0
		data.timing = {
			t_start: this.start.toISO()!,
			t_end: this.end.toISO()!,
		}

		return data
	}

	toFullCalendarEvent(temp?: boolean): EventInput {
		const fcEvent = super.toFullCalendarEvent(temp)
		fcEvent.start = this.start.toJSDate()
		fcEvent.end = this.end.toJSDate()
		fcEvent.extendedProps!.eventId = this.id
		return fcEvent
	}

	getTimingValues() {
		return {
			start: this.start.toISO()!,
			end: this.end.toISO()!,
			doRepeat: false,
		}
	}

	getOccurrences() {
		return [
			{
				start: this.start,
				end: this.end,
				event: this,
			},
		]
	}

	static createTiming(start: string, end: string, timezone?: string) {
		let startDate = DateTime.fromISO(start)
		let endDate = DateTime.fromISO(end)

		if (timezone) {
			startDate = startDate.setZone(timezone, { keepLocalTime: true })
			endDate = endDate.setZone(timezone, { keepLocalTime: true })
		}

		if (startDate > endDate) throw new TimetableError("Start date must be before end date.")

		return {
			t_start: startDate.toISO(),
			t_end: endDate.toISO(),
		}
	}
}

const FrequencyOptionsReverse: { [index: number]: FrequencyType } = {
	[RRule.MINUTELY]: "minutes",
	[RRule.HOURLY]: "hours",
	[RRule.DAILY]: "days",
	[RRule.WEEKLY]: "weeks",
}

const FrequencyOptions: { [key in FrequencyType]: number } = {
	minutes: RRule.MINUTELY,
	hours: RRule.HOURLY,
	days: RRule.DAILY,
	weeks: RRule.WEEKLY,
	months: RRule.MONTHLY,
}

export class TimetableError extends Error {
	constructor(msg: string) {
		super(msg)
	}
}
