<script lang="ts">
	import { ReleaseNotes } from "../../../../release-notes"
	import WhatsNewPreview from "./WhatsNewPreview.svelte"
</script>

<div id="whats-new">
	{#each Object.entries(ReleaseNotes) as [version, content]}
		{#if content.preview}
			<WhatsNewPreview imageLeft={content.preview[0]} imageRight={content.preview[1]} />
		{/if}
		<div class="flex-row">
			<h1>What's New</h1>
			<span>v{version}</span>
		</div>
		<div class="content">
			{#each Object.entries(content.notes) as [heading, infoList]}
				<h2>{heading}</h2>
				{#each infoList as info}
					<div>
						{#if typeof info === "string"}
							<span class="info">
								{info}
							</span>
						{:else}
							<span class="header">
								{Object.keys(info)[0]}
							</span>
							{Object.values(info)[0]}
						{/if}
					</div>
				{/each}
			{/each}
		</div>
	{/each}
</div>

<!-- BLUE -->
<!-- opacity="0.8" -->
<!-- stroke="#4647DE" -->
<!-- stroke-width="4" -->

<!-- ORANGE -->
<!-- opacity="0.8" -->
<!-- stroke="#FAAE1B" -->
<!-- stroke-width="4" -->

<style>
	#whats-new {
		padding: 0 4rem;
		padding-top: 0;
		margin-top: 4rem;
		margin-bottom: 2rem;
		height: 100%;
		overflow-y: scroll;
	}

	h1 {
		margin: 1rem 0 0 0;
		font-size: var(--h0);
	}

	.flex-row {
		align-items: flex-end;
	}

	.flex-row span {
		margin-left: 1rem;
		margin-bottom: 0.2rem;
		color: var(--color-main);
	}

	.content {
		padding: 0 2rem;
		margin-bottom: 8rem;
	}

	.content h2 {
		margin: 0;
		margin-top: 2rem;
		margin-bottom: 0.5rem;
		font-size: var(--h1);
		color: var(--color-main);
	}

	.content div {
		margin: 0;
		color: var(--color-text);
		margin-bottom: 0.5rem;
	}

	.content div .header,
	.content div .info {
		color: var(--color-text-light);
	}

	.content div .header::after {
		content: ":";
		color: var(--color-text-light);
	}
</style>
