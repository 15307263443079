import {
	CalibrationError,
	CalibrationFailure,
	type AdvancedCalibrationOptions,
	type Device,
	type Snapshot,
	DevOpsError,
} from "luxedo-data"
import { writable } from "svelte/store"
export namespace DeviceCalibrationManager {
	export type CalibrationStatus = {
		step: number
		progress: number
		message: string
		pncID: number
		description?: string
		snapshot?: Snapshot // only exists if calibration was successful
		errorCode?: string // only exists if an error occurred
		failInfo?: Array<string> // only exists if the calibration failed
	}

	export type ContextType = { [index: number]: CalibrationStatus }
	const store = writable<ContextType>({})

	/**
	 * Subscribe to a device's calibration status
	 * @param device The device who's calibration you want to subscribe to
	 * @param cb The callback for calibration status updates
	 * @returns an unsubscriber function
	 */
	export function subscribe(device: Device, cb: (status: CalibrationStatus) => void) {
		return store.subscribe((ctx) => {
			if (ctx[device.id]) cb(ctx[device.id])
		})
	}

	/**
	 * Updates the device's calibration status, triggering store updates
	 * @param device The device who's calibration status you are updating
	 * @param status The status of the device's calibration
	 */
	function updateDeviceCalibrationStatus(device: Device, status: Partial<CalibrationStatus>) {
		if (!device) return
		store.update((ctx) => {
			const existingStatus = ctx[device.id]

			return { ...ctx, [device.id]: { ...existingStatus, ...status } }
		})
	}

	/**
	 * Creates a progress listener for the specified device - this is to be used
	 * when calling device.calibration to more easily transfer the calibration status
	 * data into this module's store
	 * @param device The device you are creating a progress listener for
	 * @returns The progress listener
	 */
	function generateProgressListener(device: Device) {
		return (data: { progress: number; step: number; message: string; description?: string; pncID?: number }) => {
			const { progress, step, message, description, pncID } = data
			store.update((ctx) => {
				const existingData: Partial<CalibrationStatus> = ctx[device.id] ?? {}
				return {
					...ctx,
					[device.id]: {
						...existingData,
						message,
						step,
						progress,
						pncID,
						description: description ?? existingData.description,
					},
				}
			})
		}
	}

	/**
	 * Triggers a device calibration
	 * @param device The device to calibrate
	 * @param advancedOptions [OPTIONAL] Advanced calibration options - if passed, the calibration used will be the legacy version
	 */
	export async function calibrateDevice(device: Device, advancedOptions?: AdvancedCalibrationOptions) {
		updateDeviceCalibrationStatus(device, {
			message: "Initializing...",
			step: 0,
			pncID: undefined,
			description: undefined,
			errorCode: undefined,
			failInfo: undefined,
			progress: 0,
			snapshot: undefined,
		})
		try {
			const snapshot = await device.calibrate(generateProgressListener(device), advancedOptions)
			updateDeviceCalibrationStatus(device, { snapshot })
		} catch (e) {
			if (e instanceof CalibrationError) {
				updateDeviceCalibrationStatus(device, {
					errorCode: e.code,
					message: e.message,
					description: e.description,
				})
			} else if (e instanceof CalibrationFailure) {
				updateDeviceCalibrationStatus(device, {
					message: e.message,
					description: e.description,
					failInfo: e.images,
				})
			} else if (e instanceof DevOpsError) {
				updateDeviceCalibrationStatus(device, {
					errorCode: "C-600",
					message: e.message,
					description: "Once resolved, please try again...",
				})
			} else {
				updateDeviceCalibrationStatus(device, {
					errorCode: "C-500",
					message: "Calibration Error",
					description: "An unknown error occurred while calibrating. Please try again...",
				})
			}
		}
	}

	export async function resetDeviceCalibration(device: Device) {
		updateDeviceCalibrationStatus(device, {
			message: "Initializing...",
			step: 0,
			pncID: undefined,
			description: undefined,
			errorCode: undefined,
			failInfo: undefined,
			progress: 0,
			snapshot: undefined,
		})
	}
}
