import type { FullCalendarEvent, Lightshow, Scene } from "luxedo-data"
import { openOverlay } from "svelte-comps/overlay"
import ShowPreview from "./ShowOverview.svelte"
import type { EventImpl } from "@fullcalendar/core/internal"

export function openShowOverview(scene: Scene | Lightshow, event?: FullCalendarEvent | EventImpl) {
	openOverlay(ShowPreview, {
		props: { scene, event },
		classOverlay: "new-scene-overlay",
		classHeading: "no-underline no-pad",
	})
}
