<script lang="ts">
	import { DataHandlerDevice, Device, DeviceRPi, type LuxcastFWPreferences, Resolutions } from "luxedo-data"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { Toast } from "svelte-comps/toaster"
	import { ThirdPartyProjectorManager, type Resolution } from "luxedo-data"
	import { DataSaveError } from "../../../../types/ErrorVariants"
	import { LuxedoRPC } from "luxedo-rpc"
	import { ToggleSwitch } from "svelte-comps/inputs"

	export let triggerSave = saveResolution

	let activeResolution
	let hasInternalProjector
	let newOrientation: boolean

	let device: Device
	SelectedDeviceStore.subscribe((dev) => {
		device = dev
		if (dev && dev.hasConnectedProjector) {
			hasInternalProjector = true
			activeResolution = ThirdPartyProjectorManager.resolutionManager.getByResolution(dev.resX, dev.resY)
			triggerSave = saveResolution
		} else {
			hasInternalProjector = false
			activeResolution = undefined
			triggerSave = undefined
		}
	})

	function verifyRemove() {
		openConfirmOverlay({
			prompt: ["Are you sure you want to remove this device from your account?", "This cannot be undone."],
			buttons: {
				confirm: {
					text: "Yes",
					onClick: removeDevice,
				},
			},
		})
	}

	async function removeDevice() {
		try {
			await DataHandlerDevice.deleteEntry(device)
			const devices = DataHandlerDevice.getMany()
			if (devices.length) SelectedDeviceStore.set(devices[0])
			else SelectedDeviceStore.set(undefined)
			Toast.success("Device successfully removed from your account.")
		} catch (e) {
			console.error("Error removing device from user account: ", e)
			Toast.error("An error occurred while trying to remove this device from your account. Please try again...")
		}
	}

	function updateResolution(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		activeResolution = e.currentTarget.value
	}

	async function setRecommendedExposure(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		console.warn(device._rawData)
		await LuxedoRPC.api.plato.plato_call("set_camera_exposure", [e.currentTarget.value], device.id!)
	}

	function isLuxcast(dev: Device): dev is DeviceRPi {
		return dev && dev.hasConnectedProjector
	}

	async function setProjectorManufacturer(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		if (!device.isOnline) {
			Toast.error("Device is offline")
			return
		}

		const projectorManuf = e.currentTarget.value

		if (!(projectorManuf == "OPTOMA" || projectorManuf == "EPSON")) return
		await LuxedoRPC.api.plato.plato_call("config_update", [{ projector_manufacturer: projectorManuf }], device.id!)
	}

	async function saveResolution() {
		try {
			let hasUpdated
			const newRes = ThirdPartyProjectorManager.resolutionManager.resolutions[activeResolution]

			if (newRes && (newRes.width !== device.resX || newRes.height !== device.resY)) {
				await changeResolution(newRes)
				hasUpdated = true
			}

			if ("orientation" in device && newOrientation !== device.orientation) {
				await LuxedoRPC.api.deviceControl.device_set_camera_flipped(device.id, newOrientation ? 1 : 0)
				hasUpdated = true
			}

			if (hasUpdated) {
				await DataHandlerDevice.pull([device.id])
				Toast.success("Device updated successfully!")
			} else Toast.text("No changes detected.")
		} catch (e) {
			console.error("[ERROR] ", e)
			if (e instanceof DataSaveError) Toast.error(e.message)
			else throw e
		}
	}

	async function changeResolution(newResolution: Resolution): Promise<boolean> {
		if (!device.isReady) throw new DataSaveError("Device is offline or busy - please wait and try again")
		if (!(device instanceof DeviceRPi) || !device.hasConnectedProjector)
			throw new DataSaveError("Cannot change resolution of this device.")
		if (device.isResolutionChanging) throw new DataSaveError("Cannot change resolution while awaiting response.")

		if (device.resX === newResolution.width && device.resY === newResolution.height) return true

		device.isResolutionChanging = true

		try {
			device.resX = newResolution.width
			device.resY = newResolution.height

			await LuxedoRPC.api.plato.plato_call(
				"display_set_resolution",
				[newResolution.width, newResolution.height, 60, "_all_other_projectors"],
				device.id!
			)

			device.isResolutionChanging = false
			return true
		} catch (e) {
			device.isResolutionChanging = false
			throw e
		}
	}

	function onInvertCamera(newVal: boolean) {
		if (!("orientation" in device)) return
		newOrientation = newVal
	}
</script>

<div class="advanced-options">
	{#if hasInternalProjector && isLuxcast(device)}
		<!-- <div> -->
		<div class="select-container">
			<label id="projector-resolution-label" for="resolution-input">Projector Resolution: </label>
			<select id="resolution-input" on:change={updateResolution} value={activeResolution}>
				{#each Object.entries(ThirdPartyProjectorManager.getSupportedResolutions()) as [name, res], i}
					<option value={name}>{res.width} x {res.height}</option>
				{/each}
			</select>
		</div>

		<div class="select-container">
			<label id="proj-manufacturer-label" for="proj-manufacturer">Projector Manufacturer: </label>
			<select
				id="proj-manufacturer"
				on:change={setProjectorManufacturer}
				value={device._rawData.preferences.projector_manufacturer?.toString() ?? ""}
			>
				<option value=""></option>
				<option value="OPTOMA">Optoma</option>
				<option value="EPSON">Epson</option>
			</select>
		</div>

		<!-- <span class="resolution-warning"> -->
		<!-- Most  -->
		<div class="select-container">
			<label id="custom-exposure-label" for="camera-exposure">Camera Exposure: </label>
			<select
				id="camera-exposure"
				on:change={setRecommendedExposure}
				value={device._rawData.recommended_exposure ? device._rawData.recommended_exposure.toString() : "-1"}
			>
				<option value="-1">Automatic</option>
				<option value="32">Very Low</option>
				<option value="64">Low</option>
				<option value="128">Mid-Low</option>
				<option value="256">Standard</option>
				<option value="512">Mid-High</option>
				<option value="1024">High</option>
				<option value="2048">Very High</option>
			</select>
		</div>
		<!-- </span> -->
		<!-- </div> -->
	{:else}
		<div />
	{/if}

	{#if "orientation" in device}
		<div class="flex-row invert-camera-container">
			<div class="switch-container">
				<span class="label">Invert Camera: </span>
				<ToggleSwitch
					isActive={!!device.orientation}
					onUpdate={onInvertCamera}
					info={"If your calibration images appear upside down, toggle this to flip the camera orientation."}
				/>
			</div>
		</div>
	{/if}
	<div class="button-container">
		<button
			id="remove-device-button"
			class="outline-button"
			title="Remove Device from Account"
			on:click={verifyRemove}
		>
			Remove from Account
		</button>
	</div>
</div>

<style>
	.advanced-options {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	#projector-resolution-label {
		display: flex;
		flex-direction: row;
	}

	.switch-container {
		width: 12.5rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.button-container {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 1rem;
	}

	.flex-row {
		align-items: center;
	}

	.invert-camera-container :global(.info-dialogue-button) {
		margin-left: 0;
		margin-bottom: 0.5rem;
	}

	.label,
	label {
		color: var(--color-text);
		white-space: nowrap;
		width: fit-content;
	}

	select {
		color: var(--color-text-light);
		width: 100%;
		margin-left: 1rem;
		transition: background-color 250ms;
	}

	select:focus-visible,
	select:hover {
		background-color: var(--color-main-transparent);
	}

	#remove-device-button {
		border-color: var(--color-error);
	}

	#remove-device-button:hover,
	#remove-device-button:focus-visible {
		background-color: var(--color-error);
	}
</style>
