import { fabric } from "fabric"

import { EditorClass } from "../.."
import { Path, PathSubscriber } from "../../asset"
import { EditPoints, POINT_EDIT_DEFAULT, PointEditOptions, TransformMode } from "."
import { IEvent } from "fabric/fabric-impl"

export interface PathEditOptions extends PointEditOptions {
	/** The path to edit */
	target: Path

	/** Optional - point to the subscriber that initiated this edit. This is just used for bookkeeping. */
	caller?: PathSubscriber
}

export interface EditPath extends EditPoints, PathEditOptions {
	/** Add the asset to the editor per the options */
	complete(): void
}

export class EditPath extends EditPoints {
	cancellable: boolean = true
	protected cancelOnSelection: boolean = true
	protected unlockSelection: boolean = false

	declare target: Path

	constructor(editor: EditorClass, options: PathEditOptions) {
		super(editor, {
			...PATH_EDIT_DEFAULTS,
			...options,
		})

		this.selection = options.target
		this.caller = options.caller
		this.setStyle(options.editStyle)
	}

	get modeTitle() {
		return "Editing Path"
	}

	//#region    ===========================		   	   Implementation		 		==============================

	/**
	 * Update selection if applicable - replacement for _onSelect given odd behavior when selection can be changed.
	 */
	protected _onClick = (e: IEvent<MouseEvent>) => {
		if (e.target && e.target !== this.target) {
			this.editor.selection.unlock()
			this.editor.selection.set(e.target)
			this.editor.mode.set(TransformMode, {
				functionClearMode: this.clearEditorMode,
			})
		}
	}

	get points(): fabric.Point[] {
		return this.target.points
	}

	protected movePoint(index: number, newPosition: fabric.Point): void {
		this.target.editPoint(index, newPosition)
	}

	protected onActivate(options: PathEditOptions): void {
		if (!this.target.registered) this.editor.canvas.addTemporaryObject(this.target)
		this.setSelection(this.target)
		super.onActivate(options)

		this.targetEvents["mouse:up"] = this._onClick
	}

	protected onDeactivate(): void {
		super.onDeactivate()

		if (!this.target.registered) this.editor.canvas.clearTemporaryObjects()

		setTimeout(() => {
			// this is dumb, but works
			if (this.editor.selection.get()[0] instanceof Path) this.editor.selection.clear()
		})

		this.editor.canvas.requestRenderAll()
	}

	protected onComplete(...args: any): void {
		this.save()
	}

	insertPoint(point: fabric.Point, index: number, absolutePoint?: fabric.Point) {
		this.absolutePoints.splice(index, 0, absolutePoint ?? point.clone())
		this.target.insert(point, index)
		this.updatePoints()
		this.initControls()
	}

	deletePoint(index: number) {
		const oldPt = this.points[index]
		super.deletePoint(index)
		this.target.notifySubscribers("point:delete", index, oldPt)
	}
}

const PATH_EDIT_DEFAULTS: Partial<PathEditOptions> = {
	allowAddingPoints: true,
	allowDeletingPoints: true,
	minPoints: 2,
}
