<script lang="ts">
	import DirectUploadForm from "./DirectUploadForm.svelte"

	export let triggerUpload: () => void
	export let postSubmit: () => void

	export let sceneName: string
	export let projectFolder: number
	export let selectedSnapshotId: number
	export let selectedDeviceId: number
	export let newSceneId: number

	let uploadState: "upload" | "process" | "error"
</script>

<div class="flex-column">
	{#if uploadState === "process"}
		<h3>Processing Video...</h3>
	{:else if uploadState === "error"}
		<h3>Error Uploading Project</h3>
	{:else}
		<h3>Upload your Project</h3>
	{/if}
	<DirectUploadForm
		{sceneName}
		{projectFolder}
		{selectedSnapshotId}
		{selectedDeviceId}
		bind:currentState={uploadState}
		bind:triggerUpload
		bind:newSceneId
		onComplete={postSubmit}
	/>
</div>

<style>
	.flex-column h3 {
		margin-bottom: 1rem;
	}
</style>
