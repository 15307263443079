import { LuxedoRPC } from "luxedo-rpc"
import { DataHandler } from "data-handler"
import { Design, type DesignRawData } from "../entries/Design"
import type { BundleOf, EntryID, IDMap, RawDataOf } from "data-handler/src/types"

class DHDesign extends DataHandler<Design> {
	EntryClass = Design

	protected async fetch(specificInstances?: EntryID[]): Promise<BundleOf<Design>> {
		// const data = await LuxedoRPC.api.bundlers.proj_cake_bundle_down(specificInstances ?? [])
		const data = await LuxedoRPC.api.bundlers.project_bundle_down(specificInstances ?? [])
		return data
	}

	protected async pushData(entryData: { [id: number]: Partial<DesignRawData> }): Promise<void> {
		const pushableData = entryData as IDMap<DesignRawData>
		return await LuxedoRPC.api.bundlers.project_bundle_up(pushableData)
	}

	async requestCreateEntry(entryData: Partial<RawDataOf<Design>>): Promise<EntryID> {
		const { name, shape, width, length, parent_id, target_device_id } = entryData

		// if (!name || !shape) throw new Error(`Name or Shape not set`)

		console.error(
			`ALL NEW DESIGNS ARE BEING SET UNDER target_device with ID 1433 (check server code to fix this)`
		)

		// const { new_file_id } = await LuxedoRPC.api.cake.cake_design_create(name, parent_id!, target_device_id!, {
		// 	shape,
		// 	diameter: width,
		// 	width,
		// 	length,
		// })

		if (!name) throw new Error(`Name not set`)

		const { new_file_id } = await LuxedoRPC.api.scene.project_create(
			name,
			parent_id!,
			{ width: 1024, height: 768 },
			0,
			target_device_id!,
			{},
			0
		)

		return new_file_id
	}

	async requestDeleteEntry(entry: Design): Promise<void> {
		return await LuxedoRPC.api.scene.project_delete(entry.id!)
	}
}

export const DataHandlerDesign = new DHDesign()
