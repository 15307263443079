import {
	DataHandlerFolder,
	type Lightshow,
	type Media,
	type Scene,
	Folder,
	DataHandlerMedia,
	DataHandlerTag,
	DataHandlerScene,
	DataHandlerLightshow,
	DataHandlerDevice,
	type FullCalendarEvent,
} from "luxedo-data"
import type { ContextMenuOptions } from "svelte-comps/context-menu"
import { asyncConfirmOverlay, openConfirmOverlay } from "svelte-comps/overlay"
import { Toast } from "svelte-comps/toaster"
import { LuxedoRPC } from "luxedo-rpc"
import { ShowLibraryController } from "../../comps/routes/show-library/ShowLibraryController"
import { FileSystemMedia } from "../file-system/FileSystemMedia"
import { ScheduleController } from "../../comps/routes/schedule/ScheduleController"
import { openMediaReUploadOverlay } from "../../comps/reusable/overlays/media-upload"
import { openShowOverview } from "../../comps/reusable/overlays"
import { openMediaToolOverlay } from "svelte-comps/luxedo"

export namespace CTXOptions {
	function triggerRename(file: Scene | Media | Folder | Lightshow, elemId?: string) {
		const elementId = elemId ? elemId : `tile-label-${file.constructor.name}-${file.id}`
		const labelElem = document.getElementById(elementId)
		labelElem.contentEditable = "true"
		setTimeout(() => {
			const range = document.createRange()
			range.selectNodeContents(labelElem)
			const selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
		})
	}

	export namespace Media {
		async function verifyDelete(warning: string): Promise<void> {
			return new Promise((res, rej) => {
				openConfirmOverlay({
					prompt: warning,
					buttons: {
						confirm: {
							text: "Yes",
							onClick: res,
						},
						deny: {
							text: "Cancel",
							onClick: rej,
						},
					},
				})
			})
		}

		async function deletePermenantly(media: Media) {
			try {
				await verifyDelete(
					"This media file will be permanently removed from your library and will be deleted from any scenes. Would you like to continue?"
				)
			} catch (e) {
				return
			}

			try {
				await DataHandlerMedia.deleteEntry(media)
				Toast.success("Media deleted.")
			} catch (e) {
				console.error("Error deleting media", e)
				Toast.error("There was an error deleting this media, please refresh and try again.")
			}
		}

		async function deleteSource(media: Media) {
			try {
				await verifyDelete(
					"This media's source will be deleted, saving you storage space, but a placeholder will be left in your media library and scenes so that you can reupload the source at a later date or as needed. Would you like to continue?"
				)
			} catch (e) {
				return
			}

			try {
				await DataHandlerMedia.deleteSource(media)
				Toast.success("Source successfully deleted.")
			} catch (e) {
				console.error("Error deleting media", e)
				Toast.error("There was an error deleting this media source, please refresh and try again.")
			}
		}

		const generateMoveToOptions = (media: Media) => {
			const submenuOpts = []
			const parent = DataHandlerFolder.get(media.parent_id)
			const parentRoot = DataHandlerFolder.get(parent.parent_id)
			if (parentRoot) {
				submenuOpts.push({
					title: parentRoot.name === "froot_media" ? "My Media" : parentRoot.name,
					// @ts-ignore
					onClick: async () => await FileSystemMedia.move(media, parentRoot),
				})
			}
			const availFolders = DataHandlerFolder.getByFolder(media.parent_id).map((folder) => {
				return {
					title: folder.name,
					// @ts-ignore
					onClick: async () => await FileSystemMedia.move(media, folder),
				}
			})
			return submenuOpts.concat(availFolders)
		}

		function removeBg(media: Media) {
			openMediaToolOverlay("bg-remove", media)
		}

		function drawOn(media: Media) {
			openMediaToolOverlay("draw", media)
		}

		function trim(media: Media) {
			openMediaToolOverlay("trim", media)
		}

		function getEditOptions(media: Media) {
			const opts: ContextMenuOptions = [
				{
					title: "Remove Background",
					onClick: () => removeBg(media),
				},
			]
			switch (media.fileType) {
				case "image":
					// opts.push({
					// 	title: "Draw on Image",
					// 	onClick: drawOn,
					// })
					break
				case "video":
					opts.push({
						title: "Trim Start/End",
						onClick: () => trim(media),
					})
			}
			return opts
		}

		export function createOptions(media: Media) {
			let opts: ContextMenuOptions = []

			if (!media.is_src_deleted) {
				if (media.fileType !== "audio") {
					opts.push({
						title: "Edit",
						hasSubmenu: true,
						submenu: getEditOptions(media),
					})
				}
			}

			if (media.is_src_deleted) {
				opts.push({
					title: "Reupload Source",
					onClick: () => openMediaReUploadOverlay(media),
				})
			}

			opts.push({
				title: "Rename",
				onClick: () => triggerRename(media),
			})
			opts.push({
				title: "Move to",
				hasSubmenu: true,
				submenu: generateMoveToOptions(media),
			})

			if (!media.is_src_deleted) {
				opts.push({
					title: "Delete",
					hasSubmenu: true,
					submenu: [
						{ title: "Delete Source", onClick: () => deleteSource(media) },
						{ title: "Delete Permanently", onClick: () => deletePermenantly(media) },
					],
				})
			} else {
				opts.push({
					title: "Delete Permenantly",
					onClick: () => deletePermenantly(media),
				})
			}

			return opts
		}
	}

	export namespace Scene {
		export function createOptions(
			design: Scene,
			options: {
				lightshowIndex?: number
				fromOverlay?: boolean
			}
		) {
			const opts: ContextMenuOptions = []
			const tags = DataHandlerTag.getMany()
			const { lightshowIndex, fromOverlay } = options

			if (!design.isDirectUpload)
				opts.push({
					title: "Edit",
					onClick: () => {
						design.openInEditor()
					},
				})

			opts.push({
				title: "Schedule",
				onClick: () => ScheduleController.EventEditor.editEvent(undefined, { show: design }),
			})

			if (lightshowIndex === undefined) {
				opts.push({
					title: "Rename",
					onClick: () => triggerRename(design, fromOverlay ? "show-preview-title" : undefined),
				})
			}

			opts.push({
				title: "Add Tag",
				hasSubmenu: true,
				submenu: tags
					.map((tag) => {
						if (!design.tags.includes(tag.id))
							return {
								title: tag.title,
								style: `background-color: ${tag.color}44;
													height: fit-content;
													overflow: hidden;
													text-overflow: ellipsis;
													text-wrap: nowrap;
													padding: 0.25em 0.5em;
													border-radius: 100px;
													margin: 0.25rem;
													margin-left: 0;
													color: var(--color-text-light);
													user-select: none;
													transition: background-color 250ms;
													cursor: pointer;
													border-bottom: none;
													`,
								onClick: () => design.toggleTag(tag),
							}
						else return null
					})
					.filter((value) => value),
			})

			if (design.tags.length) {
				opts.push({
					title: "Remove Tag",
					hasSubmenu: true,
					submenu: tags
						.map((tag) => {
							if (design.tags.includes(tag.id))
								return {
									title: tag.title,
									style: `background-color: ${tag.color}44;
													height: fit-content;
													overflow: hidden;
													text-overflow: ellipsis;
													text-wrap: nowrap;
													padding: 0.25em 0.5em;
													border-radius: 100px;
													margin: 0.25rem;
													margin-left: 0;
													color: var(--color-text-light);
													user-select: none;
													transition: background-color 250ms;
													cursor: pointer;
													border-bottom: none;
													`,
									onClick: () => design.toggleTag(tag),
								}
							else return null
						})
						.filter((value) => value),
				})
			}

			if (!fromOverlay)
				opts.push({
					title: "View Details",
					onClick: () => {
						openShowOverview(design)
					},
				})

			if (!design.isDirectUpload)
				opts.push({
					title: "Duplicate",
					onClick: async () => {
						Toast.text(`Duplicating ${design.name}...`)
						try {
							const response = await LuxedoRPC.api.scene.scene_clone(
								design.id,
								`Copy of ${design.name}`
							)
							const cloned_id = response.new_file_id
							await DataHandlerScene.pull([cloned_id])
							Toast.success("Scene successfully duplicated.")
						} catch (e) {
							console.error(`Error duplicating scene: ${e}`)
							Toast.error("An error occurred.")
						}
					},
				})

			opts.push({
				title: "Delete",
				onClick: async () => {
					try {
						await asyncConfirmOverlay({
							title: `Deleting Scene`,
							prompt: `Are you sure you want to delete "${design.name}"? This action cannot be undone.`,
							confirmText: `Delete Forever`,
							denyText: `Nevermind`,
						})
						Toast.text("Deleting...")
					} catch (e) {
						return
					}

					try {
						await DataHandlerScene.deleteEntry(design)
						Toast.success("Scene successfully deleted.")
					} catch (e) {
						console.error("Error deleting scene.", e)
						Toast.error("Unable to delete scene. Please refresh and try again.")
					}
				},
			})
			return opts
		}
	}

	export namespace Lightshow {
		export function createOptions(lightshow: Lightshow, options: { fromOverlay?: boolean }) {
			const opts = [
				{
					title: "Edit",
					onClick: () => ShowLibraryController.LightshowEditor.editLightshow(lightshow),
				},
				{
					title: "Schedule",
					onClick: () => {
						ScheduleController.EventEditor.editEvent(undefined, { show: lightshow })
					},
				},
				{
					title: "Rename",
					onClick: () =>
						triggerRename(lightshow, options.fromOverlay ? "show-preview-title" : undefined),
				},
			]

			if (!options.fromOverlay)
				opts.push({
					title: "View Details",
					onClick: () => {
						openShowOverview(lightshow)
					},
				})

			opts.push({
				title: "Delete",
				onClick: async () => {
					try {
						await asyncConfirmOverlay({
							title: `Deleting Lightshow`,
							prompt: `Are you sure you want to delete "${lightshow.name}"? This action cannot be undone.`,
							confirmText: `Delete Forever`,
							denyText: `Nevermind`,
						})
						Toast.text("Deleting...")
					} catch (e) {
						return
					}

					try {
						await DataHandlerLightshow.deleteEntry(lightshow)
						Toast.success("Lightshow successfully deleted.")
					} catch (e) {
						console.error("Error deleting lightshow", e)
						Toast.error("Unable to delete lightshow, please refresh and try again.")
					}
				},
			})

			return opts
		}
	}

	export namespace Event {
		function confirmDelete(): Promise<void> {
			return new Promise((res, rej) => {
				openConfirmOverlay({
					prompt: ["Are you sure you want to delete this event?", "This cannot be undone."],
					buttons: {
						confirm: {
							text: "Yes",
							onClick: res,
						},
						deny: {
							text: "Cancel",
							onClick: rej,
						},
					},
				})
			})
		}

		export function createOptions(fcEvent: FullCalendarEvent) {
			const device = DataHandlerDevice.get(fcEvent.extendedProps.deviceId)
			const event = device.timetableManager.getEvent(fcEvent.extendedProps.eventId)

			if (!event) return

			const show = event.show
			let opts: ContextMenuOptions = []
			opts.push({
				title: "Edit Event",
				onClick: () => ScheduleController.EventEditor.editEvent(fcEvent),
			})
			opts.push({
				title: "View Details",
				onClick: () => openShowOverview(show, fcEvent),
			})
			opts.push({
				title: "Delete Event",
				onClick: async () => {
					try {
						await confirmDelete()
					} catch {
						return
					}
					try {
						await device.timetableManager.deleteEvent(event)
						Toast.success("Event deleted")
						ScheduleController.Calendar.refreshEvents()
					} catch (e) {
						console.error("Unable to delete event", { error: e, event, fullCalendarEvent: fcEvent })
						Toast.error("Unable to delete event, please try again...")
					}
				},
			})
			return opts
		}
	}
}
