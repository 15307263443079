import { Device } from "./Device"
import { FirmwareProductByID, StatusColors } from "./consts"
import { Version } from "../../modules/Version"
import type { FirmwarePreferences, BaseEidosType, BaseDeviceRawData, DeviceStatusAppearance } from "."
import { LuxedoRPC } from "luxedo-rpc"
import { ThirdPartyProjectorManager } from "../../data/SupportedProjectors"
import { DataHandlerDevice } from "../../datahandlers/DataHandlerDevice"

export type StatusRPiDevice = "OFF" | "NEW" | "UPDATING" | "IDLE" | "PROJECT" | "PROJ_TT" | "PNC" | "LOST" | "DORMANT"

export interface LuxcastFWPreferences extends FirmwarePreferences {
	power_control_type?: "IR" | "RS232"
	show_wifi_info?: boolean
	audio_device?: "HDMI" | "HEADPHONES"
	projector_manufacturer?: "OPTOMA" | "EPSON"
}

export interface EidosFirmware extends BaseEidosType {
	bluetooth?: {
		paired_entries: Array<number>
		scanned_entries: Array<number>
	}
	cache?: {
		cached_project_ids: Array<number>
		free_space_bytes: number
	}
	config?: {
		fw_config: LuxcastFWPreferences
	}
	display_config?: Array<number>
	display_mode?: string
	message_queue?: Array<string>
	pnc?: {
		capture_id: number | "auto_cal"
		imgs_taken: number
		img_total: number
	}
	proj_id?: number
	proj_play_starttime?: number
	status: StatusRPiDevice
}

export interface DeviceRPiRawData extends BaseDeviceRawData {
	status: StatusRPiDevice
	type_id: "dev_luxedo" | "dev_luxcast"
	product_id: number
	proj_w?: number
	proj_h?: number
	version_crnt: string
	version_tgt?: string
	version_avail: string
	password?: string
	third_party_proj?: string
	eidos: EidosFirmware
	preferences: LuxcastFWPreferences
}

export interface DeviceRPi extends Device<DeviceRPiRawData> {
	_eidos: EidosFirmware
	_status: StatusRPiDevice
	typeId: "dev_luxedo" | "dev_luxcast"
	isResolutionChanging?: boolean
	firmwareVersion: string
	thirdPartyProjector?: string
	orientation?: boolean
	password?: string
}

export class DeviceRPi extends Device<DeviceRPiRawData> {
	declare orientation?: boolean

	declare availableUpdate?: string
	protected versionTarget?: string
	protected lastStatusWasOnline: boolean

	constructor(data: DeviceRPiRawData) {
		super(data)
		this._rawData = data
	}

	protected importResolution(data: DeviceRPiRawData) {
		if (data.type_id === "dev_luxedo") {
			this.resX = FirmwareProductByID[data.product_id].proj_res[0]!
			this.resY = FirmwareProductByID[data.product_id].proj_res[1]!
		} else {
			this.resX = data.proj_w!
			this.resY = data.proj_h!
		}
	}

	protected importData(data: DeviceRPiRawData): void {
		super.importData(data)

		this.password = data.password
		this.orientation = !!parseInt(data.orientation ?? "0")
		this.firmwareVersion = data.version_crnt
		this.thirdPartyProjector = data.third_party_proj ?? "_all_other_projectors"
		this.availableUpdate = data.version_avail
	}

	protected exportData(): Partial<DeviceRPiRawData> {
		return {
			name: this.name,
			ui_color: this._color,
			proj_h: this.resY,
			proj_w: this.resX,
		}
	}

	onEidosUpdate(eidos: EidosFirmware): void
	onEidosUpdate(eidos: any): void
	onEidosUpdate(eidos: unknown): void {
		super.onEidosUpdate(eidos)

		console.warn({
			this: this,
			updateAvail: this.isUpdateAvailable(),
			firmware: this.firmwareVersion,
			avail: this.availableUpdate,
			eidos,
		})

		if (this.isUpdating && !this.isOnline && this.lastStatusWasOnline) {
			this.lastStatusWasOnline = false
		} else if (this.isUpdating && this.isOnline && !this.lastStatusWasOnline) {
			if (!this.isUpdateAvailable()) {
				this.isUpdating = false
				DataHandlerDevice.pull([this.id!])
			} else DataHandlerDevice.pull([this.id!])
		}
	}

	protected statusAppearanceMap: Record<StatusRPiDevice, DeviceStatusAppearance> = {
		OFF: { text: "Offline", color: StatusColors.off },
		LOST: { text: "Offline", color: StatusColors.off },
		NEW: { text: "Offline", color: StatusColors.off },
		UPDATING: { text: "Updating...", color: StatusColors.updating },
		IDLE: { text: "Idle", color: StatusColors.idle },
		PROJECT: { text: "Playing", color: StatusColors.playing },
		PROJ_TT: { text: "Playing", color: StatusColors.playing },
		PNC: { text: "Capturing...", color: StatusColors.projectAndCapture },
		DORMANT: { text: "Searching...", color: StatusColors.initializing },
	}

	// #region ================= Getters =================

	get isOnline() {
		const status = this._status
		return (
			status == "IDLE" || status == "PROJ_TT" || status == "PROJECT" || status == "PNC"
			// || status == "DORMANT"
		)
	}

	get isReady() {
		return (
			this._status == "IDLE" || this._status == "PROJ_TT" || this._status == "PROJECT"
			// || this._status == "DORMANT"
		)
	}

	public get hasConnectedProjector() {
		return this instanceof DeviceRPi && this.typeId === "dev_luxcast"
	}

	get lumenCount() {
		if (!this.thirdPartyProjector || !ThirdPartyProjectorManager.projectors[this.thirdPartyProjector]) return null
		return ThirdPartyProjectorManager.projectors[this.thirdPartyProjector].lumens ?? 3500
	}

	get status() {
		return this.getStatus().text
	}

	get statusColor() {
		return this.getStatus().color
	}

	// #endregion ============== Getters =================

	isUpdateAvailable(): boolean {
		try {
			return Version.compare_strings(this.firmwareVersion, this.availableUpdate) < 0
		} catch (e) {
			console.warn("Error comparing device version to available versions.")
			return false
		}
	}

	async update(): Promise<void> {
		this.isUpdating = true
		this.lastStatusWasOnline = true
		await LuxedoRPC.api.deviceControl.device_update(this.id!)
	}
}
