<script lang="ts">
	import {
		DataHandlerLightshow,
		DataHandlerScene,
		Lightshow,
		type Device,
		type Scene,
	} from "luxedo-data"
	import { TextRowSelector } from "svelte-comps/inputs"
	import { ScheduleController } from "../ScheduleController"
	import ScheduleInputsShowTile from "./ScheduleInputs_ShowTile.svelte"
	import { GridIcon } from "svelte-comps/icons"
	import { ShowLibraryController } from "../../show-library/ShowLibraryController"
	import { onDestroy } from "svelte"
	import { openNewSceneOverlay } from "../../../reusable/overlays"

	export let isValid: boolean = false
	let view: "Scenes" | "Lightshows" = "Scenes"

	let shows: Array<Lightshow | Scene> = []
	let device: Device

	let tileWidth: number
	let showSelectorWidth: number

	const unsubscribe = ScheduleController.EventEditor.subscribe((ctx) => {
		device = ctx?.selectedDevice
		isValid = !!ctx?.selectedShow

		refreshShows()
	})

	function refreshShows() {
		if (!device) return

		const dataHandler = view === "Scenes" ? DataHandlerScene : DataHandlerLightshow
		shows = dataHandler.getByDevice(device).filter((show) => !!show.render_ver)
	}

	function calculateTileWidth(width: number) {
		const TILE_WIDTH_PREFFERED = 96
		const TILE_MARGIN = 16

		const tileAmountPerRow = Math.floor(width / (TILE_WIDTH_PREFFERED + TILE_MARGIN))
		const newTileWidth = Math.floor(width / tileAmountPerRow) - TILE_MARGIN

		tileWidth = newTileWidth
	}

	function createNewShow() {
		if (view === "Lightshows") ShowLibraryController.LightshowEditor.createLightshow({ device })
		else openNewSceneOverlay({ device })
	}

	onDestroy(unsubscribe)

	$: view && refreshShows()
	$: calculateTileWidth(showSelectorWidth)
</script>

<div class="show-selector" style="--tile-width: {tileWidth}px;">
	<TextRowSelector
		bind:selectedOption={view}
		fontSize="var(--h1)"
		options={["Scenes", "Lightshows"]}
	/>
	<div class="show-container" bind:clientWidth={showSelectorWidth}>
		{#if shows.length}
			{#each shows as show (show.id)}
				<ScheduleInputsShowTile {show} />
			{/each}
		{:else}
			<div class="empty-indicator">
				<GridIcon />
				<p>
					There are no rendered {view.toLowerCase()} associated with this device, be sure to create {view ===
					"Scenes"
						? "and render"
						: "one"} before scheduling.
				</p>
				<button class="outline-button alt create-new-button" on:click={createNewShow}
					>Create New {view.slice(0, view.length - 1)}</button
				>
			</div>
		{/if}
	</div>
</div>

<style>
	.show-selector {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		padding: 0.5rem 0.5rem 0.5rem 1rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
	}

	.empty-indicator {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.empty-indicator p {
		margin: 0 2rem;
		color: var(--color-text);
		text-align: center;
	}

	.create-new-button {
		margin-top: 1rem;
	}

	.empty-indicator :global(svg) {
		width: 25%;
		height: auto;
	}

	.empty-indicator :global(.svg-fill) {
		fill: var(--color-text);
	}

	.show-selector :global(.text-row-selector) {
		margin-left: 0.5rem;
	}

	.show-selector ::-webkit-scrollbar-thumb {
		background-color: var(--color-bg);
	}

	.show-container {
		overflow-y: auto;
		display: flex;
		height: 100%;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 0.5rem;
	}

	.show-container :global(.tile-container) {
		margin: 0.5rem;
	}
</style>
