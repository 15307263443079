<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerSnapshot,
		DeviceGroup,
		type Device,
		type Snapshot,
	} from "luxedo-data"
	import SnapshotTile from "../../file-system/tiles/SnapshotTile.svelte"
	import { CalibrationOverlayController } from "../calibration/CalibrationOverlayController"
	import DownloadIcon from "../../icons/DownloadIcon.svelte"

	export let selectedDeviceId: number = undefined
	export let selectedSnapshotId: number
	export let isDirectUpload: boolean = false
	export let onSnapshotClick: (snapshotId: number) => void = undefined

	let device: Device
	let snapshots: Array<Snapshot> = []

	let isProjectorGroup = false
	let groupSnapshot: Snapshot

	function onSelectSnapshot(snapshot: Snapshot) {
		if (onSnapshotClick) onSnapshotClick(snapshot.id)
		selectedSnapshotId = snapshot.id
	}

	async function updateSnapshots() {
		device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			selectedSnapshotId = undefined
			groupSnapshot = await device.getSnapshot()
			return (isProjectorGroup = true)
		}

		isProjectorGroup = false
		snapshots = DataHandlerSnapshot.filterByDevice(selectedDeviceId)
		if (device.defaultSnap) selectedSnapshotId = device.defaultSnap
	}

	async function initCalibration() {
		await CalibrationOverlayController.open(device)
	}

	$: selectedDeviceId && updateSnapshots()
</script>

<div class="flex-column">
	<h3>
		{#if isDirectUpload}
			{#if isProjectorGroup}
				<div class="flex-row">
					Download snapshot
					<button class="small icon" title="Download snapshot"><DownloadIcon /></button>
				</div>
			{:else}
				Select and download snapshot
			{/if}
		{:else if isProjectorGroup}
			Verify snapshot
		{:else}
			Select Snapshot
		{/if}
	</h3>
	<div class="snapshot-selector">
		{#if isProjectorGroup}
			<img
				id="projector-group-snap"
				src={groupSnapshot.src}
				alt=""
				on:click={groupSnapshot.triggerDownload}
			/>
		{:else if !snapshots.length}
			<span id="projector-group-no-snap">
				There are no snapshots associated with this device.
				<br />
				<button class="small outline-button alt" on:click={() => initCalibration()}
					>Calibrate Now</button
				>
				to create your first snapshot.
				<br />
				<br />
				Snapshots are mapped images which show the perspective of the projector. Without one, you will
				be unable to see your mapped projection space and will be creating a show on a blank canvas.
			</span>
		{:else}
			{#each snapshots as snapshot}
				<SnapshotTile
					{snapshot}
					isSelected={selectedSnapshotId === snapshot.id}
					onClick={() => onSelectSnapshot(snapshot)}
				/>
			{/each}
		{/if}
	</div>
</div>

<style>
	.snapshot-selector {
		width: 100%;
		padding: 2rem 2rem 1rem 2rem;
		flex-grow: 1;
		background-color: var(--color-bg-dark-transparent);
		margin: 1rem 0;
		border-radius: var(--br);
		overflow-x: scroll;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;

		overflow: hidden;
	}

	.flex-column {
		flex-grow: 1;
		overflow: hidden;
	}

	h3 .flex-row {
		gap: 1rem;
		justify-content: space-between;
	}

	#projector-group-no-snap {
		color: var(--color-text);
	}

	.snapshot-selector:has(#projector-group-snap) {
		justify-content: center;
		align-items: center;
		padding: 1rem;
	}

	#projector-group-snap {
		height: 100%;
		width: auto;
		cursor: pointer;
	}

	.flex-row button.icon :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	.flex-row button.icon:hover :global(.svg-fill),
	.flex-row button.icon:focus-visible :global(.svg-fill) {
		fill: var(--color-main);
	}
</style>
