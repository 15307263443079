import { fabric } from "fabric"
import { EditorClass, getEditor } from "../../.."
import { AssetOptions, CanvasAsset } from "../.."
import { deepCopyPoints, fixPointSet } from "../../../fabric-plugins"
import { Serializable, convertInstanceToObject, registerSerializableConstructor } from "../../../modules/serialize"

export type PathMessageType =
	| "before:newsubscriber"
	| "subscribed"
	| "unsubscribed"
	| "deleted"
	| "point:add"
	| "point:delete"
	| "point:edit"
	| "point:edit:before"

export interface PathSubscriber {
	/** Called when any changes are made to the path */
	onPathUpdate(
		path: Path,
		messageType: PathMessageType,
		points: fabric.Point[],
		modifiedIndex?: number,
		modifiedValue?: fabric.Point
	): any
}

export interface PathOptions extends AssetOptions {
	points: fabric.Point[]
	/** If defined, the path's x/y coordinates will be determined by the relative object instead of using its own */
	relativeOrigin?: CanvasAsset
}

export interface Path extends CanvasAsset {}

@CanvasAsset.Mixin
export class Path extends fabric.Polyline implements Serializable {
	//#region    ===========================		  	    Initialization				==============================

	declare editor: EditorClass

	constructor(editor: EditorClass, options: PathOptions, style?: fabric.IPolylineOptions) {
		const points = options.points

		const fabricOptions = {
			...Path.DefaultOptions,
			...style,
			...Path.ForcedOptions,
			points,
		} as fabric.IPolylineOptions

		super(points, fabricOptions)
		if (options.relativeOrigin) this.useRelativeOrigin(options.relativeOrigin)

		this.closed = true
		this.editor = editor

		this.initAsset(options)
	}

	/**
	 * Create a new path object using the provided fabric object's defined points
	 * @param object A fabric object which extends from Polyline (will not work with Ellipse)
	 * @param style Styling options
	 * @returns the new path
	 */
	static FromObject(object: fabric.Polyline, style?: fabric.IPolylineOptions) {
		return new this(getEditor(), { points: deepCopyPoints(object.points) }, style)
	}

	owner: fabric.Object

	//#endregion =====================================================================================================

	//#region    ===========================		  	    Implementation				==============================

	public fnValidatePointMove: (index: number, oldPosition: fabric.Point, newPosition: fabric.Point) => boolean
	public onEdit: (index: number, oldPosition: fabric.Point, newPosition: fabric.Point) => void

	//#endregion =====================================================================================================

	//#region    ===========================		  	       Interface				==============================

	protected subscribers: PathSubscriber[] = []
	/**
	 * The intended way to use a path and have an item reflect updates from said path
	 *
	 * @param listener Function to run whenever an update occurs
	 * @returns function which can be called to unbind this listener
	 **/
	subscribe(listener: PathSubscriber): FunctionUnsubscribe {
		this.notifySubscribers("before:newsubscriber")

		// If a clipping mask is subscribing to this path
		this.subscribers.push(listener)

		listener.onPathUpdate(this, "subscribed", this.points)

		return this.unsubscribe.bind(this, listener)
	}

	unsubscribe(listener: PathSubscriber) {
		const i = this.subscribers.indexOf(listener)
		if (i == -1) return
		this.subscribers.splice(i, 1)
		listener.onPathUpdate(this, "unsubscribed", this.points)
	}

	insert(point: fabric.Point, index?: number) {
		if (!index || index >= this.points.length) {
			index = this.points.length
		} else if (index < 0) {
			index = 0
		}

		this.points.splice(index, 0, point)
		this.notifySubscribers("point:add", index, point)
	}

	delete(index: number) {
		const oldPoint = this.points[index]
		this.points.splice(index, 1)
		this.notifySubscribers("point:delete", index, oldPoint)
	}

	editPoint(index: number, value: { x: number; y: number }) {
		const point = this.points[index]
		const oldPoint = new fabric.Point(point.x, point.y)

		this.notifySubscribers("point:edit:before", index, new fabric.Point(value.x, value.y))
		point.setFromPoint(value)
		this.notifySubscribers("point:edit", index, oldPoint)
	}

	forceVisibility(val?: boolean) {
		this._visibilityOverride = val
	}

	like(shape: fabric.Polyline) {
		if (this.points.length !== shape.points.length) {
			this.points = deepCopyPoints(shape.points)
		}

		this.originX = shape.originX
		this.originY = shape.originY

		this.left = shape.left
		this.top = shape.top

		this.scaleX = shape.scaleX
		this.scaleY = shape.scaleY

		this.angle = shape.angle

		this.pathOffset = new fabric.Point(shape.pathOffset.x, shape.pathOffset.y)

		let i = 0
		for (const pt of this.getPoints()) {
			this.editPoint(i, shape.points[i++])
		}
	}

	/** Register this to the editor's internal list of live paths */
	register() {
		this.editor.paths.register(this)
	}

	get registered(): boolean {
		return this.editor.paths.isRegistered(this)
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  	    Default Values				==============================

	static DefaultOptions: fabric.IPolylineOptions = {
		strokeUniform: true,
		transparentCorners: false,
		cornerStyle: "circle" as const,
		stroke: "#ffffff88",
		strokeWidth: 1,
		originX: "center",
		originY: "center",
	}

	static ForcedOptions: fabric.IPolylineOptions = {
		fill: undefined,
		objectCaching: false,
		hasBorders: false,
	}
	//#endregion =====================================================================================================

	//#region    ===========================		  	Internal Functionality			==============================

	notifySubscribers(messageType: PathMessageType, modIndex?: number, modPoint?: fabric.Point) {
		for (const listener of this.subscribers) {
			listener.onPathUpdate(this, messageType, this.points, modIndex, modPoint)
		}
	}

	_visibilityOverride: boolean = undefined
	get visible() {
		return this.editor.paths.visible || this._visibilityOverride
	}

	set visible(v: boolean | undefined) {
		this._visibilityOverride = v
	}

	get evented() {
		return false
	}
	set evented(v) {
		return
	}

	get canvas() {
		return this.editor.canvas
	}
	set canvas(v) {
		return
	}

	useRelativeOrigin(target: CanvasAsset) {
		this.left = target.getBaseValue("left")
		this.top = target.getBaseValue("top")

		Object.defineProperty(this, "left", {
			get() {
				return target.getBaseValue("left")
			},
			set(v) {
				target.left = v
			},
		})
		Object.defineProperty(this, "top", {
			get() {
				return target.getBaseValue("top")
			},
			set(v) {
				target.top = v
			},
		})
	}
	//#endregion =====================================================================================================

	//#region 	 =======================================	   Serialization     =======================================

	//#endregion =====================================================================================================

	static async loadJSON(editor: EditorClass, data: Partial<Path>, id?: string): Promise<Path> {
		if (editor.paths.isRegistered(id)) return editor.paths.getPath(id)

		if (id) data["id"] = id
		data.points = fixPointSet(data.points)
		return new this(editor, data as PathOptions)
	}

	serialize(forExport?: boolean) {
		const data = convertInstanceToObject(this, {
			forExport,
			propertiesToExclude: ["controls", "subscribers", "points"],
		})

		data["points"] = deepCopyPoints(this.points)

		return data
	}
}

type FunctionUnsubscribe = () => void

registerSerializableConstructor(Path)
