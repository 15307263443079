<script lang="ts">
	import type { Device } from "luxedo-data"
	import ProjectorIcon from "../../../reusable/icons/ProjectorIcon.svelte"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"

	export let device: Device
	let activeDevice: Device
	let isHover = false

	SelectedDeviceStore.subscribe((dev) => (activeDevice = dev))

	function onClick() {
		SelectedDeviceStore.set(device)
	}
</script>

<button
	on:mouseover={() => (isHover = true)}
	on:mouseleave={() => (isHover = false)}
	class="projector-selection-button {activeDevice?.id === device.id ? 'selected' : ''}"
	style="--color-device: {device.color}; background-color: {device.color}{isHover ? '44' : '00'};"
	on:click={onClick}
	title={device.name}
>
	<ProjectorIcon {device} />
</button>

<style>
	button.projector-selection-button:not(.icon) {
		background-color: unset;
		box-shadow: unset;
		border: 2px solid var(--color-border);
		width: 3rem;
		height: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;
		transform: none;
		transition:
			border-color 250ms,
			background-color 250ms;
	}

	button.projector-selection-button.selected {
		border-color: var(--color-device);
	}

	button > :global(svg .svg-stroke) {
		stroke: unset;
	}
</style>
