<script lang="ts">
	import type { ChargebeePlanPrice, ChargebeePlanTitle } from "luxedo-rpc/src/routes/chargebee"
	import { LuxLinkSubscriptionManager } from "./LuxLinkSubscriptionController"
	import SubmenuHeading from "../SubmenuHeading.svelte"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import LuxLinkLogo from "../../../../reusable/icons/LuxLinkLogo.svelte"
	import { AccountNavigator } from "../../AccountStore"
	import { Toast } from "svelte-comps/toaster"

	let selectedPlan: ChargebeePlanPrice

	let isPlanAlreadyOwned = false

	let additonalProjectorAmount: number
	let addonPricePerUnit: number
	let addonPrice: number

	LuxLinkSubscriptionManager.subscribe(({ plan, addonQuantity }) => {
		selectedPlan = plan
		additonalProjectorAmount = addonQuantity ?? 0
		const addonPricePlan = ChargebeeManager.getAddonPricing(plan)
		addonPricePerUnit = ChargebeeManager.getPricePerUnit(addonPricePlan, additonalProjectorAmount)
		addonPrice = addonPricePerUnit * additonalProjectorAmount

		if (selectedPlan.id === ChargebeeManager.userPlanSubscriptions.luxlinkPlan.price_id)
			isPlanAlreadyOwned = true
		else isPlanAlreadyOwned = false
	})

	function onBack() {
		if (isPlanAlreadyOwned) AccountNavigator.closeSubscription()
		else LuxLinkSubscriptionManager.navigateTo("Subscriber")
	}

	function openCheckout() {
		// @ts-ignore WHY IS THERE NO TYPESCRIPT SUPPORT FOR THIS
		const chargebee = new Chargebee.init({
			site: import.meta.env.VITE_CHARGEBEE_SITE,
			isItemsModal: true,
		})

		const family: ChargebeePlanTitle = "Phantasm"

		const checkoutData = {
			customer_id: ChargebeeManager.userPlanSubscriptions.customer.id,
			plan_family: family,
		}

		const subItems = []
		subItems.push({
			item_price_id: selectedPlan.id,
			quantity: 1,
		})

		if (additonalProjectorAmount > 0) {
			const addonPrice = ChargebeeManager.getAddonPricing(selectedPlan)
			subItems.push({
				item_price_id: addonPrice.id,
				quantity: additonalProjectorAmount,
			})
		}

		checkoutData["subscription_items"] = JSON.stringify(subItems)

		let checkoutUrl: string
		if (isPlanAlreadyOwned)
			checkoutUrl = `${import.meta.env.VITE_API_URL}chargebee/generate_checkout_update`
		else checkoutUrl = `${import.meta.env.VITE_API_URL}chargebee/generate_checkout_new`

		chargebee.openCheckout({
			hostedPage: () =>
				fetch(checkoutUrl, {
					method: "post",
					body: JSON.stringify(checkoutData),
				}),
			error: () => Toast.error("An error occurred while processing your payment."),
			success: () => Toast.success("Payment succesful!"),
		})
	}
</script>

<SubmenuHeading {onBack}>
	<div class="heading-content">
		<div class="flex-row">
			<h2>Cart</h2>
		</div>
		<span>Click checkout below to finalize your purchase.</span>
	</div>
</SubmenuHeading>

<div class="cart-container">
	<table>
		<thead>
			<tr><th>Item</th><th></th><th>Price</th></tr>
		</thead>
		<tbody>
			<tr>
				<td>{selectedPlan.name}</td>
				<td></td>
				<td>
					{#if isPlanAlreadyOwned}
						Already Owned
					{:else}
						{ChargebeeManager.formatPrice(selectedPlan.price, selectedPlan.currency_code)} / {selectedPlan.period_unit}
					{/if}
				</td>
			</tr>
			<tr>
				<td>Provided LuxLink Projectors: </td>
				<td>2</td>
				<td>$0.00 / {selectedPlan.period_unit}</td>
			</tr>
			<tr>
				<td>Additional LuxLink Projectors: </td>
				<td
					>{additonalProjectorAmount}
					<button
						on:click={() => LuxLinkSubscriptionManager.navigateTo("Additional Projectors")}
						id="edit-additional-projector-button"
						class="small">Edit</button
					></td
				>
				<td
					>{ChargebeeManager.formatPrice(addonPrice, selectedPlan.currency_code)} / {selectedPlan.period_unit}</td
				>
			</tr>
			<tr>
				<td>Total: </td>
				<td></td>
				<td
					>{ChargebeeManager.formatPrice(
						(isPlanAlreadyOwned ? 0 : selectedPlan.price) + addonPrice,
						selectedPlan.currency_code
					)} / {selectedPlan.period_unit}</td
				>
			</tr>
		</tbody>
	</table>
</div>

<div class="button-container">
	<button on:click={onBack} class="link-button">Cancel</button>
	<button on:click={openCheckout}>Checkout</button>
</div>

<style>
	.cart-container {
		padding: 1rem;
		background-color: var(--color-bg-darker);
		border-radius: var(--br);
	}

	h2 {
		font-size: var(--h1);
		margin: 0;
	}

	.heading-content {
		display: flex;
		flex-direction: column;
	}

	.heading-content > span {
		font-size: var(--h3);
	}

	table {
		width: 100%;
	}

	th {
		font-size: var(--h3);
		color: var(--color-text);
	}

	th:last-of-type,
	td:last-of-type {
		text-align: end;
	}

	td:first-of-type {
		width: 1%;
		white-space: nowrap;
	}

	td:nth-of-type(2) {
		padding-left: 1rem;
	}

	tbody tr:not(:first-of-type, :last-of-type) td {
		padding-left: 1rem;
	}

	tbody tr:last-of-type td {
		padding-top: 1rem;
		color: var(--color-main);
	}

	#edit-additional-projector-button {
		margin-left: 0.5rem;
		padding: 0.05rem 0.5rem;
	}

	.button-container {
		width: fit-content;
		position: absolute;
		bottom: 1rem;
		right: 2rem;
	}
</style>
