import { Entry } from "data-handler"
import type { FileRawData } from "../types/FileTypes"

type SrcKeys = "audio" | "compressed" | "directUpload" | "editor" | "renderData" | "thumbnail"

export class Design extends Entry<DesignRawData> {
	declare shape: CakeShape
	declare length: number
	declare width: number
	declare name: string
	declare res_x: number
	declare res_y: number
	declare updated_at: Date
	declare render_ver: Date
	declare target_device_id: number | undefined
	declare src: {
		[key in SrcKeys]: string
	}

	constructor(designData: DesignRawData) {
		super(designData)
	}

	protected exportData(): Partial<DesignRawData> {
		return {
			assigned_device_id: this.target_device_id,
			shape: this.shape,
			length: this.length,
			width: this.width,
			name: this.name,
			res_x: this.res_x,
			res_y: this.res_y,
		}
	}

	protected importData(data: DesignRawData): void {
		this.name = data.name
		this.shape = data.shape
		this.length = data.length
		this.width = data.width
		this.res_x = data.res_x
		this.res_y = data.res_y
		this.updated_at = new Date(data.updated_at)
		this.render_ver = new Date(data.render_ver)
		this.target_device_id = data.assigned_device_id

		this.src = {
			audio: data.srcA,
			compressed: data.srcC,
			directUpload: data.srcD,
			editor: data.srcET,
			renderData: data.srcR,
			thumbnail: data.srcT,
		}
	}
}

export enum CakeShape {
	RECTANGLE = 1,
	CIRCLE = 2,
	SQUARE = 3,
}

export interface DesignRawData extends FileRawData {
	/* DB columns */
	shape: CakeShape
	length: number
	width: number

	name: string
	owner_id: number
	parent_id: number
	type_id: string
	created_at: Date
	updated_at: Date
	size_kb: number
	orientation: boolean
	res_x: number
	res_y: number
	render_ver: Date
	assigned_device_id?: number
	target_device_id: number
	r_checksum: string
	e_checksum: string
	direct_upload: boolean
	output_duration: number

	/* Aux dict */
	srcET: string
	srcR: string
	srcD: string
	framerate: number
	duration: number
}
