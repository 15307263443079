import { Entry } from "data-handler"
import type { FileRawData } from "../types/FileTypes"
import type { Tag } from "./Tag"
import { DataHandlerScene } from "../datahandlers/DataHandlerScene"

type SrcKeys = "audio" | "compressed" | "directUpload" | "editor" | "renderData" | "thumbnail" | "video"

export class Scene extends Entry<SceneRawData> {
	declare name: string
	declare res_x: number
	declare res_y: number
	declare updated_at?: Date
	declare render_ver?: Date
	declare target_device_id?: number
	declare owner_id?: number
	declare parent_id?: number
	declare duration: number
	declare size?: number
	declare description?: string
	declare isDirectUpload: boolean
	declare tags: Array<number>
	declare src: {
		[key in SrcKeys]: string | undefined
	}

	constructor(designData: SceneRawData) {
		super(designData)
	}

	protected exportData(): Partial<SceneRawData> {
		return {
			assigned_device_id: this.target_device_id,
			name: this.name,
			description: this.description,
			res_x: this.res_x,
			res_y: this.res_y,
		}
	}

	protected importData(data: SceneRawData): void {
		this.name = data.name
		this.res_x = data.res_x
		this.res_y = data.res_y
		this.updated_at = data.updated_at ? new Date(data.updated_at) : undefined
		this.render_ver = data.render_ver ? new Date(data.render_ver) : undefined
		this.target_device_id = data.assigned_device_id
		this.duration = data.duration
		this.size = data.size_kb
		this.description = data.description
		this.tags = data.tags
		this.isDirectUpload = data.direct_upload ?? false

		this.src = {
			audio: data.srcA,
			compressed: data.srcC,
			directUpload: data.srcD,
			editor: data.srcET,
			renderData: data.srcR,
			thumbnail: data.srcT,
			video: data.srcVA,
		}
	}

	public openInEditor() {
		// @ts-ignore
		window.location.replace(`${import.meta.env.VITE_STATIC_API_URL}editor?id=${this.id}`)
	}

	public async toggleTag(tag: Tag) {
		return await DataHandlerScene.toggleTag(this, tag)
	}

	triggerDownload = () => {
		const link = document.createElement("a")
		link.href = this.src.video!
		link.style.display = "none"
		link.download = this.name

		document.body.appendChild(link)
		link.click()

		setTimeout(() => {
			link.parentNode?.removeChild(link)
		})
	}
}

export interface SceneRawData extends FileRawData {
	name: string
	res_x: number
	res_y: number
	orientation?: boolean
	render_ver?: string
	assigned_device_id?: number
	target_device_id?: number
	r_checksum?: string
	e_checksum?: string
	direct_upload?: boolean
	output_duration?: number
	description?: string
	tags: Array<number>

	/* Aux dict */
	srcET?: string
	srcR?: string
	srcD?: string
	srcV?: string
	srcVA?: string
	framerate?: number
	duration: number
}
