<script lang="ts">
	import type { UserChargbeeSubscription } from "luxedo-data"
	import { ChargebeeManager } from "../../../../../modules/auth/onUserLogin"
	import NumberRange from "../../../../reusable/input/NumberRange.svelte"
	import { AccountNavigator } from "../../AccountStore"
	import SubmenuHeading from "../SubmenuHeading.svelte"
	import SubscriptionOption from "../SubscriptionOption.svelte"
	import { getUserPlan, getUserStorage } from "./StorageSubscriptionController"
	import { Toast } from "svelte-comps/toaster"

	let newStorageAmount: number = 50
	let currentStorageGB: number = getUserStorage().spaceAvailable / (1024 * 1024)
	let baseStorageAmount: number = getUserStorage().base / (1024 * 1024)
	let currentPlan: UserChargbeeSubscription = getUserPlan()

	let monthlyPrice: string

	let annualPrice: string
	let annualPriceMonthly: string

	function getPlanInfo() {
		const pricing = ChargebeeManager.getPlanPricing("Storage-Capacity")

		for (const price of pricing) {
			const pricePerUnit = ChargebeeManager.getPricePerUnit(price, newStorageAmount / 50)
			if (price.period_unit === "month") {
				monthlyPrice = ChargebeeManager.formatPrice(
					pricePerUnit,
					price.currency_code,
					newStorageAmount / 50
				)
			} else {
				annualPrice = ChargebeeManager.formatPrice(
					pricePerUnit,
					price.currency_code,
					newStorageAmount / 50
				)
				annualPriceMonthly = ChargebeeManager.formatPrice(
					pricePerUnit / 12,
					price.currency_code,
					newStorageAmount / 50
				)
			}
		}
	}

	function onSubscribe(period: "month" | "year") {
		// @ts-ignore WHY IS THERE NO TYPESCRIPT SUPPORT FOR THIS
		const chargebee = new Chargebee.init({
			site: import.meta.env.VITE_CHARGEBEE_SITE,
			isItemsModal: true,
		})

		const priceList = ChargebeeManager.getPlanPricing("Storage-Capacity")
		const price = priceList.find((price) => price.period_unit === period)

		const subItems = [
			{
				item_price_id: price,
				quantity: newStorageAmount / 50,
			},
		]

		const checkoutData = {
			customer_id: ChargebeeManager.userPlanSubscriptions.customer.id,
			plan_family: "Storage-Capacity",
			subscription_items: JSON.stringify(subItems),
		}

		let checkoutUrl
		if (currentPlan)
			checkoutUrl = `${import.meta.env.VITE_API_URL}chargebee/generate_checkout_update`
		else checkoutUrl = `${import.meta.env.VITE_API_URL}chargebee/generate_checkout_new`

		chargebee.openCheckout({
			hostedPage: () =>
				fetch(checkoutUrl, {
					method: "post",
					body: JSON.stringify(checkoutData),
				}),
			error: () => Toast.error("An error occurred while processing your payment."),
			success: () => Toast.success("Payment succesful!"),
		})
	}

	$: newStorageAmount && getPlanInfo()
</script>

<SubmenuHeading onBack={() => AccountNavigator.closeSubscription()}>
	<div class="flex-column">
		<h2>Extra Storage</h2>
		<span>Store many more photos, videos, and scenes by increasing your storage capacity.</span>
	</div>
</SubmenuHeading>

<div class="amount-selector">
	<label for="storage-amount-input">Add Storage: </label>
	<input
		bind:value={newStorageAmount}
		type="range"
		name="Storage Amount"
		id="storage-amount-input"
		step={50}
		min={50}
		max={500}
	/>
	<input
		bind:value={newStorageAmount}
		id="storage-amount-number-input"
		type="number"
		step={50}
		min={50}
		max={500}
	/>
	GB
</div>

<div class="amount-compare">
	<div class="old-amount">
		Current Storage Capacity:
		{currentStorageGB}GB
	</div>
	<div class="divider"></div>
	<div class="new-amount">
		New Storage Capacity:
		{baseStorageAmount + newStorageAmount}GB
	</div>
</div>

<div class="plan-options">
	{#if !currentPlan || currentPlan.billing_period_unit === "month"}
		<SubscriptionOption title="Monthly" priceInfo={`${monthlyPrice} / month`}>
			<p>Subscription fee billed monthly.</p>
			<button
				class="outline-button alt"
				disabled={currentStorageGB == newStorageAmount + baseStorageAmount}
				on:click={() => onSubscribe("month")}>Subscribe</button
			>
		</SubscriptionOption>
	{/if}
	{#if !currentPlan || currentPlan.billing_period_unit === "year"}
		<SubscriptionOption
			title="Annual"
			priceInfo={[`${annualPrice} / year`, `${annualPriceMonthly} / month`]}
		>
			<p>Subscription fee billed annually.</p>
			<button
				class="outline-button"
				on:click={() => onSubscribe("year")}
				disabled={currentStorageGB == newStorageAmount + baseStorageAmount}>Subscribe</button
			>
		</SubscriptionOption>
	{/if}
</div>

<style>
	h2 {
		margin: 0;
		font-size: var(--h1);
	}

	.flex-column span {
		font-size: var(--h3);
	}

	.amount-selector {
		align-items: center;
		display: flex;
		flex-direction: row;
	}

	.amount-compare {
		margin-bottom: 1rem;
	}

	label {
		color: var(--color-text);
	}

	p {
		font-size: var(--h3);
	}

	.divider {
		height: 1px;
		flex-grow: 1;
		background: linear-gradient(90deg, var(--color-text), var(--color-text-light));
		/* background: linear-gradient( red, blue); */
		margin: 0 0.5rem;
	}

	.amount-compare {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.old-amount {
		color: var(--color-text);
	}

	#storage-amount-input {
		margin: 0 1rem;
		padding: 0;
		height: 0.5rem;
		border: 1px solid var(--color-main);
		width: unset;
		flex-grow: 1;
	}

	#storage-amount-number-input {
		font-size: var(--h3);
		background-color: var(--color-bg-dark);
		border: unset;
		text-align: center;
	}
</style>
