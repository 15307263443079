<script lang="ts">
	import { DataHandlerSnapshot, Device, type Calibration, type Snapshot } from "luxedo-data"
	import { CollapsibleSection } from "svelte-comps/comps"
	import SnapshotTile from "../../../../../reusable/file-system/tiles/SnapshotTile.svelte"
	import { ArrowDown } from "svelte-comps/icons"
	import { tooltip } from "svelte-comps/tooltip"
	import { DeviceSnapshotManager } from "../../../../../../modules/device-operation-managers/DeviceSnapshotManager"

	const SNAPSHOT_DESCRIPTION =
		"Snapshots are generated by the calibration process. They are dynamically generated images of your projection space, which reflect your projection space. They are used as a backdrop when creating your shows."

	export let snapshots: Array<Snapshot> = []
	export let device: Device

	let isCapturing: boolean = false
	let isExpanded: boolean

	DeviceSnapshotManager.subscribe((ctx) => {
		if (ctx[device.id]) isCapturing = true
		else isCapturing = false
	})
</script>

<div class="snapshots-container">
	<CollapsibleSection bind:expanded={isExpanded}>
		<div
			slot="header"
			class="snapshot-heading"
			use:tooltip={{ content: SNAPSHOT_DESCRIPTION, pointing: "top" }}
		>
			<div class="span">
				<h4>Snapshots</h4>
				<button class="small icon {isExpanded ? 'open' : ''}"><ArrowDown /></button>
			</div>
			{#if isCapturing}
				<button class="link-button" disabled> Capturing... </button>
			{:else}
				<button
					class="link-button"
					title="Capture New Snapshot"
					on:click|stopPropagation={() => DeviceSnapshotManager.captureSnapshot(device)}
				>
					Capture New
				</button>
			{/if}
		</div>
		<div class="snapshots" slot="content">
			{#if snapshots.length}
				{#each snapshots as snapshot}
					<SnapshotTile {snapshot} />
				{/each}
			{:else}
				<p class="info">
					All snapshots associated with this calibration have been deleted. Click "Capture New
					Snapshot" to capture a new snapshot.
				</p>
				<div class="button-container">
					{#if isCapturing}
						<button class="outline-button" disabled> Capturing... </button>
					{:else}
						<button
							class="outline-button"
							title="Capture New Snapshot"
							on:click|stopPropagation={() => DeviceSnapshotManager.captureSnapshot(device)}
						>
							Capture New
						</button>
					{/if}
				</div>
			{/if}
		</div>
	</CollapsibleSection>
</div>

<style>
	.snapshot-heading {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 0 0.5rem;
		width: 100%;
	}

	.info {
		width: 100%;
		text-align: center;
		padding: 0.5rem;
		color: var(--color-text);
	}

	.snapshot-heading .link-button {
		color: var(--color-main);
		padding: 0;
	}

	h4 {
		color: var(--color-text);
		font-size: var(--h2);
		margin: 0;
	}

	.snapshots-container {
		background-color: var(--color-bg);
		border-radius: var(--br);
		padding: 0.5rem;
		margin-bottom: 0;
	}

	.snapshots {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.snapshots :global(.tile-container) {
		margin: 0.5rem;
	}

	button :global(svg) {
		transition: transform 250ms;
	}

	button.small.icon.open :global(svg) {
		transform: rotateX(180deg);
	}

	div.span {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
</style>
