<script lang="ts">
	import { Device } from "luxedo-data"
	import { ProjectorRegistrationContext } from "../ProjectorRegistrationContext"
	import ProjectorIcon from "../../../reusable/icons/ProjectorIcon.svelte"

	let color: string
	ProjectorRegistrationContext.subscribe((ctx) => {
		if (ctx.isRegistering) {
			color = Device.getUIColorByIndex(ctx.colorIndex)
		}
	})
</script>

<button class="projector-selection-button" title={"New Device"}>
	<!-- <ProjectorIcon strokeColor={color} /> -->
	<div class="icon-bg-color" style="background-color: {color};" />
	<div class="icon-bg" />
</button>

<style>
	button {
		background-color: unset;
		box-shadow: unset;
		border: 2px solid var(--color-border);
		width: 3rem;
		height: 3rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.5rem;
		transition:
			border-color 250ms,
			background-color 250ms;
		overflow: hidden;
		position: relative;
		transform: unset;
	}

	button .icon-bg {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		backdrop-filter: blur(0.5rem);
	}

	button .icon-bg-color {
		position: absolute;
		border-radius: 100%;
		z-index: -1;
		width: 1rem;
		height: 1rem;
	}

	button.selected {
		border-color: var(--color-text-light);
	}

	button > :global(svg .svg-stroke) {
		stroke: unset;
	}
</style>
