<script lang="ts">
	import { DataHandlerDevice, type Device, DeviceRPi, DeviceGroup } from "luxedo-data"
	import DeviceSelector from "../../../input/DeviceSelector.svelte"
	import { GroupEditorController } from "../GroupEditorController"

	let group: DeviceGroup

	let availableDevices: Array<DeviceRPi> = []
	let selectedDevices: Array<DeviceRPi> = []

	function updateAvailableDevices() {
		availableDevices = DataHandlerDevice.getMany().filter(
			(dev) => dev instanceof DeviceRPi
		) as Array<DeviceRPi>
		if (group)
			availableDevices = availableDevices.filter(
				(dev) => !(dev.getParent() && dev.getParent() !== group)
			)
	}

	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
		selectedDevices = ctx.devices
		updateAvailableDevices()
	})

	DataHandlerDevice.addListener(updateAvailableDevices)

	function onDeviceSelect(device: DeviceRPi) {
		if (selectedDevices.includes(device)) {
			// if removing
			selectedDevices.splice(selectedDevices.indexOf(device), 1)
		} else {
			// if adding
			selectedDevices.push(device)
		}
		selectedDevices = [...selectedDevices]
	}

	function getDeviceSelected(devices: Array<Device>, device: Device) {
		return devices.includes(device)
	}

	function next() {
		GroupEditorController.setSelectedDevices(selectedDevices)
		GroupEditorController.next()
	}
</script>

<h3>Select which devices will be included in this group</h3>
{#each availableDevices as device}
	<DeviceSelector
		type="checkbox"
		{device}
		isSelected={getDeviceSelected(selectedDevices, device)}
		onSelect={onDeviceSelect}
	/>
{/each}

<div id="step-buttons">
	<button
		id="next-step-button"
		class="outline-button"
		on:click={next}
		disabled={selectedDevices.length === 0}
	>
		Next
	</button>
</div>

<style>
</style>
