<script lang="ts">
	import Guides from "luxedo-data/src/data/Guides"
	import type { Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openCalibrationOverlay } from "../../../reusable/overlays/calibration"

	let device: Device
	SelectedDeviceStore.subscribe((dev) => (device = dev))
</script>

<div class="next-steps-heading">
	<h1>Calibration</h1>
</div>
<p>
	Now that your device has been registered, powered on and connected to the internet, its time to
	calibrate.
</p>
<div class="info">
	<p>Before calibrating, it may be helpful to understand the calibration process:</p>
	<p>
		Calibration is the process of projecting patterns from your Luxedo, taking pictures of the
		projection using the onboard camera, then processing the images to create a snapshot that truly
		reflects your projection space.
	</p>

	Calibration is necessary to create shows which are accurately mapped to your projection space.
	<!-- Calibration is the process of projecting patterns from your Luxedo while capturing images of the projection
	using the onboard camera. These images are then mathematically processed in order to create an image
	which accurately represents your projection space called a snapshot. -->
</div>

{#if device && device.isOnline}
	<button class="outline-button" on:click={openCalibrationOverlay}>Calibrate Now</button>
{:else}
	<p class="warn">
		⚠ Your device is currently offline. Be sure your device is powered on and connected to Wi-Fi to
		begin calibration.
	</p>
{/if}

<p class="help">
	Having trouble? Read <a href={Guides["Device"]["Troubleshooting Calibration"]}>this guide</a>
	for help troubleshooting or follow
	<a href={Guides["Device"]["Calibrating Your Device"]}>this guide</a> for a walkthrough.
</p>

<style>
</style>
