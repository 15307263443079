import { Device, type AdvancedCalibrationOptions, Version, type ImageCaptureConfig } from "luxedo-data"
import { closeOverlay, openOverlay } from "svelte-comps/overlay"
import CalibrationOverlay from "./CalibrationOverlay.svelte"
import { DeviceCalibrationManager } from "../../../../modules/device-operation-managers/DeviceCalibrationManager"
import { writable, type Unsubscriber, get } from "svelte/store"
import { Toast } from "svelte-comps/toaster"
import { LuxedoRPC } from "luxedo-rpc"

export namespace CalibrationOverlayController {
	const OVERLAY_STEPS = {
		Instructions: 0,
		Loading: 1,
		Finished: 2,
		Failed: -1,
		Troubleshooter: -2,
	}

	let device: Device
	let overlayID: string
	let unsubscriber: Unsubscriber

	type ContextType = DeviceCalibrationManager.CalibrationStatus
	const CONTEXT_DEFAULT: ContextType = {
		message: "Initializing...",
		progress: 0,
		step: 0,
		pncID: undefined,
	}

	const store = writable<ContextType>(CONTEXT_DEFAULT)

	export function subscribe(cb: (ctx: ContextType) => void) {
		return store.subscribe(cb)
	}

	function onCalibrationUpdate(calStatus: DeviceCalibrationManager.CalibrationStatus) {
		// set the overlay step
		let overlayStep = OVERLAY_STEPS.Loading
		if (calStatus.failInfo || calStatus.errorCode) overlayStep = OVERLAY_STEPS.Failed
		if (calStatus.step === 4) overlayStep = OVERLAY_STEPS.Finished

		store.set({ ...calStatus, step: overlayStep })
	}

	export function open(calDevice: Device) {
		reset()

		device = calDevice

		overlayID = openOverlay(CalibrationOverlay, {
			heading: "Calibrate Projector",
			classHeading: "no-underline",
		})
	}

	export function startCalibration() {
		if (unsubscriber) unsubscriber()
		unsubscriber = DeviceCalibrationManager.subscribe(device, onCalibrationUpdate)
		DeviceCalibrationManager.calibrateDevice(device)
	}

	export function retry() {
		store.set(CONTEXT_DEFAULT)
		if (Advanced.useAdvancedOptions) {
			DeviceCalibrationManager.calibrateDevice(device, Advanced.advancedOptions)
		} else {
			DeviceCalibrationManager.calibrateDevice(device)
		}
	}

	export function reset() {
		device = undefined
		overlayID = undefined
		Advanced.advancedOptions = undefined
		Advanced.useAdvancedOptions = false
		if (unsubscriber) unsubscriber()
		store.set(CONTEXT_DEFAULT)
	}

	export function close() {
		device.cancelCalibration()
		closeOverlay(overlayID)
		reset()
	}

	export async function userUnsatisfied() {
		const { pncID } = get(store)
		if (!pncID) Toast.error("Unable to retrieve calibration images. [Error code: PNC-004]")

		try {
			const res = await LuxedoRPC.api.pnc.get_pnc_images(pncID)
			if (res[1] === 200) openTroubleshooter(res[0])
			else {
				Toast.error("Unable to retrieve calibration images. [Error code: PNC-005]")
			}
		} catch (e) {
			console.error("[ERROR] while getting cal images", e)
			Toast.error("Unable to retrieve calibration images. [Error code: GET_PNC_FAIL]")
		}
	}

	export function openTroubleshooter(images?: Array<string>) {
		if (unsubscriber) unsubscriber()
		Advanced.useAdvancedOptions = false
		Advanced.advancedOptions = undefined
		store.update((ctx) => ({ ...ctx, step: OVERLAY_STEPS.Troubleshooter, failInfo: images ?? ctx.failInfo }))
	}

	export namespace Advanced {
		export let useAdvancedOptions: boolean = false
		export let advancedOptions: AdvancedCalibrationOptions

		export function setDoUseAdvancedOptions(doUse: boolean) {
			useAdvancedOptions = doUse
			advancedOptions = Advanced.generateAutoCalibrationOptions()
		}

		export function updateAdvancedOptions(options: Partial<ImageCaptureConfig>) {
			advancedOptions = { ...advancedOptions, imagegen_inputs: { ...advancedOptions.imagegen_inputs, ...options } }
		}

		export function generateAutoCalibrationOptions(options?: Partial<ImageCaptureConfig>): AdvancedCalibrationOptions {
			const opts = {
				imagegen_inputs: {
					bucket_size: 8,
					brightness: 255,
					num_runs: 1,
				},
				clean_cap: true,
				take_snapshot: true,
				exposure_options: {
					exposure_values: [16, 32, 64, 128, 256, 512, 1024],
					minimum_brightness: 30,
					maximum_brightness: 200,
					prioritize_stdev: true,
					ideal_metric: 110,
					clear_frames: 10,
				},
			}

			if (device.typeId === "dev_luxcast" && Version.compare_strings(device.firmwareVersion, "2.12.0") > 0) {
				opts["exposure_options"]["exposure_values"] = [16, 32, 64, 128, 256, 512]

				const lumenCount = device.lumenCount ?? 4000
				if (lumenCount) {
					if (lumenCount <= 3000) {
						opts["exposure_options"]["exposure_values"] = [64, 128, 256, 512]
					} else if (lumenCount <= 4000) {
						opts["exposure_options"]["exposure_values"] = [32, 64, 128, 256]
					} else if (lumenCount <= 5000) {
						opts["exposure_options"]["exposure_values"] = [32, 64, 128, 256]
					} else {
						opts["exposure_options"]["exposure_values"] = [32, 64, 128]
					}
				}
			}

			if (device.resX > 2560 || device.resY > 1920) {
				opts.imagegen_inputs.bucket_size = 16
			}

			if (options) opts.imagegen_inputs = { ...opts.imagegen_inputs, ...options }

			return opts
		}
	}
}
