import { fabric } from "fabric"

export type Corners = [fabric.Point, fabric.Point, fabric.Point, fabric.Point]

export function calcCenter(points: fabric.Point[]) {
	return new fabric.Point(
		(fabric.util.array.max(points, "x") + fabric.util.array.min(points, "x")) / 2,
		(fabric.util.array.max(points, "y") + fabric.util.array.min(points, "y")) / 2
	)
}

export function calcBounds(points: fabric.Point[]) {
	return {
		left: fabric.util.array.min(points, "x"),
		top: fabric.util.array.min(points, "y"),
		right: fabric.util.array.max(points, "x"),
		bottom: fabric.util.array.max(points, "y"),
	}
}

/**
 * If the points aren't actual fabric.Point instances, convert them
 * This is used to fix errors when loading a file
 */
export function fixPointSet(points: fabric.Point[]) {
	if (!(points[0] instanceof fabric.Point)) {
		points = points.map((pt) => new fabric.Point(pt.x, pt.y))
	}

	const lastIndex = points.length - 1
	if (points[0].x == points[lastIndex].x && points[0].y == points[lastIndex].y) {
		points[lastIndex] = points[0]
	}
	return points
}

export function offsetPoints(points: fabric.Point[], offset: fabric.Point): fabric.Point[] {
	for (const pt of points) {
		pt.addEquals(offset)
	}
	return points
}

export function deepCopyPoints(points: fabric.Point[]): fabric.Point[] {
	return points.map((pt) => {
		return new fabric.Point(pt.x, pt.y)
	})
}

export function getQuadrilateralIsConvex(points: fabric.Point[]) {
	function crossProduct(vector1: fabric.Point, vector2: fabric.Point): number {
		return vector1.x * vector2.y - vector1.y * vector2.x
	}

	let pt1 = points[0]
	let pt2 = points[1]
	let pt3 = points[2]
	let pt4 = points[3]

	const vector1 = pt2.subtract(pt1)
	const vector2 = pt3.subtract(pt2)
	const vector3 = pt4.subtract(pt3)
	const vector4 = pt1.subtract(pt4)

	const crossProduct1 = crossProduct(vector1, vector2)
	const crossProduct2 = crossProduct(vector2, vector3)
	const crossProduct3 = crossProduct(vector3, vector4)
	const crossProduct4 = crossProduct(vector4, vector1)

	if (crossProduct1 * crossProduct3 < 0 || crossProduct2 * crossProduct4 < 0) return false
	return true
}

export function findNearestPointOnLine(point: fabric.Point, line: [fabric.Point, fabric.Point]): fabric.Point {
	const A = line[0]
	const B = line[1]

	const A2P = point.subtract(A)
	const A2B = B.subtract(A)
	const A2B_sqr = A2B.x ** 2 + A2B.y ** 2

	const A2P_dot_A2B = A2P.x * A2B.x + A2P.y * A2B.y

	const dist = Math.max(0, Math.min(A2P_dot_A2B / A2B_sqr, 1))

	return new fabric.Point(A.x + A2B.x * dist, A.y + A2B.y * dist)
}
