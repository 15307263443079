import {
	DataHandlerScene,
	DeviceGroup,
	type Device,
	DataHandlerDevice,
	DataHandlerMask,
	DataHandlerCalibration,
} from "luxedo-data"
import { writable, type Unsubscriber } from "svelte/store"
import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
import DeviceNextStepsNew from "./DeviceNextSteps_New.svelte"
import DeviceNextStepsCal from "./DeviceNextSteps_Cal.svelte"
import DeviceNextStepsShow from "./DeviceNextSteps_Show.svelte"
import DeviceNextStepsEvent from "./DeviceNextSteps_Event.svelte"
import DeviceNextStepsComplete from "./now-playing/DeviceNextSteps_NowPlaying.svelte"
import DeviceNextStepsGroupConfig from "./DeviceNextSteps_GroupConfig.svelte"
import DeviceNextStepsUpdating from "./DeviceNextSteps_Updating.svelte"
import DeviceNextStepsRegister from "./DeviceNextSteps_Register.svelte"
import DeviceNextStepsMaskFirst from "./DeviceNextSteps_MaskFirst.svelte"

export const DEVICE_SETUP_STEPS = {
	// Device Groups
	"No Group Configuration": DeviceNextStepsGroupConfig,

	// All Devices
	"Mask First": DeviceNextStepsMaskFirst,
	"New & Offline": DeviceNextStepsNew,
	Register: DeviceNextStepsRegister,
	"No Calibration": DeviceNextStepsCal,
	"No Scene": DeviceNextStepsShow,
	"No Events": DeviceNextStepsEvent,
	"Setup Complete": DeviceNextStepsComplete,
	Updating: DeviceNextStepsUpdating,
}

type NextStepsCTX = {
	currentStep: keyof typeof DEVICE_SETUP_STEPS
}

export namespace NextStepsController {
	const store = writable<NextStepsCTX>({ currentStep: "Register" })

	let device: Device
	let deviceListenerId: string

	const onDeviceUpdate = () => {
		console.log("device updated", device)

		if (!device) return setStep("Register")

		setTimeout(() => parseCurrentStep())
	}

	SelectedDeviceStore.subscribe((dev) => {
		if (!dev) return onDeviceUpdate()

		if (deviceListenerId && device && dev.id !== device.id) {
			device.removeUpdateListener(deviceListenerId)
		}

		if (!device || dev.id !== device.id) {
			deviceListenerId = dev.addUpdateListener(onDeviceUpdate)
			device = dev
		} else {
			onDeviceUpdate()
		}
	})

	export function subscribe(cb: (ctx: NextStepsCTX) => void) {
		return store.subscribe(cb)
	}

	function setStep(step: keyof typeof DEVICE_SETUP_STEPS) {
		DataHandlerMask.removeListener(onDeviceUpdate)
		if (["Mask First"].includes(step)) {
			DataHandlerMask.addListener(onDeviceUpdate)
		}

		store.update((ctx) => {
			return {
				...ctx,
				currentStep: step,
			}
		})
	}

	function parseCurrentStep() {
		if (DataHandlerDevice.getMany().length === 0 || !device) return setStep("Register")

		// Device groups first
		if (device instanceof DeviceGroup) {
			const groupDevices = device.children.map((slot) => DataHandlerDevice.get(slot.device_id)).filter((dev) => !!dev) // filter out the negative (non-existant) devices

			if (groupDevices.length < 1) return setStep("No Group Configuration")

			return setStep("Setup Complete")
		}

		// Check for masks under most recent cal if relevant
		const lastCal = DataHandlerCalibration.filterByDevice(device, true)[0]

		// If the device is updating
		if (device.isUpdating) return setStep("Updating")

		// If the device is not calibrated AND is NOT online
		if (!device.isCalibrated && !device.isOnline) return setStep("New & Offline")

		// If the device is not calibrated AND IS online
		if (!lastCal || !device.isCalibrated) return setStep("No Calibration")

		const relevantMasks = DataHandlerMask.filterByCalibration(lastCal.id)

		// If the device is calibrated, but no masks have been configured
		if (relevantMasks.length === 0) return setStep("Mask First")

		// If there are no scenes associated with this device
		if (DataHandlerScene.getByDevice(device).length === 0) return setStep("No Scene")

		// If there are no events associated with this device
		if (device.timetableManager.events.length === 0) return setStep("No Events")

		// Otherwise, show upcoming events
		setStep("Setup Complete")
	}
}
