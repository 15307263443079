<script lang="ts">
	import { DataHandlerDevice, DataHandlerSnapshot, DeviceGroup } from "luxedo-data"

	export let selectedSnapshotId: number
	export let selectedDeviceId: number

	let resX: number
	let resY: number

	function updateResolution() {
		const device = DataHandlerDevice.get(selectedDeviceId)

		if (device instanceof DeviceGroup) {
			resX = device.resX
			resY = device.resY
		} else {
			const snapshot = DataHandlerSnapshot.get(selectedSnapshotId)
			resX = snapshot.resolution.w
			resY = snapshot.resolution.h
		}
	}

	$: (selectedSnapshotId || selectedDeviceId) && updateResolution()
</script>

<div class="flex-column">
	<h3>Export your project</h3>
	<p>Export your project, making sure that:</p>
	<ul>
		<li>Your snapshot image has been hidden before rendering your scene.</li>
		<li>The scene is being exported to the <span class="highlight">MP4</span> format.</li>
		<li>
			Your scene is being exported to the correct resolution <span class="highlight"
				>({resX ?? "0"}x{resY ?? "0"})</span
			>.
		</li>
	</ul>
	<p>Once exported, move on to the next step to upload.</p>
</div>

<style>
	p {
		margin: 1rem 0 0 0;
		color: var(--color-text);
	}

	.highlight {
		color: var(--color-main);
	}

	ul {
		list-style: disc;
		margin-left: 1rem;
		color: var(--color-text);
	}
</style>
