<script lang="ts">
	export let isForward = false
</script>

<svg
	width="5"
	height="10"
	viewBox="0 0 5 10"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
	style={isForward ? "transform: rotate(180deg)" : ""}
>
	<g clip-path="url(#clip0_2_39)">
		<path
			d="M0.18 5.12967L2.66999 8.67966C3.22999 9.47966 4.49 9.07965 4.49 8.10965V0.999666C4.49 0.0196657 3.22999 -0.370342 2.66999 0.429658L0.18 3.97965C-0.06 4.31965 -0.06 4.77967 0.18 5.12967Z"
			fill="white"
		/>
	</g>
	<defs>
		<clipPath id="clip0_2_39">
			<rect width="5" height="10" fill="white" />
		</clipPath>
	</defs>
</svg>

<style>
</style>
