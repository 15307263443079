import { Track } from "../tracks"

/**
 * Check if a property can be animated
 * @param object the object to animate
 * @param property The property to animate
 * @returns true if this is an animatable property
 */
export function canAnimate(object: Object, property: string) {
	if (!object || !(property in object)) return false
	if (typeof object[property] === "number") return true
	if (object[property] === undefined) return true
	return false
}

/**
 * A timestamp between 0 (the start of the track) and 1 (the end of the track)
 * To get it to stop returning an error,
 */
export type LocalTimestamp = number & { readonly _brand: unique symbol }

export class RelativeTimestampError extends Error {
	constructor(timestamp?: number) {
		super(`Animation timestamps must be relative, between 0 (start of track) and 1 (end of track) - got ${timestamp}`)
	}
}

export function assertTimeIsZeroToOne(timestamp: number): asserts timestamp is LocalTimestamp {
	const eps = 1e-6
	if (timestamp < 0 - eps || timestamp > 1 + eps) throw new RelativeTimestampError(timestamp)
}

/** Convert an absolute timestamp to a timestamp in the local timescale of the track */
export function timestampAbsoluteToLocal(timestamp: number, track: Track): LocalTimestamp {
	return ((timestamp - track.start) / track.duration) as LocalTimestamp
}

/** Convert a timestamp in the track's timescale to the whole timeline's scale */
export function timestampLocalToAbsolute(timestamp: LocalTimestamp, track: Track): number {
	return timestamp * track.duration + track.start
}
