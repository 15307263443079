<script lang="ts">
	import { TextRowSelector } from "svelte-comps/inputs"
	import GroupEditorInputsBlending from "./GroupEditorInputs_Blending.svelte"
	import GroupEditorInputsAlignment from "./GroupEditorInputs_Alignment.svelte"
	import GroupEditorInputsActions from "./GroupEditorInputs_Actions.svelte"
	import { DataHandlerDevice, DeviceGroup, type Device, type DeviceGroupSlot } from "luxedo-data"
	import GroupEditorInputsSlotOptions from "./GroupEditorInputs_SlotOptions.svelte"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	let selectedOption: "Alignment Tools" | "Blending Options" = "Alignment Tools"

	let group: DeviceGroup
	let selectedSlot: DeviceGroupSlot
	let selectedDevice: Device

	let isHidden = false

	GroupEditorController.View.subscribe((ctx) => (isHidden = ctx.shrinkInputs))
	GroupEditorController.subscribe((ctx) => {
		selectedSlot = ctx.selectedSlot
		group = ctx.group
		if (selectedSlot) selectedDevice = DataHandlerDevice.get(selectedSlot.device_id)
		else selectedDevice = undefined
	})
</script>

<div class="editor-input-container {isHidden ? 'hidden' : ''}">
	<div class="left">
		<TextRowSelector
			options={["Alignment Tools", "Blending Options"]}
			fontSize="var(--h1)"
			bind:selectedOption
		/>
		<div class="editor-inputs">
			{#if selectedOption == "Alignment Tools"}
				<GroupEditorInputsAlignment />
			{:else if selectedOption == "Blending Options"}
				<GroupEditorInputsBlending />
			{/if}
		</div>
	</div>
	{#if selectedSlot}
		<GroupEditorInputsSlotOptions {group} slot={selectedSlot} />
	{/if}
	<div class="right">
		<GroupEditorInputsActions />
	</div>
</div>

<style>
	.right {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.editor-input-container {
		padding: 1rem 2rem 2rem 2rem;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 15rem;
		transition:
			height 500ms,
			padding 500ms;
		/* min-height: 13rem; */
	}

	.editor-input-container.hidden {
		height: 0;
		padding: 0 2rem;
	}

	.editor-inputs {
		max-width: 24rem;
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		min-height: 6.5rem;
		padding: 0.5rem 1rem;
		margin-top: 0.5rem;
		display: flex;
		flex-direction: column;
		/* justify-content: center; */
	}

	.editor-inputs :global(label),
	.editor-inputs :global(.label) {
		width: 6rem;
	}

	.editor-inputs :global(select) {
		padding: 0 0.5rem;
	}

	.editor-inputs :global(.toggle-switch) {
		margin: 0 0.5rem;
	}
</style>
