<script lang="ts">
	import { DataHandlerMedia, type Media } from "luxedo-data"
	import type Dropzone from "dropzone"
	import { Toast } from "svelte-comps/toaster"
	import { LuxedoRPC } from "luxedo-rpc"
	import { closeOverlay } from "svelte-comps/overlay"
	import { DropzoneInput } from "svelte-comps/inputs"

	interface UploadFile extends Dropzone.DropzoneFile {
		hash: string
	}

	export let media: Media

	let isUploading = false

	function resetState() {
		isUploading = false
	}

	async function onUpload(file: UploadFile, name: string, dz: Dropzone) {
		dz.processFile(file)
	}

	async function onDropzoneSend(file: UploadFile, xhr: XMLHttpRequest, formData: FormData) {
		formData.append("file_id", String(media.id))
	}

	function onFail(file: UploadFile, message: string | Error, xhr: XMLHttpRequest) {
		Toast.error(`Error reuploading file. ${message}`)
		resetState()
	}

	async function onSuccess(file: UploadFile) {
		try {
			await LuxedoRPC.api.media.media_process(media.id, media.mimetype)
			media.is_src_deleted = false
			await DataHandlerMedia.push([media])

			isUploading = false
			Toast.success("Media reuploaded successfully!")
			closeOverlay()
		} catch (e) {
			console.error("Unable to reupload media file", e)
			Toast.error("Unable to process file.")
		}
	}
</script>

<div class="dropzone-wrapper">
	<DropzoneInput
		isLoading={isUploading}
		uploadButtonId="media-upload-button"
		uploadUrl={`${import.meta.env.VITE_API_URL}workspace/media/splitupload`}
		{onUpload}
		{onDropzoneSend}
		{onFail}
		{onSuccess}
	/>
</div>

<style>
	:global(.file-uploader) {
		width: 100%;
		height: 8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: var(--br);
		background-color: var(--color-bg);
		color: var(--color-text);
		padding: 1rem;
	}

	.dropzone-wrapper {
		height: 100%;
	}

	.dropzone-wrapper :global(.button-container) {
		position: absolute;
		bottom: 0;
		right: 1rem;
	}
</style>
