import { DeviceGroup } from "luxedo-data"
import { closeOverlay, openMultiButtonOverlay, openOverlay } from "svelte-comps/overlay"
import GroupEditor from "./GroupEditor.svelte"
import { GroupEditorController } from "./GroupEditorController"
import { Toast } from "svelte-comps/toaster"
import type { GroupOverlapController } from "./steps/blend/GroupOverlapController"

export function openGroupEditorOverlay(group: DeviceGroup) {
	const beforeClose = () => {
		return new Promise<void>((res, rej) => {
			if (group.dirty) {
				const overlayID = openMultiButtonOverlay({
					prompt: ["Are you sure you want to close the group editor?", "Any unsaved changes will be lost!"],
					buttons: [
						{
							text: "Close",
							onClick: () => {
								closeOverlay(overlayID)
								res()
							},
							className: "warn",
						},
						{
							text: "Cancel",
							onClick: () => {
								closeOverlay(overlayID)
								rej()
							},
							className: "cancel",
						},
						{
							text: "Save and Close",
							onClick: async () => {
								Toast.text("Saving group configuration...")
								await GroupEditorController.save(true)
								closeOverlay(overlayID)
							},
						},
					],
				})
			} else res()
		})
	}

	if (!(group instanceof DeviceGroup)) throw new Error("Must open with a device group")

	GroupEditorController.activate(group)

	GroupEditorController.overlayID = openOverlay(GroupEditor, {
		classHeading: "no-underline",
		// large
		classOverlay: "no-pad can-grow full-sized",
		beforeClose,
		props: {
			group,
		},
	})
}

export type OverlapInstance = GroupOverlapController.OverlapInstance
