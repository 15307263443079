import { type BundleOf, DataHandler, type EntryID, type IDMap } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"
import type { BundledAccountData } from "luxedo-rpc/src/types/BundledTypes"
import { User, type UserRawData } from "../../entries/User"

class DHUsers extends DataHandler<User> {
	EntryClass = User

	protected async fetch(specificInstances?: EntryID[]): Promise<BundleOf<User>> {
		const data = await LuxedoRPC.api.bundlers.account_bundle_down_admin(specificInstances ?? [])
		return data
	}

	protected async pushData(entryData: { [id: number]: Partial<UserRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.account_bundle_up_admin(entryData as unknown as IDMap<BundledAccountData>)
	}

	protected async requestDeleteEntry(entry: User): Promise<void> {
		return await LuxedoRPC.api.admin.account_delete(entry.id!)
	}

	public async getAssociatedIds(user: User) {
		const returnData = await LuxedoRPC.api.admin.account_data_get_associated_ids(user.id!)
		return {
			deviceIDs: returnData.device_ids,
			fileIDs: returnData.file_ids,
		}
	}
}

export const DataHandlerUserAdmin = new DHUsers()
