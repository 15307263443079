<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import NumberRange from "../../../../reusable/input/NumberRange.svelte"
	import type { DeviceGroup } from "luxedo-data"
	import { onMount } from "svelte"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	type GridMode = "simple" | "stitched"

	let group: DeviceGroup

	let isActive = false
	let hasMounted = false

	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
		isActive = ctx.alignmentOptions.isGridActive
	})

	let gridSize = 200
	let gridMode: GridMode = "simple"

	function toggleGridVisibility(doShowGrid: boolean) {
		if (doShowGrid) showGrid()
		else hideGrid()
	}

	async function showGrid(mode?: GridMode, size?: number) {
		await GroupEditorController.showGrid(mode ?? gridMode, size ?? gridSize)
	}

	async function hideGrid() {
		GroupEditorController.hideGrid()
	}

	function onGridUpdate(mode: GridMode, size: number) {
		if (isActive && hasMounted) {
			showGrid(mode, size)
		}
	}

	onMount(() => {
		hasMounted = false

		const { alignmentOptions } = GroupEditorController.getCurrentContext()
		if (alignmentOptions.isGridActive) {
			isActive = true
			gridSize = alignmentOptions.gridSize
			gridMode = alignmentOptions.gridMode
		} else {
			isActive = false
		}

		hasMounted = true
	})

	$: onGridUpdate(gridMode, gridSize)
</script>

<div class="input-container">
	<span class="label">Project Grid</span>
	<ToggleSwitch {isActive} onUpdate={toggleGridVisibility} />
</div>
<div class="select-container">
	<label for="grid-mode-input">Grid Mode</label>
	<select name="" id="grid-mode-input" bind:value={gridMode}>
		<option value="simple">Simple</option>
		<option value="stitched">Stitched</option>
	</select>
</div>
<NumberRange label="Grid Size" min={8} max={400} bind:value={gridSize} />

<style>
</style>
