<script lang="ts">
	import { DataHandlerSnapshot, type Snapshot } from "luxedo-data"
	import { onMount } from "svelte"
	import { DateTime } from "luxon"
	import { initContextMenu, type ContextMenuOptions } from "svelte-comps/context-menu"
	import DownloadIcon from "../../icons/DownloadIcon.svelte"
	import { openConfirmOverlay } from "svelte-comps/overlay"
	import { Toast } from "svelte-comps/toaster"
	import { openSnapshotPreview } from "../../overlays"

	export let snapshot: Snapshot
	export let isSelected: boolean = false
	export let onClick: () => void = undefined

	let dateString: string

	const contextMenuOptions: ContextMenuOptions = [
		{
			title: "View",
			onClick: viewSnapshotOverlay,
		},
		{
			title: "Download",
			onClick: () => {
				snapshot.triggerDownload()
			},
		},
		{
			title: "Delete",
			onClick: () => verifyDeleteSnapshot(),
		},
	]

	function viewSnapshotOverlay() {
		openSnapshotPreview(snapshot)
	}

	function verifyDeleteSnapshot() {
		openConfirmOverlay({
			prompt: ["Are you sure you want to delete this snapshot?", "This cannot be undone."],
			buttons: {
				confirm: {
					text: "Delete",
					onClick: deleteSnapshot,
				},
			},
		})
	}

	async function deleteSnapshot() {
		try {
			await DataHandlerSnapshot.deleteEntry(snapshot)
			Toast.success("Snapshot successfully deleted.")
		} catch (e) {
			console.error("ERROR deleting snapshot", e)
			Toast.error("Unable to delete snapshot. Please try again...")
		}
	}

	onMount(() => {
		const dt = DateTime.fromJSDate(snapshot.datesaved)
		dateString = dt.toLocaleString(DateTime.DATE_SHORT)
	})
</script>

<div
	class="tile-container {isSelected ? 'is-selected' : ''}"
	role="button"
	tabindex="0"
	on:contextmenu={initContextMenu(contextMenuOptions)}
	on:keydown={undefined}
	on:click={onClick ? onClick : viewSnapshotOverlay}
>
	<div class="tile">
		<img src={snapshot.src} alt="" />
	</div>
	<div class="tile-label-container">
		<span class="tile-label snapshot-label">
			<span>
				{dateString}
			</span>
			<button
				title="download"
				class="icon small download-btn"
				on:click|stopPropagation={snapshot.triggerDownload}
			>
				<DownloadIcon />
			</button>
		</span>
	</div>
</div>

<style>
	.snapshot-label {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.download-btn {
		padding: 0;
	}

	button.icon.small.download-btn > :global(svg) {
		height: 1.25rem;
	}

	.download-btn :global(.svg-fill) {
		fill: var(--color-text);
	}

	.download-btn:hover :global(.svg-fill),
	.download-btn:focus-visible :global(.svg-fill) {
		fill: var(--color-text-light);
	}
</style>
