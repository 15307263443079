<script lang="ts">
	import { ToggleSwitch } from "svelte-comps/inputs"
	import DownloadIcon from "../../../../reusable/icons/DownloadIcon.svelte"
	import type { DeviceGroup } from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	let doHideSnapshots = false
	let group: DeviceGroup

	GroupEditorController.subscribe((ctx) => {
		group = ctx.group
	})

	async function downloadSnapshot() {
		const snapshot = await group.getSnapshot()
		try {
			await snapshot.triggerDownload()
		} catch (e) {
			console.error("ERROR downloading combined snapshot", e)
			Toast.error("Unable to download combined snapshot.")
		}
	}

	$: GroupEditorController.View.toggleSnapshotVisibility(!doHideSnapshots)
</script>

<div class="group-editor-actions">
	<button id="add-slot-button" class="outline-button" on:click={GroupEditorController.createSlot}
		>Add Projector</button
	>
	<button class="link-button" on:click={downloadSnapshot}>Download Snapshot <DownloadIcon /></button
	>
	<div class="input-container">
		<span class="label">Hide Snapshots</span>
		<ToggleSwitch bind:isActive={doHideSnapshots} />
	</div>
</div>

<style>
	.group-editor-actions {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	#add-slot-button {
		/* color: var(--color-main); */
		margin-bottom: 1rem;
	}

	.group-editor-actions button.link-button {
		padding: 0;
		width: fit-content;
		transform: unset;
		text-decoration: none;
	}

	button.link-button :global(svg) {
		height: 1rem;
		width: 1rem;
		margin-left: 0.5rem;
	}

	button.link-button :global(.svg-fill) {
		fill: var(--color-text-light);
	}

	button.link-button:hover :global(.svg-fill),
	button.link-button:focus-visible :global(.svg-fill) {
		fill: var(--color-main);
	}

	.input-container {
		width: 100%;
		justify-content: space-between;
	}

	.input-container :global(.toggle-switch) {
		margin-left: 0.5rem;
	}
</style>
