<script lang="ts">
	import { Calendar, type EventClickArg } from "@fullcalendar/core"
	import dayGridPlugin from "@fullcalendar/daygrid"
	import timeGridPlugin from "@fullcalendar/timegrid"
	import listPlugin from "@fullcalendar/list"
	import interactionPlugin from "@fullcalendar/interaction"
	import rrulePlugin from "@fullcalendar/rrule"

	import {
		DataHandlerLightshow,
		DataHandlerScene,
		type FullCalendarEvent,
		type Lightshow,
		type Scene,
	} from "luxedo-data"
	import { ScheduleController } from "./ScheduleController"
	import { initContextMenu } from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../modules/ctx-menu/ContextMenuOptions"
	import { openShowOverview } from "../../reusable/overlays"

	let calendarInstance: Calendar
	let calendarElem: HTMLDivElement

	ScheduleController.subscribe((ctx) => {
		if (!ctx.isScheduling) calendarInstance?.changeView("dayGridMonth")
		setTimeout(() => {
			calendarInstance.render()
		}, 500)
	})

	function initializeCalendar() {
		if (calendarInstance)
			return console.warn("Trying to initialize an already initialized calendar.")

		const onPreview = (e: EventClickArg) => {
			const sceneId = Number(e.event.extendedProps["projectId"])
			let scene: Scene | Lightshow =
				DataHandlerScene.get(sceneId) ?? DataHandlerLightshow.get(sceneId)
			openShowOverview(scene, e.event)
		}

		calendarInstance = new Calendar(calendarElem, {
			plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin, rrulePlugin],
			initialView: "dayGridMonth",
			headerToolbar: {
				left: "prev,title,next",
				right: "dayGridMonth,timeGridWeek,timeGridDay",
			},
			views: {
				eventFocusedView: {
					type: "timeGrid",
					slotDuration: "01:00:00",
					slotLabelInterval: "02:00:00",
					scrollTime: "08:00:00",
					eventMinHeight: 4,
					dateIncrement: { days: 1 },
				},
			},
			displayEventTime: false,
			eventDisplay: "block",
			slotEventOverlap: false,
			eventBackgroundColor: "var(--color-main)",
			nowIndicatorClassNames: "date-now",
			nowIndicator: true,
			eventClick: onPreview,
			eventDidMount: (arg) => {
				if (arg.event.extendedProps.temp) {
					arg.el.style.pointerEvents = "none"
					return
				}

				arg.el.addEventListener(
					"contextmenu",
					initContextMenu(CTXOptions.Event.createOptions(arg.event as FullCalendarEvent))
				)
			},
		})

		calendarInstance.render()

		ScheduleController.Calendar.setFullCalendarInstance(calendarInstance)
	}

	$: calendarElem && initializeCalendar()
</script>

<div id="schedule-calendar" bind:this={calendarElem}></div>

<style>
	#schedule-calendar {
		--fc-border-color: var(--color-border);
		--fc-border-radius: var(--br);
		--fc-today-bg-color: var(--color-bg-dark);
		--fc-today-color: var(--color-text-light);
		--fc-now-indicator-color: var(--color-main);
		--fc-neutral-bg-color: var(--color-bg-dark);
		height: calc(100% - 3rem);
	}

	/* #region Calendar general */

	#schedule-calendar :global(.date-now) {
		background-color: var(--color-alt);
	}

	#schedule-calendar :global(a) {
		border-bottom: 0;
	}

	#schedule-calendar :global(table) {
		border: 1px solid var(--color-main);
		border-radius: var(--br);
		overflow: hidden;
	}

	#schedule-calendar :global(table thead) {
		border-bottom: 1px solid var(--color-main);
	}

	#schedule-calendar :global(table .fc-scrollgrid-section-header > *) {
		border-bottom-width: 1px;
		border-color: var(--color-main);
	}

	/* #endregion Calendar general */
	/* #region Calendar header */

	#schedule-calendar :global(.fc-header-toolbar) {
		margin: 1rem 0 1rem 0;
	}

	#schedule-calendar :global(.fc-toolbar-chunk > div) {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	#schedule-calendar :global(.fc-toolbar-chunk > div > .fc-toolbar-title) {
		color: var(--color-main);
		margin: 0 0.5rem;
		width: 16rem;
		text-align: center;
		font-size: var(--h1);
	}

	#schedule-calendar :global(button) {
		background-color: unset;
		border: none;
		color: var(--color-text-light);
		transform: unset;
		box-shadow: none;
	}

	#schedule-calendar :global(button:hover) {
		color: var(--color-main);
	}

	#schedule-calendar :global(button.fc-button-active) {
		color: var(--color-main);
	}

	/* #endregion Calendar header */

	#schedule-calendar :global(.fc-event) {
		cursor: pointer;
	}
</style>
