<script lang="ts">
	import type { Device } from "luxedo-data"
	import ProjectorIcon from "../icons/ProjectorIcon.svelte"

	export let type: "radio" | "checkbox" = "radio"
	export let device: Device
	export let isSelected = false
	export let onSelect: (dev: Device) => void
</script>

<label
	class="device-selector"
	for="device-input-{device.id}"
	style="--color-device: {device.color};"
>
	<input
		{type}
		name="schedule-device"
		value={device.id}
		id="device-input-{device.id}"
		checked={isSelected}
		on:input={() => onSelect(device)}
	/>
	<ProjectorIcon {device} />
	<span class="label">
		{device.name}
	</span>
</label>

<style>
	.device-selector {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.device-selector :global(svg) {
		width: 2rem;
		height: 1rem;
		margin-right: 0.5rem;
	}

	input[type="radio"] {
		height: 0.75rem;
		width: 0.75rem;
		padding: 0;
		background-color: var(--color-bg-dark);
		border: 1px solid var(--color-border);

		transition: background-color 150ms;
	}

	input[type="radio"]:checked {
		background-color: var(--color-device);
		outline-offset: 0;
	}

	label:has(input[type="radio"]:checked) {
		color: var(--color-text-light);
	}

	input[type="radio"] {
		margin-right: 0.5rem;
	}

	/* Hide the default checkbox input */
	input[type="checkbox"] {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* Style the custom checkbox */
	label {
		position: relative;
		cursor: pointer;
		user-select: none;
		width: auto;
		cursor: pointer;
		transition: background-color 150ms;
	}

	label:has(input[type="checkbox"]) {
		padding-left: 1.5rem; /* Add space for the custom checkbox */
	}

	/* Style the custom checkbox appearance */
	label:has(input[type="checkbox"]):before {
		content: "";
		position: absolute;
		left: 0rem;
		top: 0.325rem;
		width: 1rem; /* Set the width of the custom checkbox */
		height: 1rem; /* Set the height of the custom checkbox */
		border: 1px solid var(--color-border); /* Border color of the checkbox */
		border-radius: 0.25rem;
		background-color: transparent; /* Background color of the checkbox */
	}

	/* Style the custom checkmark */
	label:has(input[type="checkbox"]):after {
		content: "";
		position: absolute;
		left: 0.25rem; /* Adjust the position of the checkmark */
		top: 0.15rem; /* Adjust the position of the checkmark */
		width: 0.5rem; /* Set the width of the checkmark */
		height: 1rem; /* Set the height of the checkmark */
		border: solid var(--color-device); /* Checkmark color */
		border-width: 0 2px 2px 0; /* Style the checkmark as a 45-degree angle */
		transform: rotate(45deg); /* Rotate the checkmark to create a checkmark shape */
		opacity: 0; /* Hide the checkmark by default */
		transition: opacity 150ms ease;
	}

	/* Show the custom checkmark when the checkbox is checked */
	label:has(input[type="checkbox"]:checked):after {
		opacity: 1;
	}
</style>
