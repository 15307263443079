<script lang="ts">
	import { DataHandlerDevice, type Device } from "luxedo-data"
	import { onMount } from "svelte"
	import ProjectorOptionButton from "./ProjectorOption_Button.svelte"
	import QuickCreateButton from "../../../reusable/input/quick-select/QuickCreateButton.svelte"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import ProjectorOptionNewPlaceholder from "./ProjectorOption_NewPlaceholder.svelte"
	import { ProjectorRegistrationContext } from "../ProjectorRegistrationContext"
	import ProjectorSelectDropdown from "./ProjectorSelect_Dropdown.svelte"

	let devices: Array<Device> = []
	let device: Device
	let isCreating = false
	let isRegistering = false

	ProjectorRegistrationContext.subscribe((ctx) => {
		isRegistering = ctx.isRegistering
	})

	SelectedDeviceStore.subscribe((selectedDevice) => {
		devices = DataHandlerDevice.getMany()
		device = selectedDevice
	})

	function registerProjector() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: DataHandlerDevice.getNextUIColor(),
			type: "Cast",
		})
		SelectedDeviceStore.set(undefined)
	}

	function createNewDeviceGroup() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: undefined,
			type: "Group",
		})
		SelectedDeviceStore.set(undefined)
	}

	function createNewLuxLink() {
		ProjectorRegistrationContext.set({
			isRegistering: true,
			prevSelectedDevice: device,
			colorIndex: undefined,
			type: "LuxLink",
		})
		SelectedDeviceStore.set(undefined)
	}

	onMount(async () => {
		devices = DataHandlerDevice.getMany()

		for (const device of devices) {
			if (device._color === null) {
				device._color = DataHandlerDevice.getNextUIColor()
				await DataHandlerDevice.save(device)
			}
		}
	})
</script>

<div id="projector-select">
	<QuickCreateButton
		remWidth={9}
		options={devices.length >= 2
			? {
					"Register New Projector": registerProjector,
					"New Projector Group": createNewDeviceGroup,
					"Create LuxLink Device": createNewLuxLink,
				}
			: {
					"Register New Projector": registerProjector,
					"Create LuxLink Device": createNewLuxLink,
				}}
		bind:isCreating
		info="Add projector"
	/>
	{#if devices.length < 5}
		{#if isRegistering}
			<ProjectorOptionNewPlaceholder />
		{/if}
		{#each devices.reverse() as device (device.id)}
			<ProjectorOptionButton {device} />
		{/each}
	{:else}
		<ProjectorSelectDropdown {devices} />
		<!-- DROPDOWN -->
	{/if}
</div>

<style>
	#projector-select {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		align-items: center;
	}
</style>
