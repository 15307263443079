<script lang="ts">
	import { ToastStore } from "./ToastStore"

	export let topMargin = "calc(var(--header-height) + 0.5rem)"
	export let errorBackground: string = undefined
	export let errorColor: string = undefined

	let textContent
	let isOpen
	let type = "text"

	let transform = 200

	ToastStore.subscribe((v) => {
		isOpen = v.isOpen
		if (v.isOpen) {
			transform = 0
			textContent = v.textContent
			type = v.type
		} else transform = 200
	})
</script>

<div
	class="Toast {type}"
	style={`transform: translateX(${transform}%); top: ${topMargin}; ${
		type === "error" && errorColor ? `color: ${errorColor};` : ""
	} ${type === "error" && errorBackground ? `background-color: ${errorBackground};` : ""}`}
>
	<button class="close-toast-button icon" on:click={() => ToastStore.set({ isOpen: false })} />
	{#if type === "error"}
		<span class="type-indicator"> ⚠ </span>
	{:else if type === "success"}
		<span class="type-indicator">✓</span>
	{/if}
	{textContent}
</div>

<style>
	.Toast {
		z-index: 120;
		user-select: none;
		font-size: var(--text-small);
		position: fixed;
		border-radius: var(--br);
		color: var(--color-text-light);
		right: 1rem;
		background-color: var(--color-bg-dark);
		box-shadow: var(--shadow-medium);
		width: 16rem;
		padding: 0.5rem 2rem 0.5rem 0.75rem;
		background-color: var(--color-bg-dark);
		transition: transform var(--transition-duration) ease-in;
	}

	.type-indicator {
		margin-right: 0.5rem;
		float: left;
	}

	.Toast.error {
		/* color: var(--red);
		background-color: var(--color-bg); */
	}

	.Toast.success {
		color: var(--green);
		background-color: var(--color-bg-dark);
	}

	.close-toast-button {
		z-index: 20;
		padding: 0.5rem;
		position: absolute;
		top: 0;
		right: 0;
		transform: unset;
	}

	.close-toast-button::after {
		content: "\00d7";
		font-family: var(--heading-font);
		font-size: var(--text-h5);
	}

	.Toast.error .close-toast-button {
		color: var(--color-text);
	}

	.Toast.success .close-toast-button:hover,
	.Toast.success .close-toast-button:focus-visible {
		color: var(--light);
	}
</style>
