<script lang="ts">
	import { DataHandlerMedia, type Media } from "luxedo-data"
	import { Toast } from "svelte-comps/toaster"
	import { TileLabel } from "svelte-comps/luxedo"
	import { DragController } from "../../../../stores/DragContext"
	import {
		type ContextMenuOptions,
		triggerContextMenuWithGenerator,
	} from "svelte-comps/context-menu"
	import { CTXOptions } from "../../../../modules/ctx-menu/ContextMenuOptions"
	import LoadingSpinner from "../../loaders/LoadingSpinner.svelte"

	export let media: Media

	let isDragging = false

	let hasInitialized = false
	let thumbnailURL = undefined

	let triggerRename: () => void

	const generateCtxMenuOpts = (): ContextMenuOptions => {
		return CTXOptions.Media.createOptions(media)
	}

	async function onRename(newName: string) {
		try {
			await DataHandlerMedia.rename(media, newName)
			Toast.success("Media renamed.")
		} catch (e) {
			console.error("[ERROR] ", e)

			Toast.error("There was an error renaming this media, please refresh and try again.")
		}
	}

	function onDragStart() {
		isDragging = true
	}

	function onDragEnd() {
		isDragging = false
	}

	async function updateThumbnail(repeatIndex = 0) {
		console.trace(`getting thumbnail id=${media.id} repeatInterval=${repeatIndex}`)
		hasInitialized = true

		const retryLimit = media.fileType === "video" ? 10 : 4
		if (repeatIndex > retryLimit)
			return console.error(`Could not get thumbnail for <${media.name}|${media.id}>`)

		const res = await fetch(media.src.thumbnail, {
			mode: "cors",
		})

		if (res.status === 403) {
			setTimeout(() => {
				updateThumbnail(repeatIndex + 1)
			}, 2000)
			return
		}

		thumbnailURL = media.src.thumbnail
	}

	// $: media && !hasInitialized && updateThumbnail()
</script>

<div>
	<div
		class="tile-container {isDragging ? 'is-dragging' : ''}"
		role="application"
		on:click={triggerContextMenuWithGenerator(generateCtxMenuOpts)}
		on:contextmenu={triggerContextMenuWithGenerator(generateCtxMenuOpts)}
		on:dragend={DragController.onDragEnd(onDragEnd)}
		on:dragstart={DragController.onDragStart(media, onDragStart)}
		on:touchstart={DragController.onDragStart(media, onDragStart)}
		draggable={true}
	>
		<div class="tile-icon-container">
			<div class="tile">
				<!-- {#if thumbnailURL} -->
				<img src={media.src.thumbnail} alt="{media.name} Thumbnail" />
				<!-- {:else} -->
				<!-- <LoadingSpinner height="4rem" color="var(--color-main)" /> -->
				<!-- {/if} -->
			</div>
		</div>
		<div class="tile-label-container">
			<TileLabel file={media} {onRename} bind:triggerRename />
		</div>
	</div>
</div>

<style>
	.tile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>
