<script lang="ts">
	import {
		DataHandlerDevice,
		DataHandlerSnapshot,
		type Calibration,
		type Snapshot,
	} from "luxedo-data"
	import CalibrationSnapshots from "./CalibrationSnapshots.svelte"
	import CalibrationMasks from "./CalibrationMasks.svelte"
	import { DeviceSnapshotManager } from "../../../../../../modules/device-operation-managers/DeviceSnapshotManager"

	export let calibration: Calibration
	let snapshots: Array<Snapshot>

	DeviceSnapshotManager.subscribe(() => {
		setTimeout(() => {
			snapshots = DataHandlerSnapshot.filterByCalibration(calibration.id)
		})
	})

	$: snapshots = DataHandlerSnapshot.filterByCalibration(calibration.id)
</script>

<div class="cal-entry" id="cal-entry-{calibration.id}">
	<div class="flex-row">
		<h3>
			{calibration.runTime.toFormat("DDD")} at {calibration.runTime.toFormat("hh:mm a")}
		</h3>
	</div>
	<CalibrationSnapshots {snapshots} device={DataHandlerDevice.get(calibration.dev_id)} />
	{#if snapshots.length}
		<CalibrationMasks {calibration} />
	{/if}
</div>

<style>
	h3 {
		font-size: var(--h1);
		color: var(--color-text);
		margin: 0;
		position: sticky;
		top: -1px;
		padding: 1rem 0 1rem 0;
		background-color: var(--color-bg-dark);
		z-index: 1;
	}

	.flex-row {
		align-items: center;
		justify-content: space-between;
	}
</style>
