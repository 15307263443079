import { Device, DeviceConstructor, type DeviceRawData } from "../Device"

export type DeviceAdminRawData = DeviceRawData & {
	password: string
	tracked_cal_ids: Array<number>
	snapshot_urls: Array<string>
}

export interface DeviceAdmin extends Device<DeviceAdminRawData> {
	password: string
	trackedCalIDs: Array<number>
	snapshots: Array<string>
}

export function buildDeviceAdmin(rawData: DeviceAdminRawData) {
	const device = new DeviceConstructor(rawData) as any as DeviceAdmin
	device.password = rawData.password
	device.trackedCalIDs = rawData.tracked_cal_ids
	device.snapshots = rawData.snapshot_urls
	return device
}

export type DeviceAdminConstructor = new (rawData: DeviceAdminRawData) => DeviceAdmin
export const DeviceAdmin = buildDeviceAdmin as any as DeviceAdminConstructor
