import { writable, type Writable } from "svelte/store"
import AccountGeneral from "./Account_General.svelte"
import AccountLuxLink from "./subscription-management/luxlink/Account_LuxLink.svelte"
import AccountStorage from "./subscription-management/storage/Account_Storage.svelte"
import { openOverlay } from "svelte-comps/overlay"
import AccountOverlay from "./AccountOverlay.svelte"

export const ACCOUNT_ROUTES = {
	General: AccountGeneral,
	LuxLink: AccountLuxLink,
	Storage: AccountStorage,
}

type AccountNavRoute = keyof typeof ACCOUNT_ROUTES
type AccountNavProps_Subcription = {
	openSubscription: boolean
}

type AccountNavCtx = {
	route: AccountNavRoute
	props?: AccountNavProps_Subcription
}

class NavigationContext {
	private _store: Writable<AccountNavCtx>

	constructor() {
		this._store = writable<AccountNavCtx>({
			route: "General",
		})
	}

	navigate(route: AccountNavRoute | string, openToSubscription?: boolean) {
		this._store.set({
			route: route as AccountNavRoute,
			props: {
				openSubscription: openToSubscription ?? false,
			},
		})
	}

	subscribe(updater: (ctx: AccountNavCtx) => void) {
		return this._store.subscribe(updater)
	}

	open() {
		this.navigate("General")
		openOverlay(AccountOverlay, { classOverlay: "no-padding no-min" })
	}

	openSubscription() {
		this._store.update((ctx) => {
			return {
				...ctx,
				props: {
					openSubscription: true,
				},
			}
		})
	}

	closeSubscription() {
		this._store.update((ctx) => {
			return {
				...ctx,
				props: {
					openSubscription: false,
				},
			}
		})
	}

	openLuxLinkSubscription() {
		this.navigate("LuxLink", true)
	}

	openStorageSubscription() {
		this.navigate("Storage", true)
	}
}

export const AccountNavigator = new NavigationContext()
