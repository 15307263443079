import { DataHandlerDevice, Device, DeviceRPi } from "luxedo-data"
import { openUpdateInformerOverlay } from "../../comps/reusable/overlays/device-update"

export namespace DeviceUpdateManager {
	let deviceList: Array<number>
	let deviceUpdateIdMap: { [index: number]: string } = {}
	let informedMap: { [index: number]: boolean } = {}

	function onDeviceUpdate(device: Device) {
		if (!device.isOnline) return

		if (device.isUpdateAvailable()) {
			if (!informedMap[device.id]) openUpdateInformerOverlay(device, (device as DeviceRPi).availableUpdate)
			informedMap[device.id] = true
		}

		const updateId = deviceUpdateIdMap[device.id]
		if (updateId) device.removeUpdateListener(updateId)
	}

	function convertDevicesToIdArray(devices: Array<Device>) {
		return devices.map((dev) => dev.id).sort((a, b) => a - b)
	}

	function clearListeners() {
		for (const [devId, updateId] of Object.entries(deviceUpdateIdMap)) {
			DataHandlerDevice.get(Number(devId))?.removeUpdateListener(updateId)
		}
	}

	export function initialize() {
		const allDevices = DataHandlerDevice.getMany()
		deviceList = convertDevicesToIdArray(allDevices)

		for (const device of allDevices) {
			let updateId = device.addUpdateListener(onDeviceUpdate)
			deviceUpdateIdMap[device.id] = updateId
		}
	}

	DataHandlerDevice.addListener(() => {
		if (!deviceList || deviceList.length === 0) return

		const userDevices = DataHandlerDevice.getMany()
		const newDeviceList = convertDevicesToIdArray(userDevices)
		if (!compareArraysAreEqual(newDeviceList, deviceList)) {
			clearListeners()
			initialize()
		}
	})
}

function compareArraysAreEqual(listA: Array<number>, listB: Array<number>) {
	return JSON.stringify(listA.sort()) === JSON.stringify(listB.sort())
}
