<script lang="ts">
	import CalibrationOverlayAnimation from "./CalibrationOverlay_Animation.svelte"
	import { ProgressBar } from "svelte-comps/progress-bar"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"

	let progress = 0
	let calibrationMessage

	let unsubscriber = CalibrationOverlayController.subscribe((ctx) => {
		progress = ctx.progress
		calibrationMessage = ctx.message
	})

	function cancelCalibration() {
		unsubscriber()
		CalibrationOverlayController.close()
	}

	function getCalibrationText(message: string) {
		switch (message) {
			case "Fine-tuning Camera Settings...":
				return `<span class="warn">This step may take up to 10 minutes while your device calculates the best camera settings for your environment.</span>`
			default:
				return `Calibration is the process of shining patterns from a projector in one location, and taking images from a camera in another location, then using mathematical analysis to map the images accurately to the projector.`
		}
	}
</script>

<div class="calibration-top">
	<CalibrationOverlayAnimation />
	<ProgressBar width="calc(100% - 8rem)" percentage={progress} />
</div>
<div class="calibration-bottom">
	<h3>{calibrationMessage ? calibrationMessage : "Initializing..."}</h3>
	<p>
		{@html getCalibrationText(calibrationMessage)}
	</p>
</div>

<div class="button-container">
	<button class="link-button" on:click={cancelCalibration}> Cancel </button>
	<!-- TEMP BUTTON -->
	<!-- <button on:click={() => CalibrationController.openTroubleshooter()}>TROUBLESHOOTING</button> -->
</div>

<style>
	.calibration-bottom :global(.warn) {
		color: var(--color-main);
	}
</style>
