<script lang="ts">
	import { onDestroy } from "svelte"
	import CalibrationCommonIssues from "./CalibrationTroubleshooter_Issues.svelte"
	import CalibrationTroubleshooterRetry from "./CalibrationTroubleshooter_Retry.svelte"
	import InstructionSteps from "../../InstructionSteps.svelte"
	import ImageViewer from "../../ImageViewer.svelte"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"

	let images: Array<string>

	let unsubscribe = CalibrationOverlayController.subscribe((ctx) => {
		images = ctx.failInfo
	})

	let stepIndex = 0
	let disableNext = false
	let retry_disableNext = false

	const troubleshootingSteps: { [index: number]: { title: string; description: string } } = {
		0: {
			title: "View Calibration Images",
			description: "View each calibration image, checking for any movement during calibration.",
		},
		1: {
			title: "Check for Issues",
			description: "After viewing each issue, check if you are experiencing a common problem.",
		},
		2: {
			title: "Adjust and Retry",
			description: "Make the recommended adjustments and try again.",
		},
	}

	function handleBack() {
		stepIndex--
	}

	function handleNext() {
		if (stepIndex === Object.values(troubleshootingSteps).length - 1) {
			CalibrationOverlayController.retry()
		} else {
			stepIndex++
		}

		disableNext = true
		setTimeout(() => {
			disableNext = false
		}, 2500)
	}

	onDestroy(unsubscribe)
</script>

<div id="calibration-troubleshooter">
	<div class="calibration-left">
		<h3>Troubleshooting Guide</h3>
		<InstructionSteps
			steps={troubleshootingSteps}
			index={stepIndex}
			onStepClick={(step) => (stepIndex = step)}
		/>
	</div>
	<div class="calibration-right">
		{#if stepIndex === 0}
			<ImageViewer {images} />
		{:else if stepIndex === 1}
			<CalibrationCommonIssues />
		{:else if stepIndex === 2}
			<CalibrationTroubleshooterRetry />
		{/if}
	</div>
</div>
<div class="button-container">
	<button class="link-button" on:click={() => CalibrationOverlayController.close()}>Cancel</button>
	{#if stepIndex > 0}
		<button class="alt" on:click={handleBack}>Back</button>
	{/if}
	<button on:click={handleNext} disabled={disableNext || retry_disableNext}
		>{stepIndex === Object.values(troubleshootingSteps).length - 1 ? "Retry" : "Next"}</button
	>
</div>

<style>
	h3 {
		/* font-size: var(--h1); */
		margin: 0 0 1rem 0;
	}

	#calibration-troubleshooter {
		overflow: hidden;
		display: flex;
		width: calc(80vw - 6rem);
		height: calc(80vh - 7rem);
	}

	.calibration-right,
	.calibration-left {
		flex-basis: 50%;
		min-width: 25rem;
	}

	.calibration-left {
		height: 100%;
	}

	.calibration-right {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	button.alt {
		margin-right: 1rem;
	}
</style>
