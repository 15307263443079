<script lang="ts">
	import { tooltip } from "../tooltip"

	export let options: Array<string>
	export let selectedOption: string = undefined
	export let optionInfo: { [index: string]: string } = {}

	export let fontSize: string = undefined
</script>

<div class="text-row-selector">
	{#each options as option}
		<button
			class="link-button {selectedOption === option ? 'selected' : ''}"
			style={fontSize ? `font-size: ${fontSize};` : ""}
			on:click={() => (selectedOption = option)}
			use:tooltip={{ content: optionInfo[option], pointing: "top" }}>{option}</button
		>

	{/each}
</div>

<style>
	.text-row-selector {
		display: flex;
		flex-direction: row;
	}

	.text-row-selector :global(.info-dialogue-button) {
		margin: 0;
		margin-top: 0.25rem;
		margin-left: -0.75rem;
	}

	button.link-button {
		transform: none;
		font-size: var(--h3);
		padding: 0 0.5rem;
		text-decoration: none;
		color: var(--color-text);
		border-radius: 0;
	}

	button.link-button:hover,
	button.link-button:focus-visible {
		color: var(--color-main);
	}

	button.link-button.selected {
		color: var(--color-text-light);
	}

	button.link-button {
		border-right: 1px solid var(--color-border);
	}

	button.link-button:last-of-type {
		border: none;
	}

	button:first-of-type {
		padding-left: 0;
	}

	button:last-of-type {
		/* padding-right: 0; */
	}
</style>
