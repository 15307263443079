<script lang="ts">
	import type { DeviceGroupSlot } from "luxedo-data"
	import GroupCanvasSlotInstance from "./GroupCanvas_SlotInstance.svelte"
	import { GroupCanvasController } from "./GroupCanvasController"
	import { GroupEditorController } from "../GroupEditorController"
	import { GroupOverlapController } from "../steps/blend/GroupOverlapController"
	import GroupCanvasOverlapInstance from "./GroupCanvas_OverlapInstance.svelte"
	import ExpandIcon from "../../../icons/ExpandIcon.svelte"

	export let slots: Array<DeviceGroupSlot>
	export let slotOverlaps: Array<GroupOverlapController.OverlapInstance> = []

	export let onSlotClick: (slotID: number) => void = undefined
	export let onOverlapClick: (overlapID: string) => void = undefined

	let canvasElem: HTMLDivElement
	let zoomLevel: number
	let isExpanded: boolean

	GroupCanvasController.subscribe((ctx) => {
		zoomLevel = ctx.zoomLevel
		isExpanded = ctx.isExpanded
	})

	function handleScroll(
		e: WheelEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		if (e.deltaY < 0) {
			GroupCanvasController.zoomIn()
		} else if (e.deltaY > 0) {
			GroupCanvasController.zoomOut()
		}
	}

	function handleMouseDown(
		e: MouseEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.button !== 2) return
		e.preventDefault()

		const startPos = {
			x: e.pageX,
			y: e.pageY,
			left: e.currentTarget.scrollLeft,
			top: e.currentTarget.scrollTop,
		}

		const mouseUpHandler = () => {
			document.removeEventListener("mousemove", mouseMoveHandler)
			document.removeEventListener("mouseup", mouseUpHandler)
		}

		const mouseMoveHandler = (e: MouseEvent) => {
			const diffX = e.pageX - startPos.x
			const diffY = e.pageY - startPos.y

			canvasElem.scrollTo(startPos.left - diffX, startPos.top - diffY)
		}

		document.addEventListener("mousemove", mouseMoveHandler)
		document.addEventListener("mouseup", mouseUpHandler)
	}

	function handleKeyEvents(e: KeyboardEvent) {
		if (e.key === "Escape") {
			GroupEditorController.Slots.deselectSlot()
			GroupOverlapController.selectOverlap(undefined)
			e.stopPropagation()
		}
	}

	$: GroupCanvasController.setCanvasElement(canvasElem)
</script>

<div
	id="device-group-canvas"
	bind:this={canvasElem}
	on:contextmenu|preventDefault
	on:contextmenu|preventDefault
	on:mousedown={handleMouseDown}
	on:wheel|preventDefault={handleScroll}
>
	{#if slots}
		<div id="editor-canvas" style="transform: scale({zoomLevel});">
			{#each slots as slot (slot.id)}
				{#if !slot.deleted}
					<GroupCanvasSlotInstance
						{slot}
						onClick={onSlotClick ? () => onSlotClick(slot.id) : null}
					/>
				{/if}
			{/each}
			{#if slotOverlaps}
				{#each slotOverlaps as overlap (overlap.id)}
					<GroupCanvasOverlapInstance
						onClick={() => onOverlapClick(overlap.id)}
						overlapInstance={overlap}
					/>
				{/each}
			{/if}
		</div>
	{/if}
	<button
		id="expand-canvas-button"
		class="small icon"
		title={isExpanded ? "Shrink Canvas" : "Expand Canvas"}
		on:click={() => GroupCanvasController.toggleCanvasExpand()}
	>
		<ExpandIcon />
	</button>
</div>
<svelte:document on:keydown={handleKeyEvents} />

<style>
	#device-group-canvas {
		width: 100%;
		height: 100%;
		border: 1px dashed var(--color-border);
		background-color: var(--color-bg-dark);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: scroll;
		padding: 1rem;
		transition: width 500ms;
	}

	#device-group-canvas::-webkit-scrollbar {
		display: none;
	}

	#editor-canvas {
		transform-origin: 0% 0% 0px;
		min-height: 100%;
		min-width: 100%;
		transition: transform 0.5s;
	}

	#expand-canvas-button {
		position: absolute;
		top: 1.5rem;
		right: 4rem;
	}

	#expand-canvas-button :global(.svg-stroke) {
		stroke: var(--color-text-light);
	}

	#expand-canvas-button:hover :global(.svg-stroke),
	#expand-canvas-button:focus-visible :global(.svg-stroke) {
		stroke: var(--color-main);
	}
</style>
