<script lang="ts">
	import { TimetableEvent, type FullCalendarEvent, type EventOccurrence } from "luxedo-data"
	import ContextMenuButton from "../../../../reusable/input/ContextMenuButton.svelte"
	import { triggerContextMenuWithGenerator } from "svelte-comps/context-menu"
	import { ScheduleController } from "../../../schedule/ScheduleController"
	import { CTXOptions } from "../../../../../modules/ctx-menu/ContextMenuOptions"
	import { openShowOverview } from "../../../../reusable/overlays"

	export let occurrence: EventOccurrence
	let event: TimetableEvent
	let fullCalendarEvent: FullCalendarEvent

	async function getEventData(occurrence: EventOccurrence) {
		event = occurrence.event
		fullCalendarEvent = await ScheduleController.Calendar.getEventByEventId(event.id)
	}

	$: getEventData(occurrence)
</script>

<div class="upcoming-event-instance">
	{#if event}
		<div class="data">
			<span class="date">{occurrence.start.toFormat("DDD 'at' t")}</span>
			<a class="name" href="#" on:click={() => openShowOverview(event.show, fullCalendarEvent)}
				>{event.name}</a
			>
		</div>
		{#if fullCalendarEvent}
			<ContextMenuButton
				onClick={triggerContextMenuWithGenerator(CTXOptions.Event.createOptions, [
					fullCalendarEvent,
				])}
			/>
		{/if}
	{/if}
</div>

<style>
	.upcoming-event-instance {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 1rem;
		background-color: var(--color-bg);
		padding: 0.5rem;
		border-radius: var(--br);
	}

	.data {
		display: flex;
		flex-direction: column;
	}

	.date {
		color: var(--color-text);
	}

	div.upcoming-event-instance a.name {
		color: var(--color-text-light);
		font-size: var(--h1);
		margin: 0;
		line-height: 1.3;

		border: none;
	}

	div.upcoming-event-instance a.name:hover,
	div.upcoming-event-instance a.name:focus-visible {
		color: var(--color-main);
	}
</style>
