<script lang="ts">
	import Guides from "luxedo-data/src/data/Guides"

	// This step is for a new device - has not been set up and is offline
</script>

<h1>Registering Your Device</h1>
<p>Before getting started with Luxedo, you will need to register your device.</p>
<p class="info">
	To register your device, simply enter the device ID and password found on the "Getting Started
	with you Luxedo Projection System" page, name your device and click "Register".
	<br />
	If you do not have or need an additional Luxedo device, you can purchase one
	<a href="https://www.luxedo.com/products/luxedo-projection-system">here</a>.
</p>
<!-- <p class="help">
	Having trouble? Follow <a href={Guides["Device"]["Getting Started with v3"]}>this guide</a>
	for help troubleshooting.
</p> -->
<p class="help">
	Having trouble? Follow <a href={Guides["Luxedo Portal"]["Registering a Luxedo Device"]}
		>this guide</a
	>
	for a walkthrough or checkout out our
	<a href="https://www.luxedo.com/pages/help">help center</a> for assistance.
</p>

<style>
</style>
