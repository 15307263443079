<script lang="ts">
	import { DataHandlerCalibration, DataHandlerSnapshot, type Snapshot } from "luxedo-data"
	import { LuxedoRPC } from "luxedo-rpc"
	import LoadingSpinner from "../../loaders/LoadingSpinner.svelte"
	import { onMount } from "svelte"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"

	let snapshot: Snapshot
	CalibrationOverlayController.subscribe((ctx) => {
		snapshot = ctx.snapshot
	})

	async function calibrationFinish() {
		await LuxedoRPC.api.device.set_default_snap(snapshot.dev_id, snapshot.id)
		CalibrationOverlayController.close()
	}

	onMount(() => DataHandlerCalibration.pull())
</script>

<div class="calibration-top">
	{#if !snapshot}
		<LoadingSpinner height="80%" />
	{:else}
		<img src={snapshot.src} alt="" />
	{/if}
</div>
<div class="calibration-bottom">
	<h3>Calibration Complete</h3>
	<p>
		This snapshot was dynamically generated based on the results of calibration and is expected to
		appear a bit distorted. If, however, it is so distorted you cannot tell the edges of your
		projection space apart, we recommend recalibrating after checking out <a
			href="https://www.youtube.com/watch?v=qG5Lu2bur8Q&ab_channel=LuxedoProjections">this guide</a
		>.
	</p>
</div>
<div class="button-container">
	<button class="link-button" on:click={() => CalibrationOverlayController.userUnsatisfied()}
		>Unsatisfied?</button
	>
	<button on:click={calibrationFinish}>Finish</button>
</div>

<style>
	img {
		background-color: var(--color-bg-dark);
		border-radius: var(--br);
		overflow: hidden;
		height: fit-content;
		width: fit-content;
		margin-bottom: 1rem;
	}

	a {
		border-color: var(--color-alt);
		transition:
			color 250ms,
			border-color 250ms;
	}

	a:hover {
		color: var(--color-text-light);
		border-color: var(--color-main);
	}
</style>
