<script lang="ts">
	import { onDestroy, onMount } from "svelte"
	import DeviceFilter from "../../reusable/device-filter/DeviceFilter.svelte"
	import ScheduleCalendar from "./ScheduleCalendar.svelte"
	import { ScheduleController } from "./ScheduleController"
	import ScheduleInputs from "./schedule-inputs/ScheduleInputs.svelte"
	import {
		DataHandlerDevice,
		DataHandlerLightshow,
		DataHandlerScene,
		type Device,
	} from "luxedo-data"

	let isEditingEvent: boolean = false
	let filterDevice: Device = undefined

	const unsubscribe = ScheduleController.subscribe((ctx) => {
		isEditingEvent = ctx.isScheduling

		if (ctx.filterDevice !== filterDevice) {
			filterDevice = ctx.filterDevice
			setTimeout(() => ScheduleController.Calendar.refreshEvents({ deviceFilter: filterDevice }))
		}
	})

	function scheduleEvent() {
		ScheduleController.EventEditor.editEvent(undefined, { device: filterDevice })
	}

	function loadUrlParams() {
		const urlParams = new URLSearchParams(window.location.search)
		const deviceId = Number(urlParams.get("device"))
		const projectId = Number(urlParams.get("project"))

		const device = DataHandlerDevice.get(deviceId)
		const project = DataHandlerScene.get(projectId) ?? DataHandlerLightshow.get(projectId)
		if (device || project)
			ScheduleController.EventEditor.editEvent(undefined, {
				show: project,
				device: device,
			})
	}

	onMount(() => {
		ScheduleController.selectDeviceFilter(undefined)
		loadUrlParams()
	})

	onDestroy(unsubscribe)
</script>

<div class="schedule-container">
	<div class="schedule-left">
		<div class="schedule-heading">
			<h1>My Schedule</h1>
			{#if !isEditingEvent}
				<button class="outline-button" on:click={scheduleEvent}>Schedule New Event</button>
			{/if}
		</div>
		<ScheduleCalendar />
	</div>
	<div class="schedule-right">
		{#if isEditingEvent}
			<ScheduleInputs />
		{:else if DataHandlerDevice.getMany().length}
			<DeviceFilter
				selectedDevice={filterDevice}
				onSetDeviceFilter={ScheduleController.selectDeviceFilter}
			/>
		{/if}
	</div>
</div>

<style>
	.schedule-container {
		display: flex;
		flex-direction: row;
		height: calc(100vh - var(--header-height));
		width: 100%;
	}

	.schedule-heading {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.schedule-left {
		margin-right: 1rem;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.schedule-right {
		height: calc(100% - 1rem);
		display: flex;
		flex-direction: column;
		width: 14rem;
		transition: width 500ms;
	}

	.schedule-right:empty {
		display: none;
	}

	.schedule-right:global(:has(#schedule-inputs)) {
		width: 40%;
	}

	h1 {
		font-size: var(--h1);
		margin: 0;
		color: var(--color-text-light);
	}
</style>
