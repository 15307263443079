<script lang="ts">
	import type { DeviceGroup, DeviceGroupSlot } from "luxedo-data"
	import ProjectorGroupEditorSlot from "./GroupEditorAdjuster_SlotInstance.svelte"
	import { GroupEditorController } from "../DeviceGroupEditorController"

	// #region  Group & Slot Props

	let group: DeviceGroup
	let slots: Array<DeviceGroupSlot>
	let selectedSlot: DeviceGroupSlot

	GroupEditorController.subscribe((ctx) => {
		selectedSlot = ctx.selectedSlot
		group = ctx.group
		slots = ctx.slots
	})

	// #endregion Group & Slot Props
	// #region View Options & Operations

	let zoomLevel: number = 1
	let isHidingInputs: boolean
	let viewportElem: HTMLDivElement

	GroupEditorController.View.subscribe((ctx) => {
		zoomLevel = ctx.zoomLevel
		isHidingInputs = ctx.shrinkInputs
	})

	function handleScroll(
		e: WheelEvent & {
			currentTarget: EventTarget & HTMLDivElement
		}
	) {
		if (e.deltaY < 0) {
			GroupEditorController.View.zoomIn()
		} else if (e.deltaY > 0) {
			GroupEditorController.View.zoomOut()
		}
	}

	function handleMouseDown(
		e: MouseEvent & {
			currentTarget: HTMLDivElement
		}
	) {
		if (e.button !== 2) return
		e.preventDefault()

		const startPos = {
			x: e.pageX,
			y: e.pageY,
			left: e.currentTarget.scrollLeft,
			top: e.currentTarget.scrollTop,
		}

		const mouseUpHandler = () => {
			document.removeEventListener("mousemove", mouseMoveHandler)
			document.removeEventListener("mouseup", mouseUpHandler)
		}

		const mouseMoveHandler = (e: MouseEvent) => {
			const diffX = e.pageX - startPos.x
			const diffY = e.pageY - startPos.y

			viewportElem.scrollTo(startPos.left - diffX, startPos.top - diffY)
		}

		document.addEventListener("mousemove", mouseMoveHandler)
		document.addEventListener("mouseup", mouseUpHandler)
	}

	$: GroupEditorController.View.setViewportElement(viewportElem)

	// #endregion View Options & Operations
</script>

<div
	id="group-editor"
	class={isHidingInputs ? "expand" : ""}
	on:contextmenu|preventDefault
	on:mousedown={handleMouseDown}
	on:wheel|preventDefault={handleScroll}
	bind:this={viewportElem}
>
	{#if slots}
		<div id="editor-canvas" style="transform: scale({zoomLevel})">
			{#each slots as slot}
				{#if !slot.deleted}
					<ProjectorGroupEditorSlot {slot} />
				{/if}
			{/each}
		</div>
	{/if}
</div>

<style>
	#group-editor {
		flex-grow: 1;
		border: 1px dashed var(--color-border);
		background-color: var(--color-bg);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: scroll;
		padding: 1rem;
		width: 60%;
		transition: width 500ms;
	}

	#group-editor::-webkit-scrollbar {
		display: none;
	}

	#group-editor.expand {
		width: 100%;
	}

	#editor-canvas {
		transform-origin: 0% 0% 0px;
		min-height: 100%;
		min-width: 100%;
		transition: transform 0.5s;
	}
</style>
