<script lang="ts">
	import { DataHandlerScene, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { ScheduleController } from "../../schedule/ScheduleController"
	import Guides from "luxedo-data/src/data/Guides"

	let device: Device
	let hasRenderedScene: boolean = false
	SelectedDeviceStore.subscribe((dev) => (device = dev))

	function updateShows() {
		const allRenderedShows = DataHandlerScene.getMany().filter((scene) => scene.render_ver)
		if (allRenderedShows.length) hasRenderedScene = true
		else hasRenderedScene = false
	}

	$: device && updateShows()
</script>

<div class="next-steps-heading">
	<h1>Scheduling</h1>
</div>
<p>
	Now that your device has been calibrated and your first show has been created, you can schedule
	your first event!
</p>
<div class="info">
	Scheduling allows you to tell your projector to play any of your rendered Scenes or Lightshows at
	any time. All of your scheduled events will show up in the Schedule page, where you can manage
	existing events or schedule new ones.
</div>
{#if hasRenderedScene}
	<button
		class="outline-button"
		on:click={() =>
			ScheduleController.EventEditor.editEvent(undefined, {
				device,
			})}>Schedule First Event</button
	>
{:else}
	<p class="warn">
		⚠ None of your scenes have been rendered. You will be unable to schedule a show which has not
		been rendered.
	</p>
{/if}
<p class="help">
	Having trouble? Read <a href={Guides["Luxedo Portal"]["Scheduling a Scene or Lightshow"]}
		>this guide</a
	> for a walkthrough.
</p>

<style>
</style>
