<script lang="ts">
	import { Guides, type ImageCaptureConfig } from "luxedo-data"
	import { ArrowDown, ArrowForward } from "svelte-comps/icons"

	import { ToggleSwitch } from "svelte-comps/inputs"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"

	export let disableNext: boolean = false

	let isAdvancedOptionsOpen = false
	let brightnessLevel = 0
	let brightnessError: string

	let opts: Partial<ImageCaptureConfig> = {}
	let useOldCalInput: boolean = false

	function onLightLevelUpdate(
		e: Event & {
			currentTarget: EventTarget & HTMLSelectElement
		}
	) {
		brightnessLevel = Number(e.currentTarget.value)
		if (brightnessLevel >= 3) {
			brightnessError =
				"Calibration does not work in broad daylight unless your projector is brighter than the sun!"
			disableNext = true
		} else if (brightnessLevel >= 2) {
			brightnessError =
				"Calibrations can struggle in bright environments. For the best result, turn off all the lights you can and move your projector as close to your target as possible."
			disableNext = false
		} else {
			brightnessError = undefined
			disableNext = false
		}
	}
	function onInputUpdate(
		type: "exposure-level" | "exposure-mode" | "image-dim" | "detail-level" | "exposure-method"
	) {
		return (
			e: Event & {
				currentTarget: HTMLSelectElement
			}
		) => {
			switch (type) {
				case "exposure-level":
					opts["exposure_range"] = 1
					opts["max_exposure"] = Number(e.currentTarget.value)
					break
				case "exposure-mode":
					opts["exposure_range"] = Number(e.currentTarget.value)
					break
				case "image-dim":
					opts["brightness"] = Number(e.currentTarget.value)
					break
				case "detail-level":
					opts["bucket_size"] = Number(e.currentTarget.value)
					break
				case "exposure-method":
					opts["stdev_mode"] = Number(e.currentTarget.value) === 1
					break
			}

			CalibrationOverlayController.Advanced.updateAdvancedOptions(opts)
		}
	}

	function onUseLegacyCalUpdate(doUse?: boolean) {
		useOldCalInput = doUse
		CalibrationOverlayController.Advanced.setDoUseAdvancedOptions(doUse)
	}
</script>

<div id="retry-container">
	<p class="heading">
		Once you have made adjustments to your setup and are ready, click "Retry" to begin a new
		calibration.
	</p>

	<div class="advanced-options-container">
		<button
			id="advanced-option-dropdown"
			class="icon small {isAdvancedOptionsOpen ? 'open' : ''}"
			on:click={() => (isAdvancedOptionsOpen = !isAdvancedOptionsOpen)}
		>
			Advanced Options
			<ArrowDown />
		</button>
		<div id="advanced-options" class={isAdvancedOptionsOpen ? "open" : "closed"}>
			<div class="toggle-switch-input">
				<span class="label"> Use Legacy Calibration: </span>
				<ToggleSwitch isActive={useOldCalInput} onUpdate={onUseLegacyCalUpdate} />
			</div>
			<span class="legacy-cal-warning cal-warning">
				⚠ Legacy calibration takes less images and produces a less detailed snapshot.
			</span>
			{#if useOldCalInput}
				<div class="select-container environment-lighting-container">
					<label for="env-light-input">Environment Lighting:</label>
					<select id="env-light-input" on:change={onLightLevelUpdate}>
						<option value="-1">--select--</option>
						<option value="0">Pitch Black</option>
						<option value="1">Neighborhood Lights</option>
						<option value="2">City Lights</option>
						<option value="3">Daylight</option>
					</select>
				</div>
				{#if brightnessError}
					<span class="bright-warn cal-warning small {brightnessLevel >= 3 ? 'error' : 'warn'}">
						⚠ {brightnessError}
					</span>
				{/if}
				<div class="select-container">
					<label for="exposure-level-input">Exposure Level:</label>
					<select id="exposure-level-input" on:change={onInputUpdate("exposure-level")}>
						<option value="-1">--select--</option>
						<option value="64">1</option>
						<option value="128">2</option>
						<option value="256">3</option>
						<option value="512">4</option>
						<option value="1024">5</option>
						<option value="2048">6</option>
					</select>
				</div>
				<div class="select-container">
					<label for="exposure-mode-input">Exposure Mode:</label>
					<select id="exposure-mode-input" on:change={onInputUpdate("exposure-mode")}>
						<option value="-1">--select--</option>
						<option value="1">Exact Value</option>
						<option value="2">Adaptive</option>
						<option value="4">Auto-Detect</option>
					</select>
				</div>
				<div class="select-container">
					<label for="image-dim-input">Image Dimming:</label>
					<select id="image-dim-input" on:change={onInputUpdate("image-dim")}>
						<option value="255">0%</option>
						<option value="230">10%</option>
						<option value="204">20%</option>
						<option value="179">30%</option>
						<option value="153">40%</option>
						<option value="128">50%</option>
					</select>
				</div>
				<div class="select-container">
					<label for="detail-level-input">Level of Detail:</label>
					<select id="detail-level-input" on:change={onInputUpdate("detail-level")}>
						<option value="-1">--select--</option>
						<option value="32">Medium</option>
						<option value="16">High</option>
						<option value="8">Very High</option>
					</select>
				</div>
				<div class="select-container">
					<label for="exposure-method-input">Exposure Method:</label>
					<select id="exposure-method-input" on:change={onInputUpdate("exposure-method")}>
						<option value="-1">--select--</option>

						<option value="1">Best Contrast</option>
						<option value="0">Best Brightness</option>
					</select>
				</div>
			{/if}
		</div>
	</div>
</div>
<div class="additional-info">
	<p>
		To learn more about calibration, check out
		<a href={Guides.default.Device["Calibrating Your Device"]}>this article</a>.
	</p>
	<p>
		If none of the common issues helped, checkout our <a
			href={Guides.default.Device["Troubleshooting Calibration"]}
			>detailed calibration troubleshooting guide</a
		> for advanced troubleshooting.
	</p>
</div>

<!-- 


environment lighting: 
-pitch black
-neighborhood lights
-city lights
-daylight

spotlight

advanced:
-exposure (1-5)
-exposure mode (exact, adaptive, auto-detect)
-image dimming (0, 10, ..., 50%)
-level of detail (medium, high, very high)
-exposure mode (best contrast, best brightness)




 -->

<style>
	#retry-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		color: var(--color-text);
	}

	.environment-lighting-container {
		line-height: 1em;
	}

	.cal-warning {
		line-height: 1em;
		display: block;
		font-size: var(--h3);
	}

	.toggle-switch-input {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 1.5rem;
	}

	.toggle-switch-input .label {
		margin-right: 1rem;
		color: var(--color-text);
	}

	.heading {
		color: var(--color-text-light);
		margin-bottom: 1rem;
		font-size: var(--h2);
	}

	p {
		margin-bottom: 0;
	}

	.additional-info a {
		color: var(--color-text);
		border-color: var(--color-alt);
		transition:
			color 250ms,
			border-color 250ms;
	}

	.additional-info a:hover {
		color: var(--color-text-light);
		border-color: var(--color-main);
	}

	.advanced-options-container {
		flex-basis: 60%;
	}

	#advanced-options {
		overflow: hidden;
		transition: height 250ms;
	}

	#advanced-options.closed {
		height: 0;
	}

	#advanced-options.open {
		height: 100%;
	}

	#advanced-option-dropdown {
		padding: 0;
		margin-bottom: 1rem;
		display: flex;
		flex-direction: row;
		font-size: var(--h1);
	}
	#advanced-option-dropdown :global(svg) {
		transition: transform 250ms;
		margin-left: 0.5rem;
		height: 2rem;
	}
	#advanced-option-dropdown.open :global(svg) {
		transform: rotateX(180deg);
	}
	.select-container label {
		color: var(--color-text);
	}

	.error {
		color: var(--ct-red);
	}

	.warn {
		color: var(--color-main);
	}

	.additional-info {
		justify-self: flex-end;
		color: var(--color-text);
	}

	.additional-info > p {
		font-size: var(--h3);
	}

	.legacy-cal-warning {
		color: var(--color-main);
		margin-bottom: 1rem;
	}
</style>
