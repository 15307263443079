import type { Device } from "luxedo-data"
import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
import { openOverlay } from "svelte-comps/overlay"
import NewSceneForm from "./NewSceneForm.svelte"

/**
 * Opens the new scene form in an overlay
 * @param options optional config block
 * @param options.device the relevant device - if passed, this device will be selected in the UI on mount
 */
export function openNewSceneOverlay(options?: { device?: Device }) {
	if (options && options.device) SelectedDeviceStore.set(options.device)

	setTimeout(() => {
		openOverlay(NewSceneForm, {
			heading: "New Scene",
			classOverlay: "new-scene-overlay",
			classHeading: "no-underline",
		})
	})
}
