<script lang="ts">
	import { DataHandlerDevice, Device } from "luxedo-data"
	import DeviceFilterInstance from "./DeviceFilter_Instance.svelte"
	import { SelectedDeviceStore } from "../../../stores/SelectedDeviceStore"

	export let selectedDevice: Device = undefined
	export let onSetDeviceFilter: (device: Device) => void = SelectedDeviceStore.set

	let projectors = DataHandlerDevice.getMany()

	DataHandlerDevice.addListener(() => {
		projectors = DataHandlerDevice.getMany()
	})
</script>

<h3>Filter by Projector</h3>
<div class="device-filter">
	{#each projectors as device}
		<DeviceFilterInstance {device} filterDevice={selectedDevice} {onSetDeviceFilter} />
	{/each}
</div>

<style>
	h3 {
		color: var(--color-text-light);
		margin: 0 1rem 1rem 0;
		font-size: var(--h1);
		text-align: center;
	}

	.device-filter {
		--head-height: calc(var(--h1) + 1rem);
		height: calc(100% - var(--head-height));
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 0.25rem;
	}
</style>
