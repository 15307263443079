<script lang="ts">
	import { onMount } from "svelte"
	import LoadingPage from "../../reusable/loaders/LoadingPage.svelte"

	onMount(window.location.reload)
</script>

<LoadingPage />

<style>
</style>
