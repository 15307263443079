import { fabric } from "fabric"
import { Asset } from "../../Asset"
import { EditorClass } from "../../../Editor"
import { ClipboardContextInfo, ClipboardMetadata, PasteLocationData } from "../../../controllers"
import { convertInstanceToObject } from "../../../modules/serialize"
import { Track, TrackGroup, TrackLike } from "../../../tracks"

/**
 * @file asset/canvas/CanvasAsset.ts
 * @author Austin Day 2023
 * @description WARNING: This file commits some *deep dark black magic fuckery* which is designed to be IGNORED AND TAKEN FOR GRANTED
 * 	If you really gotta know, just ask me in person.
 *
 **/

export interface CanvasAsset extends Asset, fabric.Object {
	AnimatableProperty: "left" | "top" | "angle" | "scaleX" | "scaleY" | "opacity"
	AnimatableProperties: this["AnimatableProperty"][]
	track: Track<CanvasAsset>
	hidden: boolean
}

@Asset.Mixin
export class CanvasAsset extends fabric.Object {
	//#region    ===========================				Construction				==============================
	static Mixin<T extends FabricConstructor | AbstractFabricConstructor>(Base: T) {
		Base.prototype[CanvasAsset.isCanvasAsset] = true

		for (const [prop, descriptor] of Object.entries(Object.getOwnPropertyDescriptors(CanvasAsset.prototype))) {
			if (prop === "constructor") continue
			if (Object.hasOwn(Base.prototype, prop)) continue

			Object.defineProperty(Base.prototype, prop, descriptor)
		}
	}

	editor: EditorClass

	//#endregion =====================================================================================================

	@setProto(["left", "top", "angle", "scaleX", "scaleY", "opacity"])
	AnimatableProperties: this["AnimatableProperty"][]

	static isCanvasAsset = Symbol("isCanvasAsset")
	static [Symbol.hasInstance](obj) {
		if (!obj) return false
		return CanvasAsset.isCanvasAsset in obj
	}

	//#region    ===========================				General Utility				==============================
	getRelativePointer(eventData: MouseEvent) {
		const mousePointer = this.canvas.getPointer(eventData) as fabric.Point
		const pt = this.toLocalPoint(mousePointer, "top", "left")
		pt.x /= this.scaleX
		pt.y /= this.scaleY
		return pt
	}

	getBaseValue(prop: this["AnimatableProperty"]): number {
		if (!this.track || !this.track.isAnimated() || !this.track.animations[prop]) return this[prop]

		return this.track.animations[prop].initialValue
	}

	//#endregion =====================================================================================================

	//#region    ===========================		  Serialization & Clipboard			==============================

	/** Default serialization method for canvas assets */

	/** Default serialization behavior */
	serialize(forExport?: boolean): Partial<this> {
		let include = []

		if ("mediaId" in this) {
			include.push("mediaId")
		}

		const serialdata = convertInstanceToObject(this, {
			propertiesToInclude: include,
			propertyGetters: {
				getBaseValue: this.AnimatableProperties,
			},
			forExport,
		})

		return serialdata
	}

	onCopy(serializedThis: Partial<this>, copyContext: ClipboardContextInfo): CanvasAssetClipboardMetadata {
		serializedThis.left += 10
		serializedThis.top += 10
		const metadata: CanvasAssetClipboardMetadata = {
			track: this.track,
			startTime: this.track.start,
			trackDuration: this.track.duration,
			trackName: this.track.name,
			fnCheckCanPaste: (origin, location) => {
				if (location?.targetGroup && location.targetGroup.contentType != "VIDEO") return false
				return true
			},
		}

		return metadata
	}

	/** Default/base behavior for canvas assets after they are pasted */
	onPaste(
		pasteContext: ClipboardContextInfo,
		location?: PasteLocationData,
		metadata?: ReturnType<this["onCopy"]>
	): this {
		location = location ?? {}

		// Choose the target trackGroup first based on what was passed in to the paste term, then on metadata from the copy, then default to top level
		let targetTrack: TrackLike = location.targetGroup ?? metadata.track ?? this.editor.tracks
		if (!(targetTrack instanceof TrackGroup)) {
			targetTrack = targetTrack.parent
		}

		// If there is a specified paste location, update the position to that
		if (location.canvasPosition) {
			this.setOptions({
				left: location.canvasPosition.x,
				top: location.canvasPosition.y,
			})
		}

		const targetGroup = targetTrack as TrackGroup

		const duration = metadata.trackDuration ?? 5
		let startTime = location.timeStart ?? this.editor.timeline.currentTime
		startTime = Math.min(startTime, this.editor.timeline.duration - duration)

		targetGroup.addAsset(this, {
			name: metadata.trackName,
			start: startTime,
			end: startTime + duration,
			index: location.targetGroup && location.trackIndex ? location.trackIndex : -1,
		})

		return this
	}

	//#endregion =====================================================================================================
}

import { bindCanvasAssetProperties } from "./properties"
bindCanvasAssetProperties()

/* Add all the base Asset functionality to CanvasAsset */
const propertyDescriptors = Object.getOwnPropertyDescriptors(Asset.prototype)
delete propertyDescriptors["constructor"]
Object.defineProperties(CanvasAsset.prototype, propertyDescriptors)

interface CanvasAssetClipboardMetadata extends ClipboardMetadata {
	track: Track<CanvasAsset>
	startTime: number
	trackDuration: number
	trackName: string
}

const CANVAS_ASSET_DEFAULTS: Partial<fabric.Object> = {
	centeredRotation: false,
	originX: "center",
	originY: "center",
}

Object.assign(CanvasAsset.prototype, CANVAS_ASSET_DEFAULTS)

type FabricConstructor = new (...args: any) => fabric.Object
type AbstractFabricConstructor = abstract new (...args: any) => fabric.Object

function setProto<T>(value: T) {
	return function <K extends string>(target: Record<K, T>, key: K) {
		target[key] = value
	}
}
