<script lang="ts">
	import { DeviceGroup, type Device } from "luxedo-data"
	import { SelectedDeviceStore } from "../../../../stores/SelectedDeviceStore"
	import { openOverlay } from "svelte-comps/overlay"
	import ProjectorGroupEditor from "../../../reusable/overlays/group-editor/ProjectorGroupEditor.svelte"
	import { openGroupEditor } from "../../../reusable/overlays/group-editor"

	let device: Device

	SelectedDeviceStore.subscribe((dev) => {
		if (!dev || !(dev instanceof DeviceGroup)) return

		device = dev
	})

	function openProjectorMenu() {
		openGroupEditor(device as DeviceGroup)
	}
</script>

<div class="next-steps-heading">
	<h1>Group Configuration</h1>
</div>
<p>Your projector group has been created, its time to get it configured.</p>
<p class="info">
	A projector group is a combination of multiple projectors which can be used to cover larger
	projection spaces. Configuring a projector group involves designating which projectors are
	included and setting their relative positions.
</p>
<button class="outline-button" on:click={openProjectorMenu}>Configure</button>

<style>
</style>
