import { type BundleOf, DataHandler, type IDMap } from "data-handler"
import { LuxedoRPC } from "luxedo-rpc"

import { DeviceAdmin, type DeviceAdminRawData } from "../../entries/admin/DeviceAdmin"

class DHDeviceAdmin extends DataHandler<DeviceAdmin> {
	EntryClass = DeviceAdmin

	protected async fetch(specificInstances?: number[] | undefined): Promise<BundleOf<DeviceAdmin>> {
		const data = await LuxedoRPC.api.bundlers.device_bundle_down_admin(specificInstances ?? [])
		return data
	}

	protected async pushData(entryData: { [id: number]: Partial<DeviceAdminRawData> }): Promise<void> {
		return await LuxedoRPC.api.bundlers.device_bundle_up_admin(entryData as IDMap<DeviceAdminRawData>)
	}
}

export const DataHandlerDeviceAdmin = new DHDeviceAdmin()
