import { Media } from "luxedo-data"
import { AudioSource } from "../../audio/AudioSource"
import { MediaSourceBase } from "../../MediaSource"

export class VideoSource extends MediaSourceBase<["volumechange"]> {
	declare media: Media
	declare audio: AudioSource
	declare video: HTMLVideoElement

	private isPlaying: boolean

	private _timestamp: number

	constructor(media: Media, video: HTMLVideoElement) {
		super()

		this.media = media
		this.video = video
		if (media.src.audio) this.audio = new AudioSource(media.src.audio)
	}

	async load(): Promise<void> {
		const promises: Array<Promise<void>> = []
		if (this.audio) promises.push(this.audio.load())
		promises.push(
			new Promise((res, rej) => {
				this.video.loop = true
				this.video.muted = true
				this.video.volume = 0

				this.video.crossOrigin = "anonymous"
				this.video.setAttribute("src", this.media.src.editorPreview)
				this.video.load()

				this.video.onloadeddata = () => {
					this.video.width = this.video.videoWidth
					this.video.height = this.video.videoHeight
					res()
				}

				this.video.onerror = () => rej()
			})
		)

		return new Promise((res, rej) => {
			Promise.all(promises)
				.then((e) => {
					res()
				})
				.catch((e) => {
					rej()
				})
		})
	}

	async play(): Promise<void> {
		this.isPlaying = true
		this.video.play()
		this.audio?.play()
	}

	pause(): void {
		this.isPlaying = false
		this.video.pause()
		this.audio?.pause()
	}

	public mute(doMute: boolean): void {
		this.audio?.mute(doMute)
	}

	get duration(): number {
		return this.video.duration
	}

	get paused() {
		return !this.isPlaying
	}

	set currentTime(time: number) {
		this._timestamp = time
		if (this.audio) this.audio.currentTime = time
		this.video.currentTime = time

		if (this.isPlaying && this.audio) {
			this.audio.pause()
			this.audio.play()
		}
	}

	get currentTime() {
		return this._timestamp
	}

	set volume(newVolume: number) {
		if (this.audio) this.audio.volume = newVolume
	}

	get volume() {
		if (this.audio) return this.audio.volume
		else return 0
	}
}
