import { ToastStore } from "./ToastStore"

let activeTimeout: number | NodeJS.Timeout

export abstract class Toast {
	static error(textContent: string, durationMs = 5000) {
		if (activeTimeout) clearTimeout(activeTimeout)

		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "error",
			})
		}, 200)
	}

	static text(textContent: string, durationMs = 5000) {
		if (activeTimeout) clearTimeout(activeTimeout)
		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "text",
			})
		}, 200)
	}

	static success(textContent: string, durationMs = 5000) {
		if (activeTimeout) clearTimeout(activeTimeout)
		ToastStore.set({
			isOpen: false,
		})

		activeTimeout = setTimeout(() => {
			ToastStore.set({
				isOpen: false,
			})
		}, durationMs)

		setTimeout(() => {
			ToastStore.set({
				isOpen: true,
				textContent,
				type: "success",
			})
		}, 200)
	}
}
