import { LuxedoRPC } from "luxedo-rpc"
import type { Folder, FolderRawData } from "../../entries/Folder"
import { DHFolder } from "../DataHandlerFolder"

class DHFolderAdmin extends DHFolder {
	protected async requestDeleteEntry(entry: Folder): Promise<void> {
		return await LuxedoRPC.api.admin.folder_delete_admin(entry.id!)
	}

	protected async requestCreateEntry(entryData: Partial<FolderRawData>): Promise<number> {
		const newFolderID = (await LuxedoRPC.api.admin.folder_create_admin(entryData.name!, entryData.parent_id!))
			.new_folder_id
		return newFolderID
	}
}

export const DataHandlerFolderAdmin = new DHFolderAdmin()
