import { DataHandlerSnapshot, type Device } from "luxedo-data"
import { Toast } from "svelte-comps/toaster"
import { get, writable } from "svelte/store"
import { openSnapshotPreview } from "../../comps/reusable/overlays"

type SnapshotCaptureCTX = { [index: number]: boolean }

// This handles state within the projector/calibration menu. This does NOT handle calibration processing.
export namespace DeviceSnapshotManager {
	const store = writable<SnapshotCaptureCTX>({})

	export function subscribe(cb: (ctx: SnapshotCaptureCTX) => void) {
		return store.subscribe(cb)
	}

	export async function captureSnapshot(device: Device) {
		if (!device.isOnline) return Toast.error("Device must be online. ")

		store.update((ctx) => {
			return { ...ctx, [device.id]: true }
		})

		try {
			const newSnapId = await device.captureSnapshot()
			await DataHandlerSnapshot.pull([newSnapId])
			const newSnapshot = DataHandlerSnapshot.get(newSnapId)
			if (newSnapshot) openSnapshotPreview(newSnapshot, { heading: "New Snapshot" })
			else throw new Error(`Could not get new snapshot with id ${newSnapId}`)
		} catch (e) {
			Toast.error("Unable to capture snapshot. Make sure your device is online and try again.")
			console.error("An error occured while capturing new snapshot", e)
		}

		store.update((ctx) => {
			return {
				...ctx,
				[device.id]: false,
			}
		})
	}
}
