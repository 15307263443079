<script lang="ts">
	import { CalibrationError } from "luxedo-data"
	import type { Component } from "../../../../types/SvelteTypes"
	import { CalibrationOverlayController } from "./CalibrationOverlayController"
	import CalibrationOverlayFailed from "./CalibrationOverlay_Failed.svelte"
	import CalibrationOverlayFinished from "./CalibrationOverlay_Finished.svelte"
	import CalibrationOverlayInstructions from "./CalibrationOverlay_Instructions.svelte"
	import CalibrationOverlayLoading from "./CalibrationOverlay_Loading.svelte"
	import CalibrationOverlayTroubleshooter from "./CalibrationOverlay_Troubleshooter.svelte"
	import * as Sentry from "@sentry/svelte"
	import { onDestroy, onMount } from "svelte"

	let activeStep: Component

	const CALIBRATION_COMPS = {
		[-2]: CalibrationOverlayTroubleshooter,
		[-1]: CalibrationOverlayFailed,
		0: CalibrationOverlayInstructions,
		1: CalibrationOverlayLoading,
		2: CalibrationOverlayFinished,
	}

	let unsubscribe

	function subscribeToController() {
		unsubscribe = CalibrationOverlayController.subscribe((ctx) => {
			const index = ctx.step

			activeStep = CALIBRATION_COMPS[index]

			if (index > 2) activeStep = CALIBRATION_COMPS[2]
			else if (index < -2) activeStep = CALIBRATION_COMPS[-2]

			if (!activeStep)
				Sentry.captureException(
					new CalibrationError(`Error during calibration: no menu for index ${index}`),
					{
						tags: {
							section: "Calibration",
						},
					}
				)
		})
	}

	onMount(() => subscribeToController())
	onDestroy(() => {
		if (unsubscribe) unsubscribe()
	})
</script>

<div id="projector-calibration">
	<svelte:component this={activeStep} />
</div>

<style>
	#projector-calibration {
		padding-top: 3rem;
		padding-bottom: 1rem;
		box-sizing: border-box;

		display: flex;
		flex-direction: column;
		justify-content: center;
		min-width: 42rem;
		min-height: 24rem;

		max-height: 100%;
		max-width: 100%;

		position: relative;
		align-items: center;
	}

	#projector-calibration :global(.button-container) {
		position: absolute;
		right: -2rem;
		bottom: -2rem;
		margin-bottom: 0;
		justify-content: flex-end;
	}

	#projector-calibration :global(.calibration-top) {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 16rem;
	}

	#projector-calibration :global(.calibration-bottom) {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	#projector-calibration :global(.calibration-bottom h3) {
		font-size: var(--h2);
		margin: 0;
		text-align: center;
	}

	#projector-calibration :global(.calibration-bottom p) {
		margin-top: 0.5rem;
		font-size: var(--h3);
		color: var(--color-text);
		width: 30rem;
	}
</style>
